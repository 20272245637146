import {TableContainer, Table} from '@mui/material'
import {BodyPatientHprimList} from "./body"
import {HeaderPatientHprimList} from "./header"

function PatientHprimListTable() {
    return <TableContainer>
        <Table>
            <HeaderPatientHprimList />
            <BodyPatientHprimList />
        </Table>
    </TableContainer>
}

export {PatientHprimListTable}
