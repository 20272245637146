"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAxiosError = exports.formatAxiosError = void 0;
const stringify_1 = require("@deepa-web/shared/src/json/stringify");
function isAxiosError(e) {
    return e.isAxiosError === true || e.name === 'AxiosError';
}
exports.isAxiosError = isAxiosError;
function formatAxiosError(error) {
    const response = error.response;
    if (response) {
        const { headers } = response;
        if (headers === undefined) {
            return formatObject(response);
        }
        const displayMessage = {
            errorCode: error.code,
            errorStatus: error.status,
            data: response.data,
            responseStatus: response.status,
            headers: {
                'Accept': headers.Accept,
                'Content-Type': headers['Content-Type'],
                'Content-Length': headers['Content-Length']
            }
        };
        return formatObject({ displayMessage });
    }
    else if (error.request) {
        return formatObject({
            errorCode: error.code,
            errorStatus: error.status,
            request: error.request
        });
    }
    else {
        const { config } = error, { headers } = config;
        if (config === undefined || headers === undefined) {
            return formatObject(error);
        }
        return formatObject({
            errorCode: error.code,
            errorStatus: error.status,
            errorMessage: error.message,
            config: {
                headers: {
                    'Accept': headers.Accept,
                    'Content-Type': headers['Content-Type'],
                    'Content-Length': headers['Content-Length']
                },
                method: config.method,
                url: config.url,
                data: config.data
            }
        });
    }
}
exports.formatAxiosError = formatAxiosError;
function formatObject(object) {
    return (0, stringify_1.stringify)(object);
}
