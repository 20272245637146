import {useState} from 'react'
import {HprimListFilterSorting} from "./HprimListFilterSorting"
import {TextField} from '@mui/material'
import {handleQuery} from './handleQuery'
import {searchQueryProviderWrapper} from "../../../../../../../redux/store/searchQueryProviderWrapper"
import {SearchQueryState} from '@deepa-web/browser-shared/src/redux/hprimSummaryList/query/SearchQueryState'
import {UploadButton} from "../../../../doctor/uploadHprim/UploadButton";
import {ReactComponent as FilterIcon} from '../../../../../../../images/icons/filter.svg'


function HprimListFiltersToggle() {
    return searchQueryProviderWrapper.ProviderWrapper(
       <HprimListFiltersToggleHooked/>
    )
}

function HprimListFiltersToggleHooked() {
    const [isVisible, setIsVisible] = useState(false),
        toggleVisibility = () => {
            setIsVisible(!isVisible)
        },
        buttonClasses = isVisible ? 'closedFilter' : 'openFilter',
        storeState: SearchQueryState = searchQueryProviderWrapper.useSelector((s: SearchQueryState) => s),
        query: string = storeState.query || ''

    return <>
        <div className='listFiltersDiv'>
            <UploadButton/>
            <button className={buttonClasses} onClick={toggleVisibility}>
                <FilterIcon />
                Filtres
            </button>
            <div className='searchBar'>
                <TextField
                    type='text'
                    placeholder='Rechercher'
                    value={query}
                    onChange={(event) => handleQuery(event.target.value)}
                    sx={{width: '100%'}}/>
            </div>
        </div>

        {isVisible && (
            <div className='tableFilters'>
                <HprimListFilterSorting/>
            </div>
        )}
    </>
}

export {HprimListFiltersToggle}
