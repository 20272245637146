import { initDoctorPracticeState } from '../DoctorPracticeState';
import { DPRactionType } from '../DPRaction';
import { storeField } from './storeField';
import { removeField } from './removeField';
function doctorPracticeReducer(prevState = initDoctorPracticeState(), action) {
    let newState = Object.assign(Object.assign({}, prevState), { phones: [...prevState.phones] });
    switch (action.type) {
        case DPRactionType.storeData:
            newState = action.data;
            break;
        case DPRactionType.storeField:
            storeField(action, newState);
            break;
        case DPRactionType.removeField:
            removeField(action, newState);
            break;
        default:
            break;
    }
    return newState;
}
export { doctorPracticeReducer };
