import {DoctorDataState} from '@deepa-web/browser-shared/src/redux/doctorData/DoctorDataState'
import {DoctorPracticeState} from '@deepa-web/browser-shared/src/redux/doctorPractice/DoctorPracticeState'
import {GETdoctorSignatureState} from '@deepa-web/browser-shared/src/redux/doctorSignature/get/GETdoctorSignatureState'
import {StringOfExactLength} from '../dataCheck/validator/StringOfExactLength'
import {
  FINESSexactLength,
  phoneMinimumLength,
  RPPSexactLength
} from '../dataCheck/validator/expectedDoctorPropertyLength'
import {StringOfMinimumLength} from '../dataCheck/validator/StringOfMinimumLength'
import {stringIsNotEmpty} from '../dataCheck/validator/stringIsNotEmpty'

function isDataValid(doctorData: DoctorDataState,
                     doctorPracticeState: DoctorPracticeState,
                     signature: GETdoctorSignatureState): boolean {

  const {RPPS, firstName, lastName, phone} = doctorData,
    {FINESS, service} = doctorPracticeState,
    validatorRPPS = new StringOfExactLength(RPPSexactLength),
    validatorFINESS = new StringOfExactLength(FINESSexactLength),
    validatorPhone = new StringOfMinimumLength(phoneMinimumLength)

  return validatorRPPS.isOfExactLength(RPPS)
    && validatorFINESS.isOfExactLength(FINESS)
    && stringIsNotEmpty(firstName)
    && stringIsNotEmpty(lastName)
    && stringIsNotEmpty(service)
    && validatorPhone.isOfMinimumLength(phone)
    && signature.fileIsStoredInService
}

export {isDataValid}
