import { StoreProxy } from '../../factory/StoreProxy';
import { configureStore } from '@reduxjs/toolkit';
import { PaginationActionType } from './PaginationAction';
import { paginationReducer } from './paginationReducer';
const paginationStore = configureStore({ reducer: paginationReducer }), paginationStoreProxy = new StoreProxy(paginationStore);
function putHPRIMlistToPaginationStore(list) {
    paginationStore.dispatch({
        type: PaginationActionType.setList,
        data: list
    });
}
export { paginationStoreProxy, putHPRIMlistToPaginationStore };
