class StringOfMinimumLength {
  private readonly _minimumLength: number

  constructor(minimumLength: number) {
    this._minimumLength = minimumLength
  }

  isOfMinimumLength(text?: string): boolean {
    return text !== undefined && text.length >= this._minimumLength
  }
}

function stringOfMinimumLengthValidatorFactory(minimalLength: number) {
  const instance = new StringOfMinimumLength(minimalLength)
  return instance.isOfMinimumLength.bind(instance)
}

export {
  StringOfMinimumLength,
  stringOfMinimumLengthValidatorFactory
}
