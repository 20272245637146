"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseDate = exports.formattedTimestamp = exports.formatDateIso = exports.formatDate = void 0;
function formattedTimestamp() {
    const date = new Date(), explicit = parseDate(date);
    return formatDate(explicit);
}
exports.formattedTimestamp = formattedTimestamp;
function formatDate({ jour, mois, an }) {
    return `${pad0(jour)}/${pad0(mois)}/${an}`;
}
exports.formatDate = formatDate;
function formatDateIso(explicitDate) {
    const { jour, mois, an } = explicitDate;
    return `${an}-${pad0(mois)}-${pad0(jour)}`;
}
exports.formatDateIso = formatDateIso;
function pad0(i) {
    return `${i}`.padStart(2, '0');
}
function parseDate(date) {
    return {
        an: date.getFullYear(),
        jour: date.getDate(),
        mois: date.getMonth() + 1
    };
}
exports.parseDate = parseDate;
