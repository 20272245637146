import { call, put, takeEvery } from 'redux-saga/effects';
import { UploadActionType } from '../../redux/hprimUpload/UploadAction';
import { postFormHPRIM } from './postFormHPRIM';
import { uploadFileServiceInstance } from './UploadFileService';
function* fileUploadSaga() {
    yield takeEvery(UploadActionType.storeFile, peekAction);
}
function* peekAction(_action) {
    const iterator = uploadFileServiceInstance.shiftItem();
    let iterResult = iterator.next(), file;
    while (!iterResult.done) {
        file = iterResult.value;
        yield call(postFormHPRIM, file);
        iterResult = iterator.next();
    }
    yield put({ type: UploadActionType.cancelRequest });
}
export { fileUploadSaga };
