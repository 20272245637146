import { stringify } from '@deepa-web/shared/src/json/stringify';
import { dispatchNewAlertMessage } from '@deepa-web/browser-shared/src/redux/alert';
function errorHandler(url, e) {
    if (e) {
        const message = stringify(e);
        displayError(`Une requête api à ${url} a échoué avec l'errer : ${message}`);
    }
}
function displayError(...errorMessage) {
    for (const message of errorMessage) {
        dispatchNewAlertMessage(message);
    }
}
export { errorHandler, displayError };
