import {ListState} from '@deepa-web/browser-shared/src/redux/common/list/ListState'
import {hprimSummaryListProviderWrapper} from '../../../../../../../redux/store/hprimSummaryListProviderWrapper'
import {dispatchQueryType} from '../filter/dispatchQuery'
import {SearchQueryState} from '@deepa-web/browser-shared/src/redux/hprimSummaryList/query/SearchQueryState'
import {SearchQueryActionType} from '@deepa-web/browser-shared/src/redux/hprimSummaryList/query/SearchQueryAction'
import {searchQueryProviderWrapper} from '../../../../../../../redux/store/searchQueryProviderWrapper'
import {HprimSummaryType} from '@deepa-web/browser-shared/src/redux/hprimSummaryList/HprimSummaryType'


function DashboardInfo() {
  return hprimSummaryListProviderWrapper.ProviderWrapper(
    searchQueryProviderWrapper.ProviderWrapper(
      <DashboardInfoHooked/>
    ))
}

function DashboardInfoHooked() {

  const hprimList: HprimSummaryType[] = hprimSummaryListProviderWrapper.useSelector((l: ListState<HprimSummaryType>) =>
      [...l.items]),
    hprimListUnread: HprimSummaryType[] = [...hprimList].filter(notification => !notification.isRead),
    hprimListAnormales: HprimSummaryType[] = [...hprimList].filter(notification => notification.nrAnomalies > 0),
    hprimListUnreadAnormaux: HprimSummaryType[] = [...hprimList].filter(notification => notification.nrAnomalies > 0 && !notification.isRead),

    storeState: SearchQueryState = searchQueryProviderWrapper.useSelector((s: SearchQueryState) => s),
    showOnlyUnread = storeState.showOnlyUnread,
    showOnlyAnormaux = storeState.showOnlyAnormaux,
    showUnreadAndAnormaux = storeState.showUnreadAndAnormaux,

    buttonClassesUnread = showOnlyUnread ? 'appliedFilterDashboard' : 'nonAppliedFilterDashboard',
    buttonClassesAnormaux = showOnlyAnormaux ? 'appliedFilterDashboard' : 'nonAppliedFilterDashboard',
    buttonClassesUnreadAnormaux = showUnreadAndAnormaux ? 'appliedFilterDashboard' : 'nonAppliedFilterDashboard',

    handleUnreadOnClick = () =>
      dispatchQueryType(SearchQueryActionType.showOnlyUnread),

    handleAnormauxOnClick = () =>
      dispatchQueryType(SearchQueryActionType.showOnlyAnormaux),

    handleUnreadAndAnormauxOnClick = () =>
      dispatchQueryType(SearchQueryActionType.showUnreadAndAnormaux)


  return <div className='dashboardInfoDiv'>
    {displayInfo(hprimListUnread, 'non lu', 'non lus', buttonClassesUnread, handleUnreadOnClick)}
    {displayInfo(hprimListAnormales, 'anormal', 'anormaux', buttonClassesAnormaux, handleAnormauxOnClick)}
    {displayInfo(hprimListUnreadAnormaux, 'anormal non lu', 'anormaux non lus', buttonClassesUnreadAnormaux, handleUnreadAndAnormauxOnClick)}
  </div>
}

function displayInfo(list: HprimSummaryType[],
                     singular: string,
                     plural: string,
                     className: string,
                     onClick: () => void) {

  switch (list.length) {
    case 0 :
    case 1 :
      return <button className={className} onClick={onClick}>
        <span className='numberSpan'> {list.length} </span>
        <span> {singular} </span>
      </button>

    default:
      return <button className={className} onClick={onClick}>
        <span className='numberSpan'> {list.length} </span>
        <span> {plural} </span>
      </button>
  }
}

export {DashboardInfo}
