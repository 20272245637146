"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AxiosRequestConfigBuilderFacade = void 0;
const AxiosRequestConfigBuilder_1 = require("@deepa-web/axios/src/builder/AxiosRequestConfigBuilder");
const ContentTypeShort_1 = require("@deepa-web/shared/src/type/ContentTypeShort");
class AxiosRequestConfigBuilderFacade {
    get token() {
        return null;
    }
    get apiKey() {
        return null;
    }
    makeAxiosConfig(contentTypeArg, responseType) {
        const result = new AxiosRequestConfigBuilder_1.AxiosRequestConfigBuilder(), token = this.token, apiKey = this.apiKey;
        let headers = {};
        result.setResponseType(responseType);
        if (token) {
            addHeader(headers, 'Authorization', `Bearer ${token}`);
        }
        if (apiKey) {
            addHeader(headers, 'X-Api-Key', apiKey);
        }
        const contentType = (0, ContentTypeShort_1.contentTypeHTTP)(contentTypeArg);
        if (contentType) {
            addHeader(headers, 'Content-Type', contentType);
        }
        result.setHeaders(headers);
        return result.getAxiosRequestConfig;
    }
}
exports.AxiosRequestConfigBuilderFacade = AxiosRequestConfigBuilderFacade;
function addHeader(headerObj, key, value) {
    headerObj[key] = value;
}
