class LogoutService {
    saveCallback(callback) {
        if (typeof callback === 'function') {
            this._logoutCallback = callback;
        }
    }
    runCallBack() {
        if (typeof this._logoutCallback === 'function') {
            this._logoutCallback();
            this._logoutCallback = undefined;
        }
    }
}
const logoutServiceinstance = new LogoutService();
export { logoutServiceinstance };
