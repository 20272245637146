import {OnClickPropsChildren} from '@deepa-web/browser-shared/src/type/OnClickProps'
import React from 'react'

function NavigationButton({
                            children,
                            onClick
                          }: OnClickPropsChildren): JSX.Element {
  return <div className='leftSideBar-titles'>
    <button className='menuButton' onClick={onClick}>{children}</button>
  </div>
}

export {NavigationButton}
