import React from 'react'

abstract class BooleanFunctorReactComponent<TProps>
  extends React.Component<TProps> {

  protected abstract get isMatch(): boolean

  protected abstract get match(): JSX.Element | null

  protected abstract get noMatch(): JSX.Element | null

  render() {
    return this.isMatch ?
      this.match :
      this.noMatch
  }
}

export {BooleanFunctorReactComponent}
