import {GETdoctorSignatureService} from '@deepa-web/browser-shared/src/saga/doctorSignature/get/GETdoctorSignatureService'

export function ImageFromService(): JSX.Element {
  const imageBlob: Blob | null = GETdoctorSignatureService.signatureFile,
    src: string = URL.createObjectURL(imageBlob!)

  return <div className='signature'>
    <img src={src} alt='Signature' id='uploadedSignature'/>
  </div>
}
