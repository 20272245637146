import {PatientGender} from "../doctorLabo/common/hprimPages/detail/header/PatientGender"
import {Grid} from '@mui/material'
import {ReactComponent as BirthDateIcon} from '../../../images/icons/birthDate.svg'
import {ReactComponent as IDIcon} from '../../../images/icons/idIcon.svg'
import {ReactComponent as AgeIcon} from '../../../images/icons/calendarAge.svg'
import {ReactComponent as SocialSecurityCardIcon} from '../../../images/icons/socialSecurityCard.svg'
import {CustomTooltip} from "../../base/tooltip";

function PatientDetails() {
    return <Grid className='patientDetailGrid' item xs={12} sm={12} md={4.5}>
        <p> Information patient </p>
            <div className='patientDetail'>
                <div className='patientDetailContent1'>
                    <div><IDIcon/> John Doe</div>
                    <PatientGender gender={1}/>
                    <div><BirthDateIcon/> 01/01/1970</div>
                </div>
                <div className='patientDetailContent2'>
                    <div><AgeIcon/> 54 ans</div>
                    <CustomTooltip title='Numéro de sécurité sociale' placement='bottom'>
                        <div><SocialSecurityCardIcon/> 1 70 01 75 000 000 01</div>
                    </CustomTooltip>
                </div>
            </div>
    </Grid>
}

export {PatientDetails}
