import {UserLabeledValue} from '@deepa-web/shared/src/type/UserLabeledValue'
import {
  FormControl,
  IconButton,
  TextField
} from '@mui/material'
import React, {
  ChangeEvent,
  useEffect,
  useState
} from 'react'
import {
  LabelInfo,
  RadioButtonsLabelInfo
} from './RadioButtonsLabelInfo'
import {doctorPracticeStoreProxy} from '@deepa-web/browser-shared/src/redux/doctorPractice'
import {
  DPRactionField,
  DPRactionFieldPhoneLabel,
  DPRactionType,
  DPRpropertyName
} from '@deepa-web/browser-shared/src/redux/doctorPractice/DPRaction'
import {FiTrash2} from 'react-icons/fi'
import {defaultLabel} from '@deepa-web/browser-shared/src/redux/doctorPractice/DoctorPracticeState'


function UserLabeledInput(props: UserLabeledValue<string>): JSX.Element {
  const {label, value, uid} = props,
    [labelEdit, setLabel] = useState(label || defaultLabel),
    [valueEdit, setValue] = useState(value || ''),
    [choice, setChoice] = useState(LabelInfo.info)

  useEffect(() => {
    const newULV = {uid, label: labelEdit, value: valueEdit}
    doctorPracticeStoreProxy.dispatch({
      type: DPRactionType.storeField,
      data: {
        tag: DPRpropertyName.phones,
        fieldValue: newULV
      }
    })
  }, [labelEdit, valueEdit, uid])

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    const newValue: string = (event.target as HTMLInputElement).value

    switch (choice) {
      case LabelInfo.info:
        setValue(newValue)
        break
      case LabelInfo.label:
        setLabel(newValue)
        break
    }
  }

  function inputValue() {
    switch (choice) {
      case LabelInfo.info:
        return valueEdit
      case LabelInfo.label:
        return labelEdit
    }
  }

  return <FormControl id='radioForm' key={uid}>
    <div>
      <TextField
        id={'customTextField'}
        name={'customTextField'}
        label={labelEdit}
        value={inputValue()}
        onChange={onChange}
      />
      <IconButton onClick={() => removeInput(props)}>
        <FiTrash2 size={'1em'} color={'#bb2525'} />
      </IconButton>
    </div>
    <RadioButtonsLabelInfo initChoice={choice} setChoice={setChoice}/>
  </FormControl>
}

function removeInput(input: UserLabeledValue<string>) {
  const data: DPRactionFieldPhoneLabel = {
      tag: DPRpropertyName.phones,
      fieldValue: input
    },
    action: DPRactionField = {
      type: DPRactionType.removeField,
      data
    }

  doctorPracticeStoreProxy.dispatch(action)
}

export {
  UserLabeledInput,
  defaultLabel
}
