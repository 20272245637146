import '../../../../../style/ordonnancePDF/ordonnancePdf.scss'
import {
  useEffect,
  useState
} from 'react'
import {requestSignature} from '@deepa-web/browser-shared/src/redux/doctorSignature/get'
import AuthorisationProxy from '../../../../base/authorise/AuthorisationProxy'
import {UserGroup} from '@deepa-web/shared/src/type/CognitoUser'
import {OrdonnancePrintableEmbedded} from './printable/OrdonnancePrintableEmbedded'
import {ComponentUIDLabel} from '../../../../base/ComponentUIDLabel'
import {ApplicationTemplate} from '../../../../base/template/ApplicationTemplate'
import {ButtonToPdf} from './downloadButton'
import {doctorPracticeProviderWrapper} from '../../../../../redux/store/doctorPracticeProviderWrapper'
import {doctorDataProviderWrapper} from '../../../../../redux/store/doctorDataProviderWrapper'
import {GETsignatureProviderWrapper} from '../../../../../redux/store/GETsignatureProviderWrapper'


function OrdonnancePdfPreview() {
  return <AuthorisationProxy allowedGroups={[UserGroup.doctors]}>
    <ComponentUIDLabel uid='CID-OrdonnancePdfPreview'>
      <ApplicationTemplate>
        {doctorPracticeProviderWrapper.ProviderWrapper(
          doctorDataProviderWrapper.ProviderWrapper(
            GETsignatureProviderWrapper.ProviderWrapper(
              <OrdonnancePdfPreviewAuthorised/>
            )))}
      </ApplicationTemplate>
    </ComponentUIDLabel>
  </AuthorisationProxy>
}

function OrdonnancePdfPreviewAuthorised() {
  const [isSignatureRequested, setIsSignatureRequested] = useState(false)

  useEffect(() => {
    if (!isSignatureRequested) {
      requestSignature()
      setIsSignatureRequested(true)
    }
  }, [isSignatureRequested])


  return <div className='previewContainer'>
    <ButtonToPdf/>
    <div className='prescriptionPreview'>
      <OrdonnancePrintableEmbedded/>
    </div>
    <ButtonToPdf/>
  </div>
}

export {OrdonnancePdfPreview}
