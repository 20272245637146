import { initDoctorSignatureState } from './GETdoctorSignatureState';
import { GETdoctorSignatureActionType } from './GETdoctorSignatureAction';
function GETdoctorSignatureReducer(previousState = initDoctorSignatureState(), action) {
    const newState = Object.assign({}, previousState);
    switch (action.type) {
        case GETdoctorSignatureActionType.cancelRequest:
            newState.apiRequestIsInProgress = false;
            break;
        case GETdoctorSignatureActionType.fileIsReceived:
            newState.fileIsStoredInService = true;
            break;
        case GETdoctorSignatureActionType.makeRequest:
            newState.apiRequestIsInProgress = true;
            break;
        case GETdoctorSignatureActionType.clearFileFromStore:
        case GETdoctorSignatureActionType.deleteFileApi:
            newState.fileIsStoredInService = false;
            break;
        default:
            break;
    }
    return newState;
}
export { GETdoctorSignatureReducer };
