import {PropsWithChildren} from 'react'


function NoDataMessage(props: PropsWithChildren) : JSX.Element {
    return <div className='noDataTabDiv'>
       <p> {props.children} </p>
    </div>
}

export {NoDataMessage}
