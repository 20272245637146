"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DELETErequestFactory = void 0;
const RequestFactory_1 = require("./RequestFactory");
const ContentTypeShort_1 = require("@deepa-web/shared/src/type/ContentTypeShort");
const DELETErequestBuilder_1 = require("../builder/DELETErequestBuilder");
class DELETErequestFactory extends RequestFactory_1.RequestFactory {
    constructor(routeParameter, moduleArg) {
        super(routeParameter, ContentTypeShort_1.ContentTypeShort.none, 'text', moduleArg);
    }
    makeBuilder(axiosConfig) {
        const routeParameter = this._routeParameter;
        return new DELETErequestBuilder_1.DELETErequestBuilder(routeParameter, this._hostPort, axiosConfig, this._contentErrorFactory);
    }
}
exports.DELETErequestFactory = DELETErequestFactory;
