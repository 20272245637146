import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleWare from 'redux-saga';
function storeFactory(reducer, saga) {
    const sagaMiddleware = createSagaMiddleWare(), store = configureStore({
        reducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware)
    });
    sagaMiddleware.run(saga);
    return store;
}
export { storeFactory };
