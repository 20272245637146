import {
  Table,
  TableContainer
} from '@mui/material'
import {Header} from './Header'
import {Body} from './Body'
import {ComponentUIDLabel} from '../../../base/ComponentUIDLabel'
import {feedbackSummaryProviderWrapper} from '../../../../redux/store/feedbackSummaryProviderWrapper'
import {ListState} from '@deepa-web/browser-shared/src/redux/common/list/ListState'
import {FeedbackDataUser} from '@deepa-web/shared/src/type/FeedbackData'
import {ListElementFactoryWithChildren} from "../../../base/functor/ListElementFactoryWithChildren";


function FeedbackTable() {
  return <ComponentUIDLabel uid='CID-FeedbackTable'>
    <FeedbackTableLabeled/>
  </ComponentUIDLabel>
}

function FeedbackTableLabeled() {
  return feedbackSummaryProviderWrapper.ProviderWrapper(<FeedbackTableHooked/>)
}

function FeedbackTableHooked() {

    const feedbacks: FeedbackDataUser[] = feedbackSummaryProviderWrapper.useSelector((s: ListState<FeedbackDataUser>) => s.items)

  return <>
      <ListElementFactoryWithChildren list={feedbacks} noDataMessage='Aucun feedback disponible'>
          <TableContainer>
              <Table>
                  <Header/>
                  <Body/>
              </Table>
          </TableContainer>
      </ListElementFactoryWithChildren>
  </>


}

export {FeedbackTable}
