import {DoctorPracticeState} from '@deepa-web/browser-shared/src/redux/doctorPractice/DoctorPracticeState'
import {ValidatedOrdonnanceElement} from '../dataCheck/ValidatedOrdonnanceElement'
import {BarCode} from '../../../../../base/barCode/BarCode'
import {StringOfExactLength} from '../dataCheck/validator/StringOfExactLength'
import {FINESSexactLength} from '../dataCheck/validator/expectedDoctorPropertyLength'
import {doctorPracticeProviderWrapper} from '../../../../../../redux/store/doctorPracticeProviderWrapper'

function FINESS() {
  const FINESS = doctorPracticeProviderWrapper.useSelector(
      (state: DoctorPracticeState) => state.FINESS),
    validator = new StringOfExactLength(FINESSexactLength)

  return <ValidatedOrdonnanceElement
    data={FINESS}
    dataIsValid={validator.isOfExactLength.bind(validator)}
    warning='Veuillez renseigner un identifiant FINESS correct.'
  >
    <BarCode id='barcodeSvg2' data={FINESS!}/>
  </ValidatedOrdonnanceElement>
}

export {FINESS}
