import {FiClipboard} from 'react-icons/fi'
import {CopyToClipboardProps} from './CopyToClipboard'

function copyToClipboardSafeProps(props: CopyToClipboardProps): CopyToClipboardProps {

  const result: CopyToClipboardProps = {...props}

  if (!props.icon) {
    result.icon = {
      param: <FiClipboard size={'1em'}/>,
      copied: <FiClipboard size={'1em'}/>
    }
  }

  if (!props.label) {
    result.label = {
      param: 'Copier',
      copied: 'Texte Copié'
    }
  }

  return result
}

export {copyToClipboardSafeProps}
