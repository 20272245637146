import { DoctorDataActionType, DoctorDataPropertyName } from './DoctorDataAction';
import { initDoctorDataState } from './DoctorDataState';
function doctorDataReducer(prevState = initDoctorDataState(), action) {
    let newState = Object.assign({}, prevState);
    switch (action.type) {
        case DoctorDataActionType.storeData:
            newState = action.data;
            break;
        case DoctorDataActionType.clearData:
            newState = initDoctorDataState();
            break;
        case DoctorDataActionType.storeField:
            const data = action.data;
            switch (data.tag) {
                case DoctorDataPropertyName.RPPS:
                    newState.RPPS = data.fieldValue;
                    break;
                case DoctorDataPropertyName.lastName:
                    newState.lastName = data.fieldValue;
                    break;
                case DoctorDataPropertyName.firstName:
                    newState.firstName = data.fieldValue;
                    break;
                case DoctorDataPropertyName.email:
                    newState.email = data.fieldValue;
                    break;
                case DoctorDataPropertyName.phone:
                    newState.phone = data.fieldValue;
                    break;
                default:
                    break;
            }
    }
    return newState;
}
export { doctorDataReducer };
