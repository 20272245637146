function filterNotificationByQuery(query, list) {
    return list.filter(searchPredicate(query));
}
function searchPredicate(query) {
    if (query.length === 0) {
        return () => true;
    }
    return (notification) => {
        const words = notificationWords(notification);
        return words.some(string1 => string1.includes(query));
    };
}
function notificationWords(notification) {
    const { patientNom, patientPrenom, hprimId } = notification;
    return [
        patientPrenom,
        patientPrenom.toLowerCase(),
        patientNom,
        patientNom.toLowerCase(),
        hprimId
    ];
}
export { filterNotificationByQuery };
