import { SearchQueryActionType } from './SearchQueryAction';
import { storeFactory } from '../../factory/storeFactory';
import { searchQueryReducer } from './searchQueryReducer';
import { searchQuerySaga } from '../../../saga/hprimSummaryList/searchQuerySaga';
import { StoreProxy } from '../../factory/StoreProxy';
const searchQueryStore = storeFactory(searchQueryReducer, searchQuerySaga), searchQueryStoreProxy = new StoreProxy(searchQueryStore);
function applySearchQuery() {
    actionWithoutData(SearchQueryActionType.applyFilter);
}
function sortDateExamen() {
    actionWithoutData(SearchQueryActionType.reverseSortDateExamen);
}
function actionWithoutData(actionType) {
    const action = {
        type: actionType
    };
    searchQueryStore.dispatch(action);
}
export { searchQueryStoreProxy, applySearchQuery, sortDateExamen };
