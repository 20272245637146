import React from 'react'
import {
  take,
  reverse
} from 'lodash'
import {AlertState} from '@deepa-web/browser-shared/src/redux/alert/AlertState'
import {AlertMessage} from '@deepa-web/browser-shared/src/redux/alert/AlertMessage'
import {alertProviderWrapper} from '../../redux/store/alertProviderWrapper'
import {
  SnackbarContent,
  Stack
} from '@mui/material'
import {dispatchDeleteAlert} from '@deepa-web/browser-shared/src/redux/alert'
import '../../style/baseComponents/alert.scss'
import {CopyToClipboard} from './button/clipboard/CopyToClipboard'
import { FiX } from "react-icons/fi";


function Alerts() {
  return alertProviderWrapper.ProviderWrapper(<AlertSnackbarHooked/>)
}

function AlertSnackbarHooked() {

  const state: AlertState = alertProviderWrapper.useSelector((s: AlertState) => s),
    topAlerts: AlertMessage[] = reverse(take(state.messages, 4))

  return <div className='alerts'>
    <Stack spacing={2}>
      {topAlerts.map(SingleMessageAlert)}
    </Stack>
  </div>
}

function SingleMessageAlert(alertMessage: AlertMessage) {
  const close = () =>
      dispatchDeleteAlert(alertMessage.uid),
    action = (
      <>
        <button onClick={close}>
            <FiX size='1em'/> Fermer
        </button>
        <CopyToClipboard  useDefaults={true} tooltipTitle='Copier'>
          {alertMessage.message}
        </CopyToClipboard>
      </>
    )

  return <SnackbarContent
    key={alertMessage.uid}
    style={{
      maxHeight: '10em',
      maxWidth: '40em',
      overflow: 'auto',
      overflowWrap: 'break-word',
      wordBreak: 'break-all',
      display: 'block',
      backgroundColor: '#EF3D3D',
      borderRadius: '10px',
    }}
    message={alertMessage.message}
    action={action}
  />
}

export {Alerts}
