import {BilanSanguinValue} from '@deepa-web/shared/src/type/awsLambda/ruleEngine/response/BilanSanguin'

function BilanSanguinComparator(a: BilanSanguinValue, b: BilanSanguinValue): number {
  const ao = a.order,
    bo = b.order

  if (ao === bo) {
    return 0
  }

  if (ao === undefined && bo !== undefined) {
    return -1
  }

  if (ao !== undefined && bo === undefined) {
    return 1
  }

  if (ao! < bo!) {
    return -1
  }
  if (ao! > bo!) {
    return 1
  }

  return 0
}

export {BilanSanguinComparator}
