import { call, put, takeEvery } from 'redux-saga/effects';
import { DictionaryActionType } from '../../redux/common/dictionary/DictionaryAction';
import { callGETEndpoint } from './callEndpoints';
import { dictionaryTypeActionFactory } from '../../redux/common/dictionary/dictionaryTypeActionFactory';
const requested = new Set();
function* ruleEngine1Saga() {
    yield takeEvery(DictionaryActionType.requestData, wrapCancelAction);
}
function* wrapCancelAction(action) {
    const cancelAction = dictionaryTypeActionFactory(DictionaryActionType.cancelRequest);
    yield peekAction(action);
    yield put(cancelAction);
}
function* peekAction(action) {
    const key = action.key.hprimId, isRequested = requested.has(key);
    if (isRequested) {
        return;
    }
    requested.add(key);
    const ruleEngine1 = (yield call(callGETEndpoint, action.key));
    if (!ruleEngine1) {
        requested.delete(key);
        return;
    }
    const newAction = {
        type: DictionaryActionType.storeData,
        key: action.key,
        item: ruleEngine1
    };
    yield put(newAction);
    requested.delete(key);
}
export { ruleEngine1Saga };
