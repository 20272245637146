import {groupBy} from 'lodash'
import {SingleOrdoTitle} from './single/SingleOrdoTitle'
import {
  AnomalyExamProps,
  Exploration1,
  GroupedValues
} from './AnomalyExamProps'


function AnomalyExams(props: AnomalyExamProps) {

  const {explorations} = props,
    explorationsGrouped: GroupedValues<Exploration1> =
      groupBy(explorations, 'ordo_title')

  return <div className='examDiv'>
    {
      Object.keys(explorationsGrouped).map((ordo_title, index) => {
        return SingleOrdoTitle(explorationsGrouped, ordo_title)
      })
    }
  </div>
}


export {AnomalyExams}
