"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Queue = void 0;
class Queue {
    constructor() {
        this._items = [];
    }
    pushItem(file) {
        this._items.push(file);
    }
    *shiftItem() {
        let first = this._items.shift();
        while (first) {
            yield first;
            first = this._items.shift();
        }
    }
}
exports.Queue = Queue;
