import {useState} from 'react'
import {Button} from '@mui/material'
import {FiTrash2} from 'react-icons/fi'
import {
  PromptDelete,
  PromptDeleteProps
} from '../button/PromptDelete'
import {deleteSignatureApi} from '@deepa-web/browser-shared/src/redux/doctorSignature/get'

function DeleteSignatureButton() {
  const [open, setOpen] = useState(false),
    promptDeleteSignatureProps: PromptDeleteProps = {
      open,
      setOpen,
      onClickDelete1Item: deleteSignatureApi
    }

  return <div>
    <Button
      className='deleteSignature'
      onClick={() => setOpen(true)}>
      <FiTrash2 size={'1.2em'} color={'#bb2525'}/>
    </Button>
    <PromptDelete {...promptDeleteSignatureProps}/>
  </div>
}

export {DeleteSignatureButton}
