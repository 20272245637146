import {Grid} from '@mui/material'
import {formatDate} from '@deepa-web/shared/src/type/date/ExplicitDate'
import {HprimSummaryType} from '@deepa-web/browser-shared/src/redux/hprimSummaryList/HprimSummaryType'
import {PatientGender} from './PatientGender'
import {ReactComponent as BirthDateIcon} from '../../../../../../../images/icons/birthDate.svg'
import {CustomTooltip} from '../../../../../../base/tooltip'
import '../../../../../../../style/hprimDetailPage/bilanPatientDetail.scss'

function PatientDetail(notification: HprimSummaryType) {

  const {
      patientNom,
      patientPrenom,
      dateNaissance,
      genre
    } = notification,

    displayDate = formatDate(dateNaissance)

  return <Grid className='detailGrid' item xs={5} sm={5} md={5}>
    <div className='patientDetailDiv'>
      <p> {patientNom} {patientPrenom} </p>
      <div className='patientInfo'>
        <CustomTooltip title='Date de naissance' placement='top'>
          <div><BirthDateIcon/> {displayDate} </div>
        </CustomTooltip>
        <PatientGender gender={genre}/>
      </div>
    </div>
  </Grid>
}


export {PatientDetail}
