import { storeFactory } from '../factory/storeFactory';
import { StoreProxy } from '../factory/StoreProxy';
import { DictionaryReducer } from "../common/dictionary";
import { bilanSanguinAttachmentSummaryListSaga } from "../../saga/bilanSanguinAttachmentSummaryList";
class BilanSanguinAttachmentSummaryListReducer extends DictionaryReducer {
    keyToString({ hprimId }) {
        return hprimId;
    }
}
const reducerInstance = new BilanSanguinAttachmentSummaryListReducer(), bilanSanguinAttachmentSummaryListStore = storeFactory(reducerInstance.dictionaryReducer.bind(reducerInstance), bilanSanguinAttachmentSummaryListSaga), bilanSanguinAttachmentSummaryListStoreProxy = new StoreProxy(bilanSanguinAttachmentSummaryListStore);
export { bilanSanguinAttachmentSummaryListStoreProxy };
