const RPPSexactLength = 11,
  FINESSexactLength = 9,
  phoneMinimumLength = 10


export {
  RPPSexactLength,
  FINESSexactLength,
  phoneMinimumLength
}
