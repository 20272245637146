"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentErrorStringFactoryError = exports.ContentErrorStringFactory = exports.ContentErrorFactory = exports.ActionStatus = void 0;
var ActionStatus;
(function (ActionStatus) {
    ActionStatus["success"] = "success";
    ActionStatus["fail"] = "fail";
})(ActionStatus = exports.ActionStatus || (exports.ActionStatus = {}));
class ContentErrorFactory {
    makeContent(content) {
        return {
            status: ActionStatus.success,
            content
        };
    }
    makeError(error, content) {
        return {
            status: ActionStatus.fail,
            error,
            content
        };
    }
}
exports.ContentErrorFactory = ContentErrorFactory;
class ContentErrorStringFactory extends ContentErrorFactory {
    makeErrorString(error, content) {
        return super.makeError(this.errorFromString(error), content);
    }
}
exports.ContentErrorStringFactory = ContentErrorStringFactory;
class ContentErrorStringFactoryError extends ContentErrorStringFactory {
    errorFromString(e) {
        return new Error(e);
    }
}
exports.ContentErrorStringFactoryError = ContentErrorStringFactoryError;
