import '@aws-amplify/ui-react/styles.css'
import {routeLoggedInUser} from '../base/authorise/routeLoggedInUser'
import {UnknownUser} from '../base/error/UnknownUser'
import {
  CognitoUserRelevant,
  UserGroup
} from '@deepa-web/shared/src/type/CognitoUser'
import AuthorisationProxy from '../base/authorise/AuthorisationProxy'
import {UserState} from '@deepa-web/browser-shared/src/redux/user/UserState'
import {userProviderWrapper} from '../../redux/store/userProviderWrapper'

function App() {
  return <AuthorisationProxy
    allowedGroups={
      [
        UserGroup.admin,
        UserGroup.doctors,
        UserGroup.labos
      ]}>
    <AppAuthorised/>
  </AuthorisationProxy>
}

function AppAuthorised() {
  return userProviderWrapper.ProviderWrapper(<AppAuthorisedHooked/>)
}

function AppAuthorisedHooked() {
  const user: CognitoUserRelevant | undefined = userProviderWrapper.useSelector((s: UserState) => s.user)
  return user ?
    routeLoggedInUser(user)
    : UnknownUser()
}

export {App}
