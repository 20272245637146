import { initSignatureUploadState } from './SignatureUploadState';
import { SignatureUploadActionType } from './SignatureUploadAction';
function signatureUploadReducer(prevState = initSignatureUploadState(false), action) {
    switch (action.type) {
        case SignatureUploadActionType.storeFile:
            return initSignatureUploadState(true);
        case SignatureUploadActionType.cancelRequest:
            return initSignatureUploadState(false);
        default:
            return prevState;
    }
}
export { signatureUploadReducer };
