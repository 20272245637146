"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PATCHrequestFactory = void 0;
const PATCHrequestBuilder_1 = require("../builder/PATCHrequestBuilder");
const RequestFactoryBody_1 = require("./RequestFactoryBody");
class PATCHrequestFactory extends RequestFactoryBody_1.RequestFactoryBody {
    makeBuilder(axiosConfig) {
        const routeParameter = this._routeParameter, body = this._body;
        return new PATCHrequestBuilder_1.PATCHrequestBuilder(routeParameter, this._hostPort, axiosConfig, body, this._contentErrorFactory);
    }
}
exports.PATCHrequestFactory = PATCHrequestFactory;
