import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import 'dayjs/locale/fr'
import {frFR} from '@mui/x-date-pickers/locales'
import {
  toDayjsNull
} from '@deepa-web/browser-shared/src/type/DayjsExplicitDateAdapter'
import {ExplicitDate} from '@deepa-web/shared/src/type/date/ExplicitDate'
import {Dayjs} from 'dayjs'
import {ReactComponent as ResetIcon} from '../../../images/icons/refresh.svg'

export interface DatePickerRangeProps {
  labelStart: string
  labelEnd: string
  valueStart: ExplicitDate | null
  valueEnd: ExplicitDate | null
  onChangeStart: (newDate: Dayjs | null) => void
  onChangeEnd: (newDate: Dayjs | null) => void
  resetStart: () => void
  resetEnd: () => void
}

function DatePickerRange(props: DatePickerRangeProps) {

  const {
      labelStart, labelEnd, valueStart, valueEnd,
      onChangeStart, resetEnd, resetStart, onChangeEnd
    } = props,
    localeText = frFR.components.MuiLocalizationProvider.defaultProps.localeText

  return <LocalizationProvider dateAdapter={AdapterDayjs}
                               adapterLocale={'fr'}
                               localeText={localeText}
  >
    <div className='datePickerButtonDiv'>
      <DatePicker
        label={labelStart}
        value={toDayjsNull(valueStart)}
        onChange={onChangeStart}
      />
      <button className='resetDatePickerButton' onClick={resetStart}>
        <ResetIcon />
      </button>
    </div>
    <div className='datePickerButtonDiv'>
      <DatePicker
        label={labelEnd}
        value={toDayjsNull(valueEnd)}
        onChange={onChangeEnd}
      />
      <button className='resetDatePickerButton' onClick={resetEnd}>
        <ResetIcon />
      </button>
    </div>
  </LocalizationProvider>
}

export {DatePickerRange}
