import {HPRIMId} from '@deepa-web/shared/src/type/hprim/HPRIMId'
import {BilanSanguinTabs} from './bilanSanguin'
import {ComponentUIDLabel} from '../../../../../../base/ComponentUIDLabel'

export interface RuleEngineProps extends HPRIMId {}

function BilanSanguinDiagnostics(props: RuleEngineProps) {

  return (
    <div className='bilanSanguinContainer'>
      <ComponentUIDLabel uid='CID-BilanSanguinTabs'>
          <div className='bilanSanguinTitle'>
              <h3> Compte-rendu biologique </h3>
          </div>
          <div className='bilanSanguinTabsDiv'>
              <BilanSanguinTabs hprimId={props.hprimId}/>
          </div>
      </ComponentUIDLabel>
    </div>
  )
}

export {BilanSanguinDiagnostics}
