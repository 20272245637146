import {PaginationState} from '@deepa-web/browser-shared/src/redux/hprimSummaryList/pagination/PaginationState'
import {paginationProviderWrapper} from '../../../../../../../redux/store/paginationProviderWrapper'
import {HprimSummaryType} from '@deepa-web/browser-shared/src/redux/hprimSummaryList/HprimSummaryType'

function useHPRIMlistOnePage(): HprimSummaryType[] {
  const state = paginationProviderWrapper.useSelector((s: PaginationState) => s),
    {list, page, rowsPerPage} = state,
    listCopy = [...list],
    sliceStart = page * rowsPerPage,
    sliceEnd = sliceStart + rowsPerPage

  return listCopy.slice(sliceStart, sliceEnd)
}

export {useHPRIMlistOnePage}
