import { formatDateIso } from '@deepa-web/shared/src/type/date/ExplicitDate';
import { filter } from 'lodash';
function filterNotificationByDateRange(list, access, startDate, endDate) {
    return filter([...list], (n) => {
        const examDate = formatDateIso(access(n));
        if (startDate && endDate) {
            return examDate >= formatDateIso(startDate) && examDate <= formatDateIso(endDate);
        }
        if (startDate && !endDate) {
            return examDate >= formatDateIso(startDate);
        }
        if (endDate && !startDate) {
            return examDate <= formatDateIso(endDate);
        }
        return true;
    });
}
export { filterNotificationByDateRange };
