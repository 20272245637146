import {TableBody, TableRow} from '@mui/material'
import {CellBody} from "../../../../base/tableCells/CellBody"
import {FaCircle} from 'react-icons/fa'


function BodyPatientHprimList() {
    return <TableBody>
        <TableRow>
            <CellBody> 0101010101 </CellBody>
            <CellBody> 03/09/2024 </CellBody>
            <CellBody> <FaCircle color='#f8d045'/> </CellBody>
        </TableRow>
    </TableBody>
}

export {BodyPatientHprimList}
