import {
  RadioButtons,
  RadioButtonsProps,
  ValueLabel
} from '../radio'

export enum LabelInfo {
  label = 'label',
  info = 'info'
}

const choices: ValueLabel<LabelInfo>[] = [
  {
    value: LabelInfo.label,
    label: 'Label'
  },
  {
    value: LabelInfo.info,
    label: 'Information'
  }
]

export function RadioButtonsLabelInfo(props: RadioButtonsProps<LabelInfo>): JSX.Element {
  const propsChoices = {...props, choices}
  return <RadioButtons {...propsChoices} />
}
