import {NavigateFunction} from 'react-router-dom'
import {
  BrowserRoutePaths,
  hprimIdUrl
} from '@deepa-web/shared/src/routes/BrowserRoutePaths'
import {HPRIMId} from '@deepa-web/shared/src/type/hprim/HPRIMId'
import {requestItemData} from '@deepa-web/browser-shared/src/saga/requestItemData'
import {OnClickProps} from '@deepa-web/browser-shared/src/type/OnClickProps'

function gotoPage(navigateTo: BrowserRoutePaths,
                  navigate: NavigateFunction): OnClickProps {

  return {onClick: () => navigate(navigateTo)}
}

function gotoDetailPage(
  route: BrowserRoutePaths,
  hprimId: HPRIMId,
  navigate: NavigateFunction)
  : OnClickProps {

  return {
    onClick: () => {
      requestItemData(hprimId)
      navigate(hprimIdUrl(route, hprimId))
    }
  }
}

export {
  gotoDetailPage,
  gotoPage
}
