import { DictionaryActionType } from '../common/dictionary/DictionaryAction';
import { ordonnanceSummaryListStoreProxy } from "./index";
function updateOrdonnancePdfList(hprimId) {
    const action = {
        type: DictionaryActionType.requestData,
        key: hprimId,
    };
    ordonnanceSummaryListStoreProxy.dispatch(action);
}
export { updateOrdonnancePdfList };
