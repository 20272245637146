import {NavigateFunction, useNavigate} from 'react-router-dom'
import {gotoPage} from '../../gotoDetailPage'
import {BrowserRoutePaths} from '@deepa-web/shared/src/routes/BrowserRoutePaths'
import {BaseButtonBack} from './BaseButtonBack'


function ButtonBackHome(): JSX.Element {
  const navigate: NavigateFunction = useNavigate(),
    instance = new BackHome(navigate)

  return instance.makeButtonBack()
}

class BackHome extends BaseButtonBack {
  private readonly _navigate

  constructor(navigate: NavigateFunction){
    super()
    this._navigate = navigate
  }

  protected onClick(path: BrowserRoutePaths) {
    return gotoPage(path, this._navigate)
  }

  protected get pathLabo(): BrowserRoutePaths {
    return BrowserRoutePaths.labo
  }

  protected get pathMedecin(): BrowserRoutePaths {
    return BrowserRoutePaths.medecin
  }
}

export {ButtonBackHome}
