import { initQueryState } from './SearchQueryState';
import { SearchQueryActionType } from './SearchQueryAction';
import { reverseSortOrder } from '@deepa-web/shared/src/type/SortOrder';
function searchQueryReducer(previousState = initQueryState(), action) {
    switch (action.type) {
        case SearchQueryActionType.setQuery:
            let { query } = action;
            if (query === undefined || query.length === 0) {
                query = '';
            }
            return Object.assign(Object.assign({}, previousState), { query });
        case SearchQueryActionType.reverseSortDateExamen:
            return Object.assign(Object.assign({}, previousState), { examDateSort: reverseSortOrder(previousState.examDateSort) });
        case SearchQueryActionType.examDateStart:
            const { examDateStart } = action;
            return Object.assign(Object.assign({}, previousState), { examDateStart: examDateStart !== null && examDateStart !== void 0 ? examDateStart : null });
        case SearchQueryActionType.examDateEnd:
            const { examDateEnd } = action;
            return Object.assign(Object.assign({}, previousState), { examDateEnd: examDateEnd !== null && examDateEnd !== void 0 ? examDateEnd : null });
        case SearchQueryActionType.showBilanUnread:
            return Object.assign(Object.assign({}, previousState), { showBilanUnread: !previousState.showBilanUnread });
        case SearchQueryActionType.showBilanAnormaux:
            return Object.assign(Object.assign({}, previousState), { showBilanAnormaux: !previousState.showBilanAnormaux });
        case SearchQueryActionType.showOnlyUnread:
            return Object.assign(Object.assign({}, previousState), { showOnlyUnread: !previousState.showOnlyUnread, showOnlyAnormaux: false, showUnreadAndAnormaux: false });
        case SearchQueryActionType.showOnlyAnormaux:
            return Object.assign(Object.assign({}, previousState), { showOnlyAnormaux: !previousState.showOnlyAnormaux, showOnlyUnread: false, showUnreadAndAnormaux: false });
        case SearchQueryActionType.showUnreadAndAnormaux:
            return Object.assign(Object.assign({}, previousState), { showUnreadAndAnormaux: !previousState.showUnreadAndAnormaux, showOnlyUnread: false, showOnlyAnormaux: false });
        case SearchQueryActionType.urgent_int: {
            const { urgent_int } = action;
            return Object.assign(Object.assign({}, previousState), { urgent_int: urgent_int !== null && urgent_int !== void 0 ? urgent_int : null });
        }
        case SearchQueryActionType.reset:
            return initQueryState();
        case SearchQueryActionType.applyFilter:
        default:
            return Object.assign({}, previousState);
    }
}
export { searchQueryReducer };
