import {ProgressState} from '@deepa-web/browser-shared/src/redux/factory/progress/ProgressState'
import {StoreProxy} from '@deepa-web/browser-shared/src/redux/factory/StoreProxy'
import {Action} from '@reduxjs/toolkit'
import {ProgressIndicator} from '../ProgressIndicator'
import {ProviderWrapper} from '../../../redux/ProviderWrapper'

export type ProgressStoreProxy<TState extends ProgressState,
  TAction extends Action> = StoreProxy<TState, TAction>

export type ElementFactory<TState> = (state: TState) => JSX.Element

function renderDataOrProgress<
  TState extends ProgressState,
  TAction extends Action>(
  elementFactory: ElementFactory<TState>,
  providerWrapper: ProviderWrapper<TState, TAction>):
  JSX.Element {

  const state: TState = providerWrapper.useSelector((s: TState) => s)

  return state.apiRequestIsInProgress ?
    <ProgressIndicator/> :
    elementFactory(state)
}

export {renderDataOrProgress}
