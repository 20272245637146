import {
  Box,
  Tab,
  Tabs
} from '@mui/material'
import React, {
  useEffect,
  useState
} from 'react'
import {DeepTabsProps} from './DeepTabsProps'

function DeepTabs(props: DeepTabsProps) {
  const [value, setValue] = useState(0),
    handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue)
    },
    {allyProps, tabStyle, orientation, tabLabels} = props,
    maxLabel = Math.max(0, tabLabels.length - 1),
    valueSafe = Math.min(maxLabel, value)

  useEffect(() => {
    if(value > valueSafe) {
      setValue(valueSafe)
    }
  }, [maxLabel, value, valueSafe])

  return (
    <Box className='boxContainer' sx={{width: '100%'}}>
      <Box className='tabBox' sx={{borderBottom: 1, borderColor: 'divider'}}>
        <Tabs value={valueSafe} onChange={handleChange}
              aria-label='application tabs'
              orientation={orientation}
        >
          {props.tabLabels.map((label, idx) =>
            <Tab label={label} {...allyProps(idx)} key={label}
                 sx={tabStyle}/>)}
        </Tabs>
      </Box>
      {props.childFactory.map(f => f(valueSafe))}
    </Box>
  )
}

export {DeepTabs}
