import {
    Accordion,
    AccordionDetails,
    AccordionSummary
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {BilanSanguinAttachments} from "./bilanSanguinAttachments";


const accordionStyle = {boxShadow: 'none', color: '#091954FF'},
    summaryStyle = {
        backgroundColor: 'white',
        fontSize: '1em',
        color: '#091954FF',
        fontWeight: 'bolder',
        border: '1px solid #eeeeee',
        borderRadius: '5px',
    },
    detailStyle = {
        padding: '1em',
        color: '#091954FF',
        fontSize: '1em',
        borderBottom: '1px solid #eeeeee',
        borderRight: '1px solid #eeeeee',
        borderLeft: '1px solid #eeeeee',
        borderTop: 'none',
        borderBottomRightRadius: '5px',
        borderBottomLeftRadius: '5px',
}

function BilanSanguinAttachmentsAccordion() {

    return <div className='attachmentsDiv'>
        <Accordion sx={accordionStyle}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                sx={summaryStyle}>
                Pièces jointes
            </AccordionSummary>
            <AccordionDetails sx={detailStyle}>
                <BilanSanguinAttachments />
            </AccordionDetails>
        </Accordion>
    </div>
}

export {BilanSanguinAttachmentsAccordion}
