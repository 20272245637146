import {
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material'
import {CellHeader} from '../../../../../../base/tableCells/CellHeader'
import {searchQueryProviderWrapper} from '../../../../../../../redux/store/searchQueryProviderWrapper'
import {SearchQueryState} from '@deepa-web/browser-shared/src/redux/hprimSummaryList/query/SearchQueryState'
import {sortDateExamen} from '@deepa-web/browser-shared/src/redux/hprimSummaryList/query'

function Header(): JSX.Element {
  const sortOrder = searchQueryProviderWrapper.useSelector((s: SearchQueryState) => s.examDateSort)

  return <TableHead>
    <TableRow>
      <CellHeader> Compte-rendu n° </CellHeader>
      <CellHeader> Nom </CellHeader>
      <CellHeader> Prénom </CellHeader>
      <CellHeader> Date de naissance </CellHeader>
      <CellHeader>
        <TableSortLabel active={true} direction={sortOrder}
                        onClick={sortDateExamen}>
          Date examen
        </TableSortLabel>
      </CellHeader>
      <CellHeader>  </CellHeader>
    </TableRow>
  </TableHead>
}

export {Header}
