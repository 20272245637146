import { storeFactory } from '../factory/storeFactory';
import { DictionaryReducer } from '../common/dictionary';
import { bilanSanguin1Saga } from '../../saga/bilanSanguin';
import { StoreProxy } from '../factory/StoreProxy';
class BilanSanguinReducer extends DictionaryReducer {
    keyToString({ hprimId }) {
        return hprimId;
    }
}
const reducerInstance = new BilanSanguinReducer(), bilanSanguin1Store = storeFactory(reducerInstance.dictionaryReducer.bind(reducerInstance), bilanSanguin1Saga), bilanSanguin1StoreProxy = new StoreProxy(bilanSanguin1Store);
export { bilanSanguin1StoreProxy };
