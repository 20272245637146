import { storeFactory } from '../../factory/storeFactory';
import { StoreProxy } from '../../factory/StoreProxy';
import { GETdoctorSignatureActionType } from './GETdoctorSignatureAction';
import { GETdoctorSignatureReducer } from './GETdoctorSignatureReducer';
import { GETdoctorSignatureSaga } from '../../../saga/doctorSignature/get';
const GETsignatureStore = storeFactory(GETdoctorSignatureReducer, GETdoctorSignatureSaga), GETsignatureStoreProxy = new StoreProxy(GETsignatureStore);
function requestSignature() {
    GETsignatureStore.dispatch({ type: GETdoctorSignatureActionType.clearFileFromStore });
    GETsignatureStore.dispatch({ type: GETdoctorSignatureActionType.makeRequest });
}
function deleteSignatureApi() {
    GETsignatureStore.dispatch({ type: GETdoctorSignatureActionType.deleteFileApi });
}
export { GETsignatureStoreProxy, requestSignature, deleteSignatureApi };
