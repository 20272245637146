import {Button} from '@mui/material'
import {ServerAppRoutes} from '@deepa-web/shared/src/routes/ServerAppRoutes'
import {ComponentUIDLabel} from '../../base/ComponentUIDLabel'
import {FiDownload} from 'react-icons/fi'
import {requestDownload} from '../../base/downloadBlob/requestDownload'
import {exportFeedbackFileName} from '@deepa-web/shared/src/type/FeedbackData'
import {RequestBuilder} from '@deepa-web/axios/src/builder/RequestBuilder'
import {ServerRouteParameter} from '@deepa-web/shared/src/routes/ServerRouteParameter'
import {GETrequestFactory} from '@deepa-web/axios/src/factory/GETrequestFactory'
import {RequestFactoryModuleArgBrowser} from '@deepa-web/browser-shared/src/apiClient/https/RequestFactoryModuleArgBrowser'

function DownloadFeedback() {
  return <ComponentUIDLabel uid='CID-DownloadFeedback'>
    <DownloadFeedbackLabeled/>
  </ComponentUIDLabel>
}

function DownloadFeedbackLabeled() {

  const buttonStyle = {
    fontFamily: 'graphie',
    fontSize: '1em'
  }

  return (
    <Button className='downloadFeedback'
            onClick={downloadButtonClicked}
            sx={buttonStyle}>
      <div>
        <span> Téléchargement des données </span>
        <FiDownload size={'1.5em'} color={'black'}/>
      </div>
    </Button>
  )
}

function downloadButtonClicked() {
  const routeParameter = new ServerRouteParameter(ServerAppRoutes.feedbackDownload),
    moduleArg = new RequestFactoryModuleArgBrowser<Blob>(),
    factory = new GETrequestFactory<Blob>(routeParameter, moduleArg, 'blob'),
    requestBuilder: RequestBuilder<Blob, null> = factory.Result()

  requestDownload(
    requestBuilder,
    exportFeedbackFileName
  )
}


export {DownloadFeedback}
