import { DictionaryKeyActionFactory } from '../redux/common/dictionary/DictionaryKeyActionFactory';
import { bilanSanguin1StoreProxy } from '../redux/bilanSanguin';
import { ordonnance1StoreProxy } from '../redux/ordonnance';
import { ruleEngine1StoreProxy } from '../redux/ruleEngine';
import { feedbackStoreProxy } from '../redux/feedback';
import { ordonnanceSummaryListStoreProxy } from '../redux/ordonnanceSummaryList';
import { resetEtapeStore } from '../redux/ordonnance/etape';
import { ordonnanceFormatStoreProxy } from '../redux/ordonnance/format';
import { bilanSanguinAttachmentSummaryListStoreProxy } from '../redux/bilanSanguinAttachmentSummaryList';
import { anomaly1StoreProxy } from "../redux/anomaly";
function requestItemData(hprimId) {
    const proxies = [
        bilanSanguin1StoreProxy,
        ruleEngine1StoreProxy,
        ordonnance1StoreProxy,
        feedbackStoreProxy,
        ordonnanceSummaryListStoreProxy,
        ordonnanceFormatStoreProxy,
        bilanSanguinAttachmentSummaryListStoreProxy,
        anomaly1StoreProxy
    ], factories = actionFactories(proxies);
    resetEtapeStore();
    for (const factory of factories) {
        factory.requestData(hprimId);
    }
}
function actionFactories(proxies) {
    return proxies.map(p => new DictionaryKeyActionFactory(p));
}
export { requestItemData };
