import { DictionaryActionType } from './DictionaryAction';
class DictionaryKeyActionFactory {
    constructor(storeProxy) {
        this._storeProxy = storeProxy;
    }
    requestData(key) {
        this.dispatchAction(key, DictionaryActionType.requestData);
    }
    postData(key) {
        this.dispatchAction(key, DictionaryActionType.postDataToAPI);
    }
    dispatchAction(key, actionType) {
        const action = {
            type: actionType,
            key
        };
        this._storeProxy.dispatch(action);
    }
}
export { DictionaryKeyActionFactory };
