import {Exam} from '@deepa-web/shared/src/type/awsLambda/ruleEngine/response/anomaly/Exam'
import {Exploration} from '@deepa-web/shared/src/type/awsLambda/ruleEngine/response/anomaly/Exploration'

function showDelay(exploration: Exploration<Exam>) {
  switch (exploration.type) {
    case 'suivi' :
      return <span className='suiviSpan'> Suivi de {exploration.delay} </span>
    default :
      return null
  }
}

export {showDelay}
