export var LoginStage;
(function (LoginStage) {
    LoginStage["notYetLoggedIn"] = "notYetLoggedIn";
    LoginStage["loggedIn"] = "loggedIn";
    LoginStage["loggedOut"] = "loggedOut";
})(LoginStage || (LoginStage = {}));
function initUserState() {
    return {
        loginStage: LoginStage.notYetLoggedIn
    };
}
export { initUserState };
