import {BarCodes} from './BarCodes'
import {DoctorInfo} from './DoctorInfo'

function Header(): JSX.Element {
  return <div className='templateHeader'>
    <BarCodes/>
    <DoctorInfo/>
  </div>
}

export {Header}
