import { DPRpropertyName } from '../DPRaction';
import { remove } from 'lodash';
function removeField(action, newState) {
    const data = action.data;
    switch (data.tag) {
        case DPRpropertyName.FINESS:
            delete newState.FINESS;
            break;
        case DPRpropertyName.service:
            delete newState.service;
            break;
        case DPRpropertyName.location:
            delete newState.location;
            break;
        case DPRpropertyName.phones:
            const phoneForRemoval = data.fieldValue, uid = phoneForRemoval.uid;
            remove(newState.phones, v => v.uid == uid);
    }
}
export { removeField };
