var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ServerAppRoutes } from '@deepa-web/shared/src/routes/ServerAppRoutes';
import { GETrequestFactory } from '@deepa-web/axios/src/factory/GETrequestFactory';
import { POSTrequestFactory } from '@deepa-web/axios/src/factory/POSTrequestFactory';
import { ContentTypeShort } from '@deepa-web/shared/src/type/ContentTypeShort';
import { ServerRouteParameter } from '@deepa-web/shared/src/routes/ServerRouteParameter';
import { RequestFactoryModuleArgBrowser } from '../../apiClient/https/RequestFactoryModuleArgBrowser';
function callGETendpoint(hprimId) {
    return __awaiter(this, void 0, void 0, function* () {
        const routeParameter = new ServerRouteParameter(ServerAppRoutes.ordonnance1);
        routeParameter.hprimId(hprimId);
        const factory = new GETrequestFactory(routeParameter, new RequestFactoryModuleArgBrowser(), 'text'), requestBuilder = factory.Result();
        return yield requestBuilder.resultContentOrDefault();
    });
}
function callPOSTendpoint(hprimId, ordonnance) {
    return __awaiter(this, void 0, void 0, function* () {
        const routeParameter = new ServerRouteParameter(ServerAppRoutes.ordonnance1);
        routeParameter.hprimId(hprimId);
        const factory = new POSTrequestFactory(routeParameter, ContentTypeShort.json, 'json', new RequestFactoryModuleArgBrowser(), ordonnance), requestBuilder = factory.Result(), formattedOrdonnance = yield requestBuilder.resultContentOrDefault();
        return formattedOrdonnance || [];
    });
}
export { callGETendpoint, callPOSTendpoint };
