import '../../../style/baseComponents/pageHeader.scss'
import {PageHeaderSimpleProps} from "./PageHeaderSimple";

export interface PageHeaderProps extends PageHeaderSimpleProps{
    backButton?: JSX.Element
    action?: JSX.Element
}

function PageHeaderComplex(props: PageHeaderProps) {

    const { backButton, pageTitle, action} = props

    return <div className='pageHeaderDiv'>
        <div>
            {backButton}
            <div> {pageTitle} </div>
        </div>
        <div>
            {action}
        </div>
    </div>
}

export {PageHeaderComplex}
