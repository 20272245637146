import {Navigate} from 'react-router'
import React from 'react'
import {ErrorPage} from '../error/ErrorPage'
import {
  CognitoUserRelevant,
  doctorLaboGroup,
  UserGroup
} from '@deepa-web/shared/src/type/CognitoUser'
import {BrowserRoutePaths} from '@deepa-web/shared/src/routes/BrowserRoutePaths'
import {UnknownUser} from '../error/UnknownUser'

function routeLoggedInUser(user?: CognitoUserRelevant): JSX.Element {
  if (!user) {
    return <UnknownUser/>
  }

  const group = doctorLaboGroup(user.group)

  switch (group) {
    case UserGroup.doctors:
      return <Navigate to={BrowserRoutePaths.medecin}/>
    case UserGroup.labos:
      return <Navigate to={BrowserRoutePaths.labo}/>
    default:
      return ErrorPage('groupe d\'utilisateurs imprévu')
  }
}

export {routeLoggedInUser}
