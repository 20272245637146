import {BrowserRoutePaths} from '@deepa-web/shared/src/routes/BrowserRoutePaths'
import {HPRIMId} from '@deepa-web/shared/src/type/hprim/HPRIMId'
import {
  NavigateFunction,
  useNavigate
} from 'react-router-dom'
import {gotoDetailPage} from '../../../../../base/gotoDetailPage'
import {
  NavigateToHPRIMbuttonProps,
  UserGroupAwareNavigateButton
} from './UserGroupAwareNavigateButton'

function NavigatetoHPRIMButton(hprimId: HPRIMId) {
  const navigate: NavigateFunction = useNavigate(),
    doctor = gotoDetailPage(BrowserRoutePaths.medecinHprimDetail, hprimId, navigate),
    labo = gotoDetailPage(BrowserRoutePaths.laboHprimDetail, hprimId, navigate),
    navigateProps: NavigateToHPRIMbuttonProps = {
      doctor,
      labo
    }

  return <UserGroupAwareNavigateButton {...navigateProps}/>
}

export {NavigatetoHPRIMButton}
