import {BinBsn} from './BinBsn'

class Bsn extends BinBsn {
  protected analyseDecimals(value: number, binBsn: number, unit: string): string {
    return value > binBsn ? `> ${binBsn}${unit} (BSN)` : ''
  }

  protected get threshold(): number | undefined | null {
    return this._bilanSanguinValue.bsn
  }
}

export {Bsn}
