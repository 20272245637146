import { ServerAppRoutes } from '@deepa-web/shared/src/routes/ServerAppRoutes';
import { ServerRouteParameter } from '@deepa-web/shared/src/routes/ServerRouteParameter';
import { ContentTypeShort } from '@deepa-web/shared/src/type/ContentTypeShort';
import { CallEndpointGeneric } from '../common/CallEndpointGeneric';
class DoctorDataCallEndpoints extends CallEndpointGeneric {
    get getSpecs() {
        return {
            responseType: 'json',
            serverRoute: new ServerRouteParameter(ServerAppRoutes.doctorDetail)
        };
    }
    get postSpecs() {
        return {
            contentType: ContentTypeShort.json,
            responseType: 'text',
            serverRoute: new ServerRouteParameter(ServerAppRoutes.doctorDetail)
        };
    }
}
export { DoctorDataCallEndpoints };
