import {TextField} from '@mui/material'
import React, {
  useEffect,
  useState
} from 'react'
import {
  DoctorDataAction,
  DoctorDataActionType,
  DoctorDataPropertyName
} from '@deepa-web/browser-shared/src/redux/doctorData/DoctorDataAction'
import {doctorDataStoreProxy} from '@deepa-web/browser-shared/src/redux/doctorData'
import {DoctorDataState} from '@deepa-web/browser-shared/src/redux/doctorData/DoctorDataState'
import {doctorDataProviderWrapper} from '../../../../redux/store/doctorDataProviderWrapper'

export interface DoctorDataFormTextFieldProps {
  id: string
  label: string
  fieldName: DoctorDataPropertyName
}

function DoctorDataFormTextField(props: DoctorDataFormTextFieldProps): JSX.Element {
  const initValue = doctorDataProviderWrapper.useSelector(
      (state: DoctorDataState) => state[props.fieldName]),

    [value, setValue] = useState(initValue || '')

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newValue = e.target.value
    if (newValue !== value) {
      setValue(newValue)
    }
  }

  useEffect(() => {
    const action: DoctorDataAction = {
      type: DoctorDataActionType.storeField,
      data: {
        tag: props.fieldName,
        fieldValue: value
      }
    }
    doctorDataStoreProxy.dispatch(action)
  }, [value, props.fieldName])

  return (
    <TextField
      id={props.id}
      name={props.id}
      label={props.label}
      value={value}
      onChange={onChange}
      margin='normal'
      required
      fullWidth
    />
  )
}

export {DoctorDataFormTextField}
