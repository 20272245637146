import {
  TabOrientationCSS,
  TabPanel
} from '../../../base/tab/tabFactory/TabPanel'
import {UploadImagePreview} from './UploadImagePreview'
import {ComponentUIDLabel} from '../../../base/ComponentUIDLabel'

function Tab3(selectedTab: number): JSX.Element {
  return <TabPanel value={selectedTab} index={2} key={2} orientation={TabOrientationCSS.horizontal}>
    <ComponentUIDLabel uid='CID-MCTab3'>
      <div className='uploadDiv'>
        <UploadImagePreview/>
      </div>
    </ComponentUIDLabel>
  </TabPanel>
}

export {Tab3}
