import {DoctorDataState} from '@deepa-web/browser-shared/src/redux/doctorData/DoctorDataState'
import {ValidatedOrdonnanceElement} from '../dataCheck/ValidatedOrdonnanceElement'
import {BarCode} from '../../../../../base/barCode/BarCode'
import {StringOfExactLength} from '../dataCheck/validator/StringOfExactLength'
import {RPPSexactLength} from '../dataCheck/validator/expectedDoctorPropertyLength'
import {doctorDataProviderWrapper} from '../../../../../../redux/store/doctorDataProviderWrapper'

function RPPS() {
  const RPPS = doctorDataProviderWrapper.useSelector(
    (state: DoctorDataState) => state.RPPS),
    validator = new StringOfExactLength(RPPSexactLength)

  return <ValidatedOrdonnanceElement
    data={RPPS}
    dataIsValid={validator.isOfExactLength.bind(validator)}
    warning='Veuillez renseigner un identifiant RPPS correct.'
  >
    <BarCode id='barcodeSvg1' data={RPPS!}/>
  </ValidatedOrdonnanceElement>
}

export {RPPS}
