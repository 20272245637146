import {Grid} from '@mui/material'
import {PatientHprimListTable} from "./table";


function PatientHprimList() {
    return <Grid className='patientHprimListGrid' item xs={12} sm={12} md={7}>
        <p> Historique patient </p>
        <PatientHprimListTable/>
    </Grid>
}

export {PatientHprimList}
