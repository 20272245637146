import {ErrorPageTemplate} from '../template/ErrorPageTemplate'

function ErrorPage(message: string): JSX.Element {
  return <ErrorPageTemplate>
    <div className='errorMessageDiv'>
      <span className='errorSpan'>  {message} </span>
    </div>
  </ErrorPageTemplate>
}

export {ErrorPage}
