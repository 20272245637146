import {
  SearchQueryAction,
  SearchQueryActionType
} from '@deepa-web/browser-shared/src/redux/hprimSummaryList/query/SearchQueryAction'
import {dispatchQuery} from './dispatchQuery'


function handleQuery(query?: string | null) {
  const action: SearchQueryAction = {
    type: SearchQueryActionType.setQuery,
    query: query === null ? undefined : query
  }
  dispatchQuery(action)
}

export {handleQuery}
