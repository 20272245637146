import {PropsWithChildren} from 'react'
import {OrdonnanceDataWarning} from './OrdonnanceDataWarning'

export interface ValidatedOrdonnanceElementProps<TData> extends PropsWithChildren {
  data: TData
  dataIsValid(data: TData): boolean
  warning: string
}

function ValidatedOrdonnanceElement<TData>(props: ValidatedOrdonnanceElementProps<TData>) {
  return props.dataIsValid(props.data) ?
    <>
      {props.children}
    </> :
    <OrdonnanceDataWarning>
      {props.warning}
    </OrdonnanceDataWarning>
}

export {ValidatedOrdonnanceElement}
