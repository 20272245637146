import React from 'react'
import {DatePickerRange} from '../../../../../../base/datePickerRange'
import {SearchQueryActionType} from '@deepa-web/browser-shared/src/redux/hprimSummaryList/query/SearchQueryAction'
import {searchQueryProviderWrapper} from '../../../../../../../redux/store/searchQueryProviderWrapper'
import {SearchQueryState} from '@deepa-web/browser-shared/src/redux/hprimSummaryList/query/SearchQueryState'
import {handleStartDate} from './handleStartDate'
import {handleEndDate} from './handleEndDate'
import {searchQueryStoreProxy} from '@deepa-web/browser-shared/src/redux/hprimSummaryList/query'

const urgentClass = {
  filtered: 'appliedFilter',
  unfiltered: 'nonAppliedFilter'
}

function HprimListFilterSorting() {
  return searchQueryProviderWrapper.ProviderWrapper(
    <HprimListFilterSortingHooked/>
  )
}

function HprimListFilterSortingHooked() {

  const storeState: SearchQueryState = searchQueryProviderWrapper.useSelector((s: SearchQueryState) => s),
    {
      examDateStart,
      examDateEnd,
      showBilanUnread,
      showBilanAnormaux,
      urgent_int
    } = storeState,

    buttonClassesUnread = showBilanUnread ? urgentClass.filtered : urgentClass.unfiltered,
    buttonClassesAnormaux = showBilanAnormaux ? urgentClass.filtered : urgentClass.unfiltered,


    handleUnreadOnClick = () => {
      searchQueryStoreProxy.dispatch({
        type: SearchQueryActionType.showBilanUnread
      })
    },

    handleAnormauxOnClick = () => {
      searchQueryStoreProxy.dispatch({
        type: SearchQueryActionType.showBilanAnormaux
      })
    },

    handleUrgenceBiologiqueOnClick = (clicked: number) => {
      const selected = clicked === urgent_int ? null : clicked
      searchQueryStoreProxy.dispatch({
        type: SearchQueryActionType.urgent_int,
        urgent_int: selected
      })
    }

  function urgentClassName(urgent: number) {
    return urgent === urgent_int ? urgentClass.filtered : urgentClass.unfiltered
  }

  return <div className='datePickerDiv'>
    <div className='examDatePicker'>
      <p>Filtre par date d'examen :</p>
      <div className='datePickerRangeInputs'>
        <DatePickerRange
          labelStart={'Début'}
          labelEnd={'Jusqu\'à'}
          valueStart={examDateStart}
          valueEnd={examDateEnd}
          onChangeStart={handleStartDate}
          onChangeEnd={handleEndDate}
          resetStart={() => handleStartDate(null)}
          resetEnd={() => handleEndDate(null)}
        />
      </div>
    </div>
    <div className='filterButtonsDiv'>
      <p> Filtres additonnels : </p>
      <div className='filterButtons'>
        <button className={buttonClassesUnread} onClick={handleUnreadOnClick}>
          Bilans non lus
        </button>
        <button className={buttonClassesAnormaux}
                onClick={handleAnormauxOnClick}>
          Bilans anormaux
        </button>
        <button className={urgentClassName(3)}
                onClick={() => handleUrgenceBiologiqueOnClick(3)}>
          Urgences biologiques
        </button>
        <button className={urgentClassName(2)}
                onClick={() => handleUrgenceBiologiqueOnClick(2)}>
          Anomalies significatives
        </button>
        <button className={urgentClassName(1)}
                onClick={() => handleUrgenceBiologiqueOnClick(1)}>
          Non significatif / Normal
        </button>
      </div>
    </div>
  </div>
}

export {HprimListFilterSorting}
