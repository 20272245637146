import {BrowserNotification} from '@deepa-web/shared/src/type/BrowserNotification'
import {formatDate} from '@deepa-web/shared/src/type/date/ExplicitDate'
import React from 'react'
import {NavigatetoHPRIMButton} from './NavigateToHPRIMbutton'


function NotificationDetail(notification: BrowserNotification): JSX.Element {
  const {patientPrenom, patientNom, dateExamen, hprimId} = notification

  return (
    <div className='notificationContentDetail'>
      Bilan sanguin de {patientPrenom} {patientNom} du {formatDate(dateExamen)}
      <NavigatetoHPRIMButton {...{hprimId}}/>
    </div>
  )
}

export {NotificationDetail}
