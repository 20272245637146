import {ServerRouteParameter} from '@deepa-web/shared/src/routes/ServerRouteParameter'
import {RequestFactoryModuleArgBrowser} from '@deepa-web/browser-shared/src/apiClient/https/RequestFactoryModuleArgBrowser'
import {GETrequestFactory} from '@deepa-web/axios/src/factory/GETrequestFactory'
import {RequestBuilder} from '@deepa-web/axios/src/builder/RequestBuilder'
import {requestDownload} from './requestDownload'

abstract class OnClickDownloadFile {
  protected abstract routeParameter(): ServerRouteParameter

  protected abstract get fileName(): string

  onClick() {
    const routeParameter = this.routeParameter()

    const moduleArg = new RequestFactoryModuleArgBrowser<Blob>(),
      factory = new GETrequestFactory<Blob>(routeParameter, moduleArg, 'blob'),
      requestBuilder: RequestBuilder<Blob, null> = factory.Result(),
      fileName = this.fileName

    requestDownload(
      requestBuilder,
      fileName
    )
  }
}

export {OnClickDownloadFile}
