import React, {
  PropsWithChildren,
  ReactElement
} from 'react'
import {styled} from '@mui/material/styles'
import {
  Tooltip,
  TooltipProps,
  tooltipClasses
} from '@mui/material'


export interface CustomTooltipProps extends PropsWithChildren {
  title: string | React.ReactNode,
  placement: 'bottom' | 'left' | 'right' | 'top' | 'bottom-end' | 'bottom-start' | 'left-end' |
    'left-start' | 'right-end' | 'right-start' | 'top-end' | 'top-start' | undefined
}

const StyledTooltip = styled(({className, ...props}: TooltipProps) => (
  <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgb(9, 25, 84, 0.8)',
    color: 'white',
    fontSize: 12,
    border: '1px, solid, #091954',
    borderRadius: '5px',
    boxShadow: theme.shadows[1],
    padding: '0.5em'
  }
}))

function CustomTooltip(props: CustomTooltipProps) {
  const {title, placement, children} = props,
    child = children as ReactElement<any, any>

  return <StyledTooltip title={title} placement={placement}>
    {child}
  </StyledTooltip>
}

export {CustomTooltip}
