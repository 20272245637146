import { storeFactory } from '../factory/storeFactory';
import { DictionaryReducer } from '../common/dictionary';
import { StoreProxy } from '../factory/StoreProxy';
import { anomaly1Saga } from "../../saga/anomaly";
class AnomalyReducer extends DictionaryReducer {
    keyToString({ hprimId }) {
        return hprimId;
    }
}
const reducerInstance = new AnomalyReducer(), anomaly1Store = storeFactory(reducerInstance.dictionaryReducer.bind(reducerInstance), anomaly1Saga), anomaly1StoreProxy = new StoreProxy(anomaly1Store);
export { anomaly1StoreProxy };
