import { call, put, takeEvery } from 'redux-saga/effects';
import { DictionaryActionType } from '../../redux/common/dictionary/DictionaryAction';
import { dictionaryTypeActionFactory } from '../../redux/common/dictionary/dictionaryTypeActionFactory';
import { callOrdonnanceFormatGETendpoint } from './callEndpoints';
const requested = new Set();
function* ordonnanceFormatSaga() {
    yield takeEvery(DictionaryActionType.requestData, wrapCancelAction);
}
function* wrapCancelAction(action) {
    const cancelAction = dictionaryTypeActionFactory(DictionaryActionType.cancelRequest);
    yield peekAction(action);
    yield put(cancelAction);
}
function* peekAction(action) {
    const key = action.key.hprimId;
    if (requested.has(key)) {
        return;
    }
    requested.add(key);
    const ordonnaceFormatted = (yield call(callOrdonnanceFormatGETendpoint, action.key));
    if (ordonnaceFormatted === null) {
        requested.delete(key);
        return;
    }
    const newAction = {
        type: DictionaryActionType.storeData,
        key: action.key,
        item: ordonnaceFormatted
    };
    yield put(newAction);
    requested.delete(key);
}
export { ordonnanceFormatSaga };
