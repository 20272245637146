import { initComponentUIDState } from './ComponentUIDState';
import { ComponentUIDActionType } from './ComponentUIDAction';
function componentUIDreducer(previousState = initComponentUIDState(), action) {
    let displayUID = previousState.displayUID;
    switch (action.type) {
        case ComponentUIDActionType.showUID:
            displayUID = true;
            break;
        case ComponentUIDActionType.hideUID:
            displayUID = false;
            break;
        default:
            break;
    }
    return { displayUID };
}
export { componentUIDreducer };
