class UserGroupFunctorClick {
    constructor(elementFactory, usergroupFunctor) {
        this._elementFactory = elementFactory;
        this._usergroupFunctor = usergroupFunctor;
    }
    result() {
        const callback = this._usergroupFunctor.result();
        return this._elementFactory(callback);
    }
}
export { UserGroupFunctorClick };
