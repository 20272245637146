import {GETdoctorSignatureState} from '@deepa-web/browser-shared/src/redux/doctorSignature/get/GETdoctorSignatureState'
import {ValidatedOrdonnanceElement} from '../../composite/doctorLabo/doctor/ordonnancePreview/dataCheck/ValidatedOrdonnanceElement'
import {isTrue} from '../../composite/doctorLabo/doctor/ordonnancePreview/dataCheck/validator/isTrue'
import {PropsWithChildren} from 'react'
import {GETsignatureProviderWrapper} from '../../../redux/store/GETsignatureProviderWrapper'

function Signature(props: PropsWithChildren): JSX.Element {
  return GETsignatureProviderWrapper.ProviderWrapper(
    <SignatureHooked>{props.children}</SignatureHooked>)
}

function SignatureHooked(props: PropsWithChildren): JSX.Element {
  const exist: boolean = GETsignatureProviderWrapper.useSelector((state: GETdoctorSignatureState) => state.fileIsStoredInService)
  return <ValidatedOrdonnanceElement
    warning='Veuillez ajouter votre signature.'
    data={exist}
    dataIsValid={isTrue}
  >
    {props.children}
  </ValidatedOrdonnanceElement>
}

export {Signature}
