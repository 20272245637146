import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from '@mui/material'
import {
  ChangeEvent,
  useState
} from 'react'

export interface RadioButtonsChoicesProps<TChoice>
  extends RadioButtonsProps<TChoice>{
  choices: ValueLabel<TChoice>[]
}

export interface RadioButtonsProps<TChoice> {
  initChoice: TChoice
  setChoice: (newChoice: TChoice) => void
}

export interface ValueLabel<TChoice> {
  value: TChoice
  label: string
}

export function RadioButtons<TChoice extends string>(
  props: RadioButtonsChoicesProps<TChoice>): JSX.Element {

  const {choices, initChoice, setChoice} = props,
    [choice, setChoiceState] = useState(initChoice),
    radioFactory: (valueLabel: ValueLabel<TChoice>, idx: number) => JSX.Element = singleChoiceFactory(choice)

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    const newValue: string = (event.target as HTMLInputElement).value,
      newValueLabelInfo = newValue as TChoice

    setChoiceState(newValueLabelInfo)
    setChoice(newValueLabelInfo)
  }

  return <RadioGroup value={choice}
                     onChange={onChange}>
    <FormLabel>
      {choices.map(radioFactory)}
    </FormLabel>
  </RadioGroup>
}

function singleChoiceFactory<TChoice>(choice: TChoice) {
  return (valueLabel: ValueLabel<TChoice>, idx: number) => {
    const {label, value} = valueLabel
    return <FormControlLabel value={value}
                             key={idx}
                             checked={choice === value}
                             control={<RadioStyled/>}
                             label={label}/>
  }
}

function RadioStyled(props: any): JSX.Element {
  return <Radio {...props} sx={{
    '& .MuiSvgIcon-root': {
      fontSize: 14
    }
  }}/>
}
