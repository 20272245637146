import {
  ActionStatus,
  ContentError
} from '@deepa-web/shared/src/type/ContentError'
import {RequestBuilder} from '@deepa-web/axios/src/builder/RequestBuilder'
import {processReceivedBlob} from './processReceivedBlob'

function requestDownload(builder: RequestBuilder<Blob, null>,
                         fileNameWithExtension: string,
                         successCallBack: () => void = () => false) {

  builder.callApi().then((contentError: ContentError<Blob, any>) => {
    if (contentError.status === ActionStatus.fail) {
      return
    }

    processReceivedBlob(contentError.content, fileNameWithExtension)

    successCallBack()
  })
}

export {requestDownload}

