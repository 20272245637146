"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AxiosRequestConfigBuilder = void 0;
class AxiosRequestConfigBuilder {
    constructor(initConfig) {
        this._result = initConfig || {};
    }
    get getAxiosRequestConfig() {
        return this._result;
    }
    setQueryParams(params) {
        this._result.params = params;
    }
    setAgent(agent) {
        this._result.httpsAgent = agent;
    }
    setResponseType(responseType) {
        this._result.responseType = responseType;
    }
    setHeaders(headers) {
        this._result.headers = headers;
    }
}
exports.AxiosRequestConfigBuilder = AxiosRequestConfigBuilder;
