import {BooleanFunctor} from '../../functor/BooleanFunctor'
import {
  AllyPropsFunction,
  allyPropsHorizontal,
  allyPropsVertical
} from '../style/allyProps'

class AllyProps extends BooleanFunctor<AllyPropsFunction> {
  protected get match(): AllyPropsFunction {
    return allyPropsVertical
  }

  protected get noMatch(): AllyPropsFunction {
    return allyPropsHorizontal
  }
}

export {AllyProps}
