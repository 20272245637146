import {BilanSanguinValue} from '@deepa-web/shared/src/type/awsLambda/ruleEngine/response/BilanSanguin'

abstract class BinBsn {
  protected readonly _bilanSanguinValue: BilanSanguinValue

  constructor(bilanSanguinValue: BilanSanguinValue) {
    this._bilanSanguinValue = bilanSanguinValue
  }

  private static toNumber(text?: string | number | null): number | null {
    switch (text) {
      case undefined:
      case null:
        return null
      default:
        return typeof text === 'number' ? text : parseFloat(text)
    }
  }

  protected abstract analyseDecimals(value: number,
                                     binBsn: number,
                                     unit: string): string

  protected abstract get threshold(): number | undefined | null

  get display(): string {
    const {value, unit} = this._bilanSanguinValue,
      threshhold = this.threshold,
      valueDec: number | null = BinBsn.toNumber(value),
      thresholdDec: number | null = BinBsn.toNumber(threshhold)

    if (valueDec === null || thresholdDec === null) {
      return ''
    }

    return this.analyseDecimals(valueDec, thresholdDec, unit || '')
  }
}

export {BinBsn}
