import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

const boxStyle = {display: 'flex', alignContent: 'center', minHeight: '5em'}

function ProgressIndicator() {
  return (
    <Box sx={boxStyle}>
      <CircularProgress/>
    </Box>
  )
}

export {ProgressIndicator}
