import {DownloadFeedback} from './DownloadFeedback'
import '../../../style/adminPage.scss'
import AuthorisationProxy from '../../base/authorise/AuthorisationProxy'
import {UserGroup} from '@deepa-web/shared/src/type/CognitoUser'
import {ComponentUIDLabel} from '../../base/ComponentUIDLabel'
import {FeedbackTable} from './recentFeedback'
import {nrFeedbackRecords} from '@deepa-web/browser-shared/src/saga/feedbackSummary'
import {ApplicationTemplate} from '../../base/template/ApplicationTemplate'
import {PageHeaderSimple} from "../../base/pageHeader/PageHeaderSimple";

function AdminPage() {
  return <AuthorisationProxy allowedGroups={[UserGroup.admin]}>
      <AdminPageAuthorised/>
  </AuthorisationProxy>
}

function AdminPageAuthorised() {
  return (
    <ComponentUIDLabel uid='CID-AdminPage'>
      <ApplicationTemplate>
        <PageHeaderSimple pageTitle={'Page Administrateur'} />
        <div className='adminDiv'>
          <h3> Les {nrFeedbackRecords} derniers feedbacks </h3>
          <FeedbackTable/>
          <DownloadFeedback/>
        </div>
      </ApplicationTemplate>
    </ComponentUIDLabel>
  )
}

export {AdminPage}
