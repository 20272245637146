import { errorHandler } from './errorHandler';
import { ContentErrorFunctor } from '@deepa-web/shared/src/type/ContentErrorFunctor';
class ContentErrorFunctorBrowser extends ContentErrorFunctor {
    constructor(routeParameter) {
        super();
        this._routeParameter = routeParameter;
    }
    handleError(error) {
        errorHandler(this._routeParameter.Result, error);
    }
    ifError() {
        return null;
    }
}
export { ContentErrorFunctorBrowser };
