import {DeepTabsResponsiveFunctorFactory} from '../DeepTabsResponsiveProps'
import {AllyProps} from './AllyProps'
import {OrientationQuery} from './OrientationQuery'
import {TabStyleFunctor} from './TabStyleFunctor'
import {TabOrientationTag} from '../enum/TabOrientationTag'
import {TabStyle} from '../style/tabStyle'

class MediaQueryResponsiveFactory implements DeepTabsResponsiveFunctorFactory {
  private readonly _allyProps: AllyProps
  private readonly _orientationQuery: OrientationQuery
  private readonly _tabStyle: TabStyleFunctor

  constructor(isWideScreen: boolean){
    this._allyProps = new AllyProps(isWideScreen)
    this._orientationQuery = new OrientationQuery(isWideScreen)
    this._tabStyle = new TabStyleFunctor(isWideScreen)
  }

  get allyProps() {
    return this._allyProps.value
  }

  get orientation(): TabOrientationTag {
    return this._orientationQuery.value
  }

  get tabStyle(): TabStyle {
    return this._tabStyle.value
  }
}

export {MediaQueryResponsiveFactory}
