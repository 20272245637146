"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hprimIdUrl = exports.BrowserRoutePathsExpress = exports.BrowserRoutePaths = void 0;
var BrowserRoutePaths;
(function (BrowserRoutePaths) {
    BrowserRoutePaths["account"] = "/medecin/compte";
    BrowserRoutePaths["admin"] = "/admin";
    BrowserRoutePaths["appRoot"] = "/";
    BrowserRoutePaths["contact"] = "/contact";
    BrowserRoutePaths["labo"] = "/labo";
    BrowserRoutePaths["laboHprimDetail"] = "/labo/hprim/detail/:hprimId";
    BrowserRoutePaths["medecin"] = "/medecin";
    BrowserRoutePaths["medecinHprimDetail"] = "/medecin/hprim/detail/:hprimId";
    BrowserRoutePaths["medecinHprimUpload"] = "/medecin/hprim/upload";
    BrowserRoutePaths["ordonnancePreview"] = "/medecin/ordonnance/voir/:hprimId";
    BrowserRoutePaths["patientDetail"] = "/medecin/patient-detail";
})(BrowserRoutePaths = exports.BrowserRoutePaths || (exports.BrowserRoutePaths = {}));
var BrowserRoutePathsExpress;
(function (BrowserRoutePathsExpress) {
    BrowserRoutePathsExpress["account"] = "/medecin/compte";
    BrowserRoutePathsExpress["admin"] = "/admin";
    BrowserRoutePathsExpress["appRoot"] = "/";
    BrowserRoutePathsExpress["contact"] = "/contact";
    BrowserRoutePathsExpress["labo"] = "/labo";
    BrowserRoutePathsExpress["laboHprimDetail"] = "/labo/hprim/detail/*";
    BrowserRoutePathsExpress["medecin"] = "/medecin";
    BrowserRoutePathsExpress["medecinHprimDetail"] = "/medecin/hprim/detail/*";
    BrowserRoutePathsExpress["medecinHprimUpload"] = "/medecin/hprim/upload";
    BrowserRoutePathsExpress["ordonnancePreview"] = "/medecin/ordonnance/voir/*";
    BrowserRoutePathsExpress["patientDetail"] = "/medecin/patient-detail";
})(BrowserRoutePathsExpress = exports.BrowserRoutePathsExpress || (exports.BrowserRoutePathsExpress = {}));
function hprimIdUrl(browserPath, { hprimId }) {
    return browserPath.replace(':hprimId', hprimId);
}
exports.hprimIdUrl = hprimIdUrl;
