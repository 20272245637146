import {withAuthenticator} from '@aws-amplify/ui-react'
import {authenticatorOptions} from '../../../configuration/authenticatorOptions'
import {loginEffect} from './loginEffect'
import {UnknownUser} from '../error/UnknownUser'
import React, {
  PropsWithChildren,
  ReactElement,
  useEffect
} from 'react'
import {
  CognitoUserRelevant,
  mapToRelevant,
  UserGroup
} from '@deepa-web/shared/src/type/CognitoUser'
import {intersection} from 'lodash'
import {Unauthorised} from '../error/Unauthorised'
import {AuthArg} from '@deepa-web/browser-shared/src/type/AuthArg'

export interface AuthorisationProxyProps
  extends AuthArg,
          PropsWithChildren {

  allowedGroups: UserGroup[]
  onLogin?: () => void
  childFactory?: (authArg: AuthArg) => ReactElement
}

function AuthorisationProxy(props?: AuthorisationProxyProps) {
  useEffect(loginEffect(props, props?.onLogin), [props?.user?.username])

  if (!props?.user) {
    return <UnknownUser/>
  }

  const userRelevant: CognitoUserRelevant = mapToRelevant(props.user),
    matchedGroups: UserGroup[] = intersection(props.allowedGroups, userRelevant.group)

  if (matchedGroups.length === 0) {
    return <Unauthorised/>
  }

  return props?.childFactory ?
    props.childFactory(props) :
    props?.children as ReactElement
}

export default withAuthenticator(AuthorisationProxy, authenticatorOptions)
