import {DoctorDataState} from '@deepa-web/browser-shared/src/redux/doctorData/DoctorDataState'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import {FiInfo} from 'react-icons/fi'
import {doctorDataProviderWrapper} from '../../../../redux/store/doctorDataProviderWrapper'

const tooltipText = 'En cas d\'erreur, veuillez contacter notre administrateur.'

function RppsDisplay() {

  const RPPS = doctorDataProviderWrapper.useSelector(
    (state: DoctorDataState) => state.RPPS)

  return <div className='infoIdentifier'>
    <p> RPPS : <span className='identifierValue'> {RPPS} </span></p>
    <Tooltip title={tooltipText}>
      <IconButton>
        <FiInfo/>
      </IconButton>
    </Tooltip>
  </div>
}

export {RppsDisplay}
