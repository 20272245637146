import UserLogo from '../../../../images/Profile.png'

export interface MenuUserInfoProps {
  lastName?: string
  firstName?: string
}

function MenuUserInfo(props: MenuUserInfoProps) {
  const displayName = props?.firstName && props?.lastName
  return <div className='menuUserInfoDiv'>
    <img className='userLogo' src={UserLogo} alt={'User Logo'}/>

    {displayName ?
      <span
        className='menuUserInfoSpan'>
        {props.lastName} {props.firstName}
      </span> :
      <span/>
    }
  </div>
}

export {MenuUserInfo}
