import {Source} from '@deepa-web/shared/src/type/awsLambda/ruleEngine/response/anomaly/Source'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const accordionStyle = {boxShadow: 'none', color: '#091954FF'},
    summaryStyle = {
        backgroundColor: 'white',
        fontSize: '1em',
        color: '#091954FF',
        fontWeight: 'bolder',
        border: '1px solid #e7edfe',
        borderRadius: '5px',
        height: '1em',
        paddingLeft: '1em',
    },
    detailStyle = {
        padding: '1em',
        color: '#091954FF',
        fontSize: '1em',
        borderBottom: '1px solid #e7edfe',
        borderRight: '1px solid #e7edfe',
        borderLeft: '1px solid #e7edfe',
        borderTop: 'none',
        borderBottomRightRadius: '5px',
        borderBottomLeftRadius: '5px',
    }

export interface AnomalySourcesProps {
  sources: Source[]
}

function AnomalySources(props: AnomalySourcesProps) {
  const {sources} = props


  return <div className='explorationSourcesDiv'>
      {sources.length > 0 ?
          <Accordion sx={accordionStyle}>
              <AccordionSummary
                  expandIcon={<ExpandMoreIcon/>}
                  sx={summaryStyle}>
                      <p> Sources</p>
              </AccordionSummary>
              <AccordionDetails sx={detailStyle}>
                  <ul>
                      {sources.map((source, index: number) => {
                          return <li className='itemList' key={index}>
                              <a href={source.link} target='_blank'>
                                  {source.label}.
                              </a>
                          </li>
                      })}
                  </ul>
              </AccordionDetails>
          </Accordion> :
          <span/>
      }

  </div>
}

export {AnomalySources}
