import {promptDeleteProps} from './promptDeleteProps'
import {useState} from 'react'
import {HPRIMId} from '@deepa-web/shared/src/type/hprim/HPRIMId'
import {PromptDelete} from '../../../../../../base/button/PromptDelete'
import {useNavigate} from 'react-router-dom'
import {ReactComponent as DeleteIcon} from '../../../../../../../images/icons/delete.svg'
import '../../../../../../../style/hprimDetailPage/hprimView.scss'

function DeleteButton(hprimId: HPRIMId): JSX.Element {
  const [open, setOpen] = useState(false),
    navigate = useNavigate(),
    promptDeleteProps1 = promptDeleteProps(hprimId, navigate, open, setOpen)

  return <div className='deleteButtonDiv'>
      <button className='itemButton' onClick={() => setOpen(true)}>
        <DeleteIcon />
      </button>
      <PromptDelete {...promptDeleteProps1} />
  </div>
}

export {DeleteButton}
