import {
  TabOrientationCSS,
  TabPanel
} from '../../../base/tab/tabFactory/TabPanel'
import {DPRform} from './DPRform'
import {ComponentUIDLabel} from '../../../base/ComponentUIDLabel'

function Tab2(selectedTab: number): JSX.Element {
  return <TabPanel value={selectedTab} index={1} key={1} orientation={TabOrientationCSS.horizontal}>
    <ComponentUIDLabel uid='CID-MCTab2'>
      <div className='professionalForm'>
        <DPRform/>
      </div>
    </ComponentUIDLabel>
  </TabPanel>
}

export {Tab2}
