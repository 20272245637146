import {UserGroupFunctorClick} from '@deepa-web/browser-shared/src/redux/user/userGroup/UserGroupFunctorClick'
import {BackButton} from './BackButton'
import {UserGroupFunctorDoctorLabo} from '@deepa-web/browser-shared/src/redux/user/userGroup/UserGroupFunctorDoctorLabo'
import {UserGroup} from '@deepa-web/shared/src/type/CognitoUser'
import {BrowserRoutePaths} from '@deepa-web/shared/src/routes/BrowserRoutePaths'
import {OnClickProps} from '@deepa-web/browser-shared/src/type/OnClickProps'

abstract class BaseButtonBack {
  makeButtonBack() {
    const functor = this.makeFunctor()
    return functor.result()
  }

  protected abstract get pathMedecin(): BrowserRoutePaths

  protected abstract get pathLabo(): BrowserRoutePaths

  protected abstract onClick(path: BrowserRoutePaths): OnClickProps

  private makeFunctor(): UserGroupFunctorClick {
    const onclickDetailMedecin: OnClickProps = this.onClick(this.pathMedecin),
      onclickDetailLabo: OnClickProps = this.onClick(this.pathLabo),
      map = new Map<UserGroup, OnClickProps>()

    map.set(UserGroup.doctors, onclickDetailMedecin)
    map.set(UserGroup.labos, onclickDetailLabo)

    return new UserGroupFunctorClick(
      BackButton,
      new UserGroupFunctorDoctorLabo(map))
  }
}

export {BaseButtonBack}
