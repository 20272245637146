import {HPRIMId} from '@deepa-web/shared/src/type/hprim/HPRIMId'
import {PieceJointeDecrite} from '@deepa-web/shared/src/type/PieceJointeDecrite'
import {DictionaryState} from '@deepa-web/browser-shared/src/redux/common/dictionary/DictionaryState'
import {
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableBody
} from '@mui/material'
import {SingleItemBilanAttachment} from "./SingleItemBilanAttachment";
import {
    bilanSanguinAttachmentSummaryListProviderWrapper
} from "../../../../../../../../../redux/store/bilanSanguinAttachmentSummaryListProviderWrapper";
import {ListElementFactoryWithChildren} from "../../../../../../../../base/functor/ListElementFactoryWithChildren";
import {CellHeader} from "../../../../../../../../base/tableCells/CellHeader";
import {
    Params,
    useParams
} from 'react-router-dom'



function BilanSanguinAttachments() {

    const params: Readonly<Params> = useParams(),
        {hprimId} = params

    const hprimId1: HPRIMId = {hprimId: hprimId!}

    return bilanSanguinAttachmentSummaryListProviderWrapper.ProviderWrapper(
        <BilanSanguinAttachmentsHooked {...hprimId1} />
    )
}

function BilanSanguinAttachmentsHooked(hprimId: HPRIMId) {

    const bilanSanguinAttachmentList: PieceJointeDecrite[] = bilanSanguinAttachmentSummaryListProviderWrapper.useSelector(
        (s: DictionaryState<PieceJointeDecrite[]>) => s.items[hprimId.hprimId] || [])

    return <ListElementFactoryWithChildren list={bilanSanguinAttachmentList} noDataMessage='Aucune pièce jointe disponible'>
        <TableContainer className='attachmentTable'>
            <Table>
                <TableHead>
                    <TableRow>
                        <CellHeader width='5em'> ID </CellHeader>
                        <CellHeader width='5em'> Nom </CellHeader>
                        <CellHeader width='5em'> Format </CellHeader>
                        <CellHeader width='2em'> Télécharger </CellHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {bilanSanguinAttachmentList?.map(renderItemList)}
                </TableBody>
            </Table>
        </TableContainer>
    </ListElementFactoryWithChildren>
}

function renderItemList(props: PieceJointeDecrite) {
    return <SingleItemBilanAttachment {...props} key={props.piece_jointe_id}/>
}


export {BilanSanguinAttachments}
