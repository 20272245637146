class StringOfExactLength {
  private readonly _expectedLength: number

  constructor(expectedLength: number){
    this._expectedLength = expectedLength
  }

  isOfExactLength(text?: string): boolean {
    return text !== undefined && text.length === this._expectedLength
  }
}

export {StringOfExactLength}
