var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { call, put, takeEvery } from 'redux-saga/effects';
import { GETrequestFactory } from '@deepa-web/axios/src/factory/GETrequestFactory';
import { ServerAppRoutes } from '@deepa-web/shared/src/routes/ServerAppRoutes';
import { ListActionType } from '../../redux/common/list/ListAction';
import { ServerRouteParameter } from '@deepa-web/shared/src/routes/ServerRouteParameter';
import { RequestFactoryModuleArgBrowser } from '../../apiClient/https/RequestFactoryModuleArgBrowser';
let isRequested = false;
const nrFeedbackRecords = 5;
function* feedbackSummarySaga() {
    yield takeEvery([
        ListActionType.requestData,
        ListActionType.storeData
    ], peekAction);
}
function* peekAction(action) {
    switch (action.type) {
        case ListActionType.requestData:
            if (isRequested) {
                break;
            }
            isRequested = true;
            const feedbackList = (yield call(callEndpoint)), newAction = {
                type: ListActionType.storeData,
                items: feedbackList || []
            };
            yield put(newAction);
            isRequested = false;
            break;
        case ListActionType.storeData:
            break;
    }
}
function callEndpoint() {
    return __awaiter(this, void 0, void 0, function* () {
        const routeParameter = new ServerRouteParameter(ServerAppRoutes.feedbackRecent);
        routeParameter.nr(nrFeedbackRecords);
        const factory = new GETrequestFactory(routeParameter, new RequestFactoryModuleArgBrowser(), 'json'), requestBuilder = factory.Result();
        return yield requestBuilder.resultContentOrDefault();
    });
}
export { feedbackSummarySaga, nrFeedbackRecords };
