import {useState, ChangeEvent} from 'react'
import {FormGroup, FormLabel, FormControlLabel, Switch} from '@mui/material'
import { alpha, styled } from '@mui/material/styles'

 export type ValueLabel<T> = {
    label: string,
    value: T
}

 export type SwitchProps<TChoice extends string> = {
    initChoice: TChoice
    setChoice: (choice: TChoice) => void
}

export interface SwitchChoicesProps<TChoice extends string> extends SwitchProps<TChoice> {
    choices: ValueLabel<TChoice>
}

const SwitchButtonStyled = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#ef3d3d',
        '&:hover': {
            backgroundColor: alpha('#ef3d3d', theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#ef3d3d',
    },
}))


export function SwitchButton<TChoice extends string>(
    props: SwitchProps<TChoice>
): JSX.Element {
    const {initChoice, setChoice} = props,
        [choice, setChoiceState] = useState<TChoice>(initChoice),
        onChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
            const newValue = checked ? 'anormale' : 'tous'
            setChoiceState(newValue as TChoice)
            setChoice(newValue as TChoice)
        }

    return <FormGroup>
        <FormLabel>
            <FormControlLabel label={'Seulement les valeurs anormales'}
                              control={
                                  <SwitchButtonStyled checked={choice === 'anormale'}
                                          onChange={onChange}
                                          value={choice}
                                          color={'warning'}
                                  />}/>
        </FormLabel>
    </FormGroup>
}
