import {
  ListElementFactory,
  ListElementFactoryProps
} from '../../../../../base/functor/ListElementFactory'
import {NotificationDetail} from '../detail'
import {BrowserNotification} from '@deepa-web/shared/src/type/BrowserNotification'


class NotificationContent extends ListElementFactory<BrowserNotification,
  ListElementFactoryProps<BrowserNotification>> {

  protected get match(): JSX.Element | null {
    return <div className='notificationContent'>
      <div>
        {this.props.list.map((notification, index) =>
          <NotificationDetail {...notification} key={index}/>)}
      </div>
    </div>
  }
}

export {NotificationContent}
