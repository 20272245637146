function filterNotificationByUrgentInt(list, urgent_int) {
    const result = [...list];
    let filter = null;
    switch (urgent_int) {
        case null:
            return result;
        case 0:
        case 1:
            filter = filterPredicate(0, 2, false);
            break;
        case 2:
            filter = filterPredicate(2, 4, true);
            break;
        case 3:
            filter = filterPredicate(3, 4, true);
            break;
        default:
            filter = n => n.urgent_int === urgent_int;
    }
    return result.filter(filter);
}
function filterPredicate(min, max, includeMax) {
    return (n) => {
        const maxOk = includeMax ? n.urgent_int <= max : n.urgent_int < max;
        return maxOk && n.urgent_int >= min;
    };
}
export { filterNotificationByUrgentInt };
