"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.contentTypeHTTP = exports.ContentTypeShort = void 0;
var ContentTypeShort;
(function (ContentTypeShort) {
    ContentTypeShort["json"] = "json";
    ContentTypeShort["text"] = "text";
    ContentTypeShort["file"] = "file";
    ContentTypeShort["none"] = "none";
})(ContentTypeShort = exports.ContentTypeShort || (exports.ContentTypeShort = {}));
function contentTypeHTTP(contentType1) {
    switch (contentType1) {
        case ContentTypeShort.json:
            return 'application/json';
        case ContentTypeShort.text:
            return 'text/plain; charset=UTF-8';
        case ContentTypeShort.file:
            return 'multipart/form-data';
        case ContentTypeShort.none:
        default:
            return null;
    }
}
exports.contentTypeHTTP = contentTypeHTTP;
