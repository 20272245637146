import { storeFactory } from '../factory/storeFactory';
import { ListActionType } from '../common/list/ListAction';
import { getListReducer } from '../common/list';
import { StoreProxy } from '../factory/StoreProxy';
import { feedbackSummarySaga } from '../../saga/feedbackSummary';
import { UserGroup } from '@deepa-web/shared/src/type/CognitoUser';
import { UserGroupFunctorAdmin } from '../user/userGroup/UserGroupFunctorAdmin';
const feedbackSummaryStore = storeFactory(getListReducer, feedbackSummarySaga), feedbackSummaryStoreProxy = new StoreProxy(feedbackSummaryStore);
function requestFeedbackSummaryFromServer() {
    const callbackMap = new Map(), adminCallback = () => feedbackSummaryStore.dispatch({ type: ListActionType.requestData });
    callbackMap.set(UserGroup.admin, adminCallback);
    const functor = new UserGroupFunctorAdmin(callbackMap), callback = functor.result();
    if (callback) {
        callback();
    }
}
export { feedbackSummaryStoreProxy, requestFeedbackSummaryFromServer };
