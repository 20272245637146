import {DoctorDataState} from '@deepa-web/browser-shared/src/redux/doctorData/DoctorDataState'
import {DoctorPracticeState} from '@deepa-web/browser-shared/src/redux/doctorPractice/DoctorPracticeState'
import {DisabledButton} from '../../../../../base/button/DisabledButton'
import {GETdoctorSignatureState} from '@deepa-web/browser-shared/src/redux/doctorSignature/get/GETdoctorSignatureState'
import {
  Params,
  useParams
} from 'react-router-dom'
import {HPRIMId} from '@deepa-web/shared/src/type/hprim/HPRIMId'
import {isDataValid} from './isDataValid'
import {EnabledButtonToPdf} from './EnabledButtonToPdf'
import {doctorPracticeProviderWrapper} from '../../../../../../redux/store/doctorPracticeProviderWrapper'
import {GETsignatureProviderWrapper} from '../../../../../../redux/store/GETsignatureProviderWrapper'
import {doctorDataProviderWrapper} from '../../../../../../redux/store/doctorDataProviderWrapper'


function ButtonToPdf() {
  const doctorData: DoctorDataState = doctorDataProviderWrapper.useSelector((state: DoctorDataState) => state),
    doctorPracticeState: DoctorPracticeState = doctorPracticeProviderWrapper.useSelector((state: DoctorPracticeState) => state),
    signature : GETdoctorSignatureState = GETsignatureProviderWrapper.useSelector(state => state) as GETdoctorSignatureState,
    isValid = isDataValid(doctorData, doctorPracticeState, signature),
    params: Readonly<Params> = useParams(),
    {hprimId} = params,
    hprimId1: HPRIMId = {hprimId: hprimId!}

  return isValid ?
    <EnabledButtonToPdf {...hprimId1}/> :
    <DisabledButton> Télécharger </DisabledButton>
}

export {ButtonToPdf}
