import { initUploadState } from './UploadState';
import { UploadActionType } from './UploadAction';
function fileUploadReducer(prevState = initUploadState(false), action) {
    switch (action.type) {
        case UploadActionType.storeFile:
            return initUploadState(true);
        case UploadActionType.cancelRequest:
            return initUploadState(false);
        default:
            return prevState;
    }
}
export { fileUploadReducer };
