export var DPRactionType;
(function (DPRactionType) {
    DPRactionType["requestData"] = "requestData";
    DPRactionType["storeData"] = "storeData";
    DPRactionType["storeField"] = "storeField";
    DPRactionType["removeField"] = "removeField";
    DPRactionType["postDataToAPI"] = "postDataToAPI";
    DPRactionType["clearData"] = "clearData";
})(DPRactionType || (DPRactionType = {}));
export var DPRpropertyName;
(function (DPRpropertyName) {
    DPRpropertyName["FINESS"] = "FINESS";
    DPRpropertyName["service"] = "service";
    DPRpropertyName["address"] = "address";
    DPRpropertyName["location"] = "location";
    DPRpropertyName["phones"] = "phones";
})(DPRpropertyName || (DPRpropertyName = {}));
