import {DoctorDataFormTextField} from './DoctorDataFormTextField'
import React from 'react'
import {
  Box,
  Button
} from '@mui/material'
import {doctorDataStoreProxy} from '@deepa-web/browser-shared/src/redux/doctorData'
import {
  DoctorDataActionType,
  DoctorDataPropertyName
} from '@deepa-web/browser-shared/src/redux/doctorData/DoctorDataAction'
import {RppsDisplay} from "./RppsDisplay";
import {doctorDataProviderWrapper} from '../../../../redux/store/doctorDataProviderWrapper'

function DoctorDataForm() {
  return doctorDataProviderWrapper.ProviderWrapper(<DoctorDataFormHooked/>)
}

function DoctorDataFormHooked() {
  return (
    <Box id='box' component='form'>
      <RppsDisplay />
      <DoctorDataFormTextField id={'nomInput'} label={'Nom'}
                               fieldName={DoctorDataPropertyName.lastName}/>
      <DoctorDataFormTextField id={'prenomInput'} label={'Prénom'}
                               fieldName={DoctorDataPropertyName.firstName}/>
      <DoctorDataFormTextField id={'emailInput'} label={'E-mail'}
                               fieldName={DoctorDataPropertyName.email}/>
      <DoctorDataFormTextField id={'phoneInput'} label={'Téléphone'}
                               fieldName={DoctorDataPropertyName.phone}/>
      <Button
        id='submitButton'
        type='button'
        variant='contained'
        onClick={submit}
      >
        Enregistrer
      </Button>
    </Box>
  )
}

function submit() {
  doctorDataStoreProxy.dispatch({type: DoctorDataActionType.postDataToAPI})
}

export {DoctorDataForm}
