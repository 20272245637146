"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RequestFactory = void 0;
class RequestFactory {
    constructor(routeParameter, contentType, responseType, moduleArg) {
        this._routeParameter = routeParameter;
        this._contentType = contentType;
        this._responseType = responseType;
        this._configBuilderFactory = moduleArg.configBuilderFactory();
        this._contentErrorFactory = moduleArg.contentErrorFactory();
        this._hostPort = moduleArg.hostPort;
    }
    Result() {
        const configBuilderInstance = this._configBuilderFactory.makeConfigBuilder(), contentType1 = this._contentType, responseType1 = this._responseType, axiosConfig = configBuilderInstance.makeAxiosConfig(contentType1, responseType1);
        return this.makeBuilder(axiosConfig);
    }
}
exports.RequestFactory = RequestFactory;
