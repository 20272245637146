import {
  ListElementFactory,
  ListElementFactoryProps
} from '../../../../../base/functor/ListElementFactory'
import {BrowserNotification} from '@deepa-web/shared/src/type/BrowserNotification'


class ListLengthDisplay extends ListElementFactory<BrowserNotification, ListElementFactoryProps<BrowserNotification>> {
  protected get match(): JSX.Element {
    return <span className='notificationNumberSpan'>{this.props.list.length}</span>
  }
}

export {ListLengthDisplay}
