import React from 'react'
import {UserGroup} from '@deepa-web/shared/src/type/CognitoUser'
import {AuthArg} from '@deepa-web/browser-shared/src/type/AuthArg'
import {DoctorLaboCommon} from '../common/DoctorLaboCommon'
import AuthorisationProxy from '../../../base/authorise/AuthorisationProxy'
import {ComponentUIDLabel} from '../../../base/ComponentUIDLabel'

function Doctor() {
  function ComponentFactory(authArg: AuthArg) {
    return <ComponentUIDLabel uid='CID-Doctor'>
      <DoctorLaboCommon {...authArg}
                        expectUserGroup={UserGroup.doctors}/>
    </ComponentUIDLabel>
  }

  return <AuthorisationProxy allowedGroups={[UserGroup.doctors]}
                             childFactory={ComponentFactory}
  />
}

export {Doctor}
