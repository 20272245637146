"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseDateTime = void 0;
const ExplicitDate_1 = require("./ExplicitDate");
const ExplicitTime_1 = require("./ExplicitTime");
function parseDateTime(date) {
    return {
        ...(0, ExplicitDate_1.parseDate)(date),
        ...(0, ExplicitTime_1.parseTime)(date)
    };
}
exports.parseDateTime = parseDateTime;
