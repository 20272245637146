"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userEmail = exports.mapToRelevant = exports.jwt = exports.isUserLabo = exports.isUserDoctor = exports.isUserAdmin = exports.isAdmin = exports.doctorLaboGroup = exports.UserGroup = void 0;
var UserGroup;
(function (UserGroup) {
    UserGroup["doctors"] = "doctors";
    UserGroup["labos"] = "labos";
    UserGroup["admin"] = "admin";
})(UserGroup = exports.UserGroup || (exports.UserGroup = {}));
function mapToRelevant(fromAws) {
    return {
        identifiantMedecin: identifiantMedecin(fromAws),
        email: userEmail(fromAws),
        group: groups(fromAws),
        jwtToken: jwt(fromAws)
    };
}
exports.mapToRelevant = mapToRelevant;
function userEmail(user) {
    return user.signInUserSession.idToken.payload.email;
}
exports.userEmail = userEmail;
function jwt(user) {
    return user === null || user === void 0 ? void 0 : user.signInUserSession.idToken.jwtToken;
}
exports.jwt = jwt;
function groups(user) {
    const payload1 = payload(user);
    return payload1['cognito:groups'];
}
function doctorLaboGroup(groups) {
    if (groups.indexOf(UserGroup.doctors) > -1) {
        return UserGroup.doctors;
    }
    if (groups.indexOf(UserGroup.labos) > -1) {
        return UserGroup.labos;
    }
    return undefined;
}
exports.doctorLaboGroup = doctorLaboGroup;
function isAdmin(groups) {
    return groups.indexOf(UserGroup.admin) > -1;
}
exports.isAdmin = isAdmin;
function isUserAdmin(user) {
    return isAdmin(user.group);
}
exports.isUserAdmin = isUserAdmin;
function isUserDoctor(user) {
    return doctorLaboGroup(user.group) === UserGroup.doctors;
}
exports.isUserDoctor = isUserDoctor;
function isUserLabo(user) {
    return doctorLaboGroup(user.group) === UserGroup.labos;
}
exports.isUserLabo = isUserLabo;
function identifiantMedecin(user) {
    const payload1 = payload(user);
    return payload1['custom:identifiantMedecin'];
}
function payload(user) {
    return user.signInUserSession.idToken.payload;
}
