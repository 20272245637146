import {TableCell} from '@mui/material'
import {PropsWithChildren} from 'react'

const bodyStyleNormal = {
    fontFamily: 'mulish',
    backgroundColor : 'white',
    fontSize : '0.9em',
    color: '#091954'
  },
  bodyStyleAbnormal = {
    ...bodyStyleNormal,
    fontWeight: 'bold',
    color: '#091954'
  }

export interface CellBodyProps extends PropsWithChildren {
  isNormal?: boolean
  classNameTableCell?: string
}

function CellBody(props: CellBodyProps) {
  const style = props.isNormal === false ? bodyStyleAbnormal : bodyStyleNormal,
      {classNameTableCell} = props
  return <TableCell align='center'
                    variant={'body'}
                    sx={style}
                    className={classNameTableCell}
  >
    {props.children}
  </TableCell>
}

export {CellBody}
