import { AxiosRequestConfigBuilderFacade } from '@deepa-web/axios/src/builder/AxiosRequestConfigBuilderFacade';
import { userStoreProxy } from '@deepa-web/browser-shared/src/redux/user';
class AxiosRequestConfigBuilderBrowser extends AxiosRequestConfigBuilderFacade {
    get token() {
        var _a;
        const userState = userStoreProxy.getState, token = (_a = userState.user) === null || _a === void 0 ? void 0 : _a.jwtToken;
        return token || null;
    }
}
class ConfigBuilderFacadeFactoryBrowser {
    makeConfigBuilder() {
        return new AxiosRequestConfigBuilderBrowser();
    }
}
export { AxiosRequestConfigBuilderBrowser, ConfigBuilderFacadeFactoryBrowser };
