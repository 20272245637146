import {
  TabOrientationCSS,
  TabPanel
} from '../../../base/tab/tabFactory/TabPanel'
import {DoctorDataForm} from './DoctorDataForm'
import {ComponentUIDLabel} from '../../../base/ComponentUIDLabel'

function Tab1(selectedTab: number): JSX.Element {
  return <TabPanel value={selectedTab} index={0} key={0} orientation={TabOrientationCSS.horizontal}>
    <ComponentUIDLabel uid='CID-MCTab1'>
      <div className='personnalForm'>
        <DoctorDataForm/>
      </div>
    </ComponentUIDLabel>
  </TabPanel>
}

export {Tab1}
