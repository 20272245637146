import {Button} from '@mui/material'
import '../../../style/baseComponents/noDataButton.scss'
import {PropsWithChildren} from 'react'

function DisabledButton(props: PropsWithChildren) {
  return <Button className='noDataButton' disabled>
    {props.children}
  </Button>
}

export {DisabledButton}
