import { initDictionaryState } from './DictionaryState';
import { DictionaryActionType } from './DictionaryAction';
class DictionaryReducer {
    dictionaryReducer(prevState = initDictionaryState(), action) {
        const { items } = this.cloneState(prevState);
        switch (action.type) {
            case DictionaryActionType.storeData:
                const keyString = this.keyToString(action.key);
                items[keyString] = action.item;
                return { items, apiRequestIsInProgress: prevState.apiRequestIsInProgress };
            case DictionaryActionType.deleteData:
                const keyString1 = this.keyToString(action.key);
                delete items[keyString1];
                return { items, apiRequestIsInProgress: prevState.apiRequestIsInProgress };
            case DictionaryActionType.clearData:
                return initDictionaryState();
            case DictionaryActionType.requestData:
            case DictionaryActionType.postDataToAPI:
                return Object.assign(Object.assign({}, prevState), { apiRequestIsInProgress: true });
            case DictionaryActionType.cancelRequest:
                return Object.assign(Object.assign({}, prevState), { apiRequestIsInProgress: false });
            default:
                return prevState;
        }
    }
    cloneState(prevState) {
        const { items, apiRequestIsInProgress } = prevState, itemsCloned = Object.assign({}, items);
        return { items: itemsCloned, apiRequestIsInProgress };
    }
}
export { DictionaryReducer };
