import { ServerAppRoutes } from '@deepa-web/shared/src/routes/ServerAppRoutes';
function wsManagerOptions() {
    return {
        path: ServerAppRoutes.wsNotify,
        reconnection: true,
        autoConnect: true,
        reconnectionAttempts: 3,
        reconnectionDelay: 10000,
        reconnectionDelayMax: 20000,
        timeout: 5000
    };
}
export { wsManagerOptions };
