import React from 'react'
import {
  Params,
  useParams
} from 'react-router-dom'
import {HPRIMId} from '@deepa-web/shared/src/type/hprim/HPRIMId'
import {requestItemData} from '@deepa-web/browser-shared/src/saga/requestItemData'
import {HprimDetailPagePure} from './HprimDetailPagePure'
import AuthorisationProxy
  from '../../../../../base/authorise/AuthorisationProxy'
import {UserGroup} from '@deepa-web/shared/src/type/CognitoUser'
import {ComponentUIDLabel} from '../../../../../base/ComponentUIDLabel'
import {bilanSanguin1ProviderWrapper} from '../../../../../../redux/store/bilanSanguin1ProviderWrapper'
import {ruleEngine1ProviderWrapper} from '../../../../../../redux/store/ruleEngine1ProviderWrapper'
import {hprimSummaryListProviderWrapper} from '../../../../../../redux/store/hprimSummaryListProviderWrapper'
import {ordonnance1ProviderWrapper} from '../../../../../../redux/store/ordonnance1ProviderWrapper'
import {feedbackProviderWrapper} from '../../../../../../redux/store/feedbackProviderWrapper'
import {ordoTitleProviderWrapper} from '../../../../../../redux/store/ordoTitleProviderWrapper'
import '../../../../../../style/hprimDetailPage/bilanSanguin.scss'


function HprimDetailPage() {
  const params: Readonly<Params> = useParams(),
    {hprimId} = params

  const hprimId1: HPRIMId = {hprimId: hprimId!}

  function onLogin() {
    if (hprimId1) {
      requestItemData(hprimId1)
    }
  }

  return <AuthorisationProxy
    allowedGroups={[UserGroup.doctors, UserGroup.labos]}
    onLogin={onLogin}
  >
    <ComponentUIDLabel uid='CID-HprimDetailPage'>
      <HprimDetailPageAuthorised {...hprimId1} />
    </ComponentUIDLabel>
  </AuthorisationProxy>
}

function HprimDetailPageAuthorised(hprimId: HPRIMId) {
  return bilanSanguin1ProviderWrapper.ProviderWrapper(
        ruleEngine1ProviderWrapper.ProviderWrapper(
          hprimSummaryListProviderWrapper.ProviderWrapper(
            ordonnance1ProviderWrapper.ProviderWrapper(
              ordoTitleProviderWrapper.ProviderWrapper(
                feedbackProviderWrapper.ProviderWrapper(
                  <HprimDetailPagePure {...hprimId}/>))))))
}

export {HprimDetailPage}
