import {BilanSanguin} from '@deepa-web/shared/src/type/awsLambda/ruleEngine/response/BilanSanguin'
import {TabFactory} from '../../../../../../../base/tab/tabFactory'
import {BilanSanguin1} from './table/BilanSanguin1'

class BilanSanguinTabFactory
  extends TabFactory<BilanSanguin> {

  protected sortTabLabels(): void {
    super.sortTabLabels()
    this._tabLabels.sort(sortTabsComparator)
  }

  protected displayValue(tabLabel: string,
                         value: BilanSanguin)
    : JSX.Element {
    return <BilanSanguin1 bilanSanguin={value}/>
      }
  }

function sortTabsComparator(a: string, b: string): number {
  if (a === 'Toutes') {
    return -1
  }
  if (b === 'Toutes') {
    return 1
  }
  return 0
}

export {BilanSanguinTabFactory}
