import {HPRIMId} from '@deepa-web/shared/src/type/hprim/HPRIMId'
import {DictionaryActionType, DictionaryKeyAction} from '@deepa-web/browser-shared/src/redux/common/dictionary/DictionaryAction'
import {bilanSanguin1StoreProxy} from '@deepa-web/browser-shared/src/redux/bilanSanguin'

function deleteBilanSanguin(hprimId: string) {
  const action: DictionaryKeyAction<HPRIMId> = {
    type: DictionaryActionType.deleteData,
    key: {hprimId}
  }

  bilanSanguin1StoreProxy.dispatch(action)
}

export {deleteBilanSanguin}
