import { initListState } from './ListState';
import { ListActionType } from './ListAction';
function getListReducer(prevState = initListState(), action) {
    switch (action.type) {
        case ListActionType.storeData:
            return { items: action.items, apiRequestIsInProgress: false };
        case ListActionType.deleteData:
            const selector = action.selector, clonedItems = [...prevState.items], predicate = (value, _idx, _a) => !selector(value);
            return { items: clonedItems.filter(predicate), apiRequestIsInProgress: false };
        case ListActionType.clearData:
            return initListState();
        case ListActionType.requestData:
            return Object.assign(Object.assign({}, prevState), { apiRequestIsInProgress: true });
        case ListActionType.cancelRequest:
            return Object.assign(Object.assign({}, prevState), { apiRequestIsInProgress: false });
        default:
            return prevState;
    }
}
export { getListReducer };
