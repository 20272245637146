import '../../../style/baseComponents/pageHeader.scss'
export interface PageHeaderSimpleProps {
    pageTitle: string | JSX.Element
}

function PageHeaderSimple(props : PageHeaderSimpleProps) {
    return <div className='pageHeaderDiv'>
        <div>
            <div> {props.pageTitle} </div>
        </div>
    </div>
}

export {PageHeaderSimple}
