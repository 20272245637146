import { call, put, takeEvery } from 'redux-saga/effects';
import { postFormSignature } from './postFormSignature';
import { uploadSignatureServiceInstance } from './UploadFileServiceSignature';
import { SignatureUploadActionType } from '../../../redux/doctorSignature/upload/SignatureUploadAction';
import { requestSignature } from '../../../redux/doctorSignature/get';
function* signatureUploadSaga() {
    yield takeEvery(SignatureUploadActionType.storeFile, peekAction);
}
function* peekAction(_action) {
    const iterator = uploadSignatureServiceInstance.shiftItem();
    let iterResult = iterator.next(), file;
    while (!iterResult.done) {
        file = iterResult.value;
        yield call(postFormSignature, file);
        iterResult = iterator.next();
    }
    yield put({ type: SignatureUploadActionType.cancelRequest });
    requestSignature();
}
export { signatureUploadSaga };
