import { storeFactory } from '../factory/storeFactory';
import { DictionaryReducer } from '../common/dictionary';
import { StoreProxy } from '../factory/StoreProxy';
import { ordonnance1Saga } from '../../saga/ordonnance';
class OrdonnanceRuleEngineReducer extends DictionaryReducer {
    keyToString({ hprimId }) {
        return hprimId;
    }
}
const reducerInstance = new OrdonnanceRuleEngineReducer(), store = storeFactory(reducerInstance.dictionaryReducer.bind(reducerInstance), ordonnance1Saga), ordonnance1StoreProxy = new StoreProxy(store);
export { ordonnance1StoreProxy };
