import { formatDateIso } from '@deepa-web/shared/src/type/date/ExplicitDate';
import dayjs from 'dayjs';
function toDayjs(explicitDate) {
    const iso = formatDateIso(explicitDate);
    return dayjs(iso);
}
function toDayjsNull(explicitDate) {
    return explicitDate === null ? null : toDayjs(explicitDate);
}
function toExplicitDate(dayjs1) {
    return {
        an: dayjs1.year(),
        jour: dayjs1.date(),
        mois: dayjs1.month() + 1
    };
}
function toExplicitDateNull(date) {
    return date === null ? null : toExplicitDate(date);
}
export { toDayjs, toDayjsNull, toExplicitDate, toExplicitDateNull };
