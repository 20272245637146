import {TableCell, TableCellProps} from '@mui/material'

const headerStyle = {
  backgroundColor: '#EEEEEE',
  color: ' #091954',
  fontFamily: 'mulish',
  width: '16em',
  fontWeight: 'bold'
}

export interface StyledTableCellProps extends TableCellProps {
  width?: string
}

function CellHeader(props: StyledTableCellProps) {
  const sx = {...headerStyle, width: props.width || headerStyle.width}
  return <TableCell align='center' sx={sx} >
    {props.children}
  </TableCell>
}

export {CellHeader}
