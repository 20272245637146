import {UploadSignatureButton} from './UploadSignatureButton'
import {Signature} from '../../../base/signature'
import {requestSignature} from '@deepa-web/browser-shared/src/redux/doctorSignature/get'
import {
  useEffect,
  useState
} from 'react'
import {ImageFromService} from '../../../base/signature/ImageFromService'
import {DeleteSignatureButton} from '../../../base/signature/DeleteSignatureButton'

function UploadImagePreview() {
  const [isRequested, setIsRequested] = useState(false)

  useEffect(() => {
    if (!isRequested) {
      requestSignature()
      setIsRequested(true)
    }
  }, [isRequested])

  return (
    <div className='uploadPreviewDiv'>
      <div className='imagePreviewDiv'>
        <Signature>
          <div className='signatureDiv'>
            <ImageFromService/>
            <DeleteSignatureButton/>
          </div>
        </Signature>
      </div>
      <div className='inputDiv'>
        <UploadSignatureButton/>
      </div>
    </div>
  )
}

export {UploadImagePreview}
