"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RequestBuilderBody = void 0;
const RequestBuilder_1 = require("./RequestBuilder");
class RequestBuilderBody extends RequestBuilder_1.RequestBuilder {
    constructor(routeParameter, hostPort, axiosConfig, body, contentErrorFunctorFactory) {
        super(routeParameter, hostPort, axiosConfig, contentErrorFunctorFactory);
        this._body = body;
    }
    async callApi() {
        const url = this.buildUrl();
        try {
            const response = await this.makeCall(url, this._body, this._axiosConfig);
            return this.axiosResponseToT(response);
        }
        catch (e) {
            return this.caughtError(e);
        }
    }
}
exports.RequestBuilderBody = RequestBuilderBody;
