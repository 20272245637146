import {
  ListElementFactory,
  ListElementFactoryProps
} from './ListElementFactory'
import {PropsWithChildren} from 'react'

export interface ListElementFactoryWithChildrenProps<ListElementT>
  extends ListElementFactoryProps<ListElementT>,
          PropsWithChildren {}


class ListElementFactoryWithChildren<ListElementT>
  extends ListElementFactory<ListElementT,
    ListElementFactoryWithChildrenProps<ListElementT>> {

  protected get match(): JSX.Element | null {
    return <>
      {this.props.children}
    </>
  }
}

export {ListElementFactoryWithChildren}
