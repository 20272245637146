import {Exploration1} from '../AnomalyExamProps'
import {AnomalyDocumentation} from '../../AnomalyDocumentation'
import {explorationToShow} from './explorationToShow'
import {FaMinus} from 'react-icons/fa'
import {CopyClipboardParam, CopyToClipboard} from "../../../../../../../base/button/clipboard/CopyToClipboard"
import {FiClipboard} from "react-icons/fi"
import {FiCheck} from "react-icons/fi"

function SingleOrdoTitleDetail(explorations: Exploration1[]): JSX.Element {
    const firstExploration = explorations[0],
        showAnomalyDocumentation = firstExploration.reco?.trim().length > 0,
        showExams = firstExploration.exams.length > 0,

        exams = explorations.map(i => i.exams.map(e => e.label)).flat()


    return <div className='documentationExamDiv'>
        {showExams ?
            <div>
                <div className='examTitleDiv'>
                  <span className='examDocTitleSpan'>
                    <FaMinus color={'#091954'} size={'0.7em'}/>
                    Examens de biologie médicale (première intention)
                </span>
                <CopyToClipboard
                    useDefaults={false}
                    tooltipTitle={'Copier les examens de biologie médicale'}
                    icon={clipboardIcon()}
                    className='clipboardButtonExams'
                >
                    {exams}
                </CopyToClipboard>
                </div>

                <ul>
                    {explorations.map((exploration, i) => (
                        <li key={i}>{explorationToShow(exploration)}</li>
                    ))}
                </ul>
            </div>
            : <span/>
        }
        {showAnomalyDocumentation ? (
            <AnomalyDocumentation reco={firstExploration.reco}/>
        ) : <span/>
        }
    </div>
}

function clipboardIcon(): CopyClipboardParam<JSX.Element> {
    return {
        param: <FiClipboard color={'#091954'} size={'1em'}/>,
        copied: <FiCheck color={'#091954'} size={'1em'}/>
    }
}

export {SingleOrdoTitleDetail}

