import { ActionStatus } from '@deepa-web/shared/src/type/ContentError';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { GETdoctorSignatureActionType } from '../../../redux/doctorSignature/get/GETdoctorSignatureAction';
import { callGETendpoint } from './callGETendpoint';
import { GETdoctorSignatureService } from './GETdoctorSignatureService';
import { callDELETEendpoint } from './callDELETEendpoint';
let isInProgress = false;
function* GETdoctorSignatureSaga() {
    yield takeEvery([
        GETdoctorSignatureActionType.makeRequest,
        GETdoctorSignatureActionType.clearFileFromStore,
        GETdoctorSignatureActionType.deleteFileApi
    ], peekAction);
}
function* peekAction(action) {
    switch (action.type) {
        case GETdoctorSignatureActionType.makeRequest:
            yield* requestAction();
            break;
        case GETdoctorSignatureActionType.clearFileFromStore:
            yield* clearAction();
            break;
        case GETdoctorSignatureActionType.deleteFileApi:
            yield* deleteSignature();
    }
}
function* clearAction() {
    GETdoctorSignatureService.signatureFile = null;
}
function* requestAction() {
    const currentState = yield select();
    if (isInProgress || currentState.fileIsStoredInService) {
        yield put({ type: GETdoctorSignatureActionType.cancelRequest });
        return;
    }
    isInProgress = true;
    const { status } = yield call(callGETendpoint);
    isInProgress = false;
    yield put({ type: GETdoctorSignatureActionType.cancelRequest });
    switch (status) {
        case ActionStatus.success:
            yield put({ type: GETdoctorSignatureActionType.fileIsReceived });
            break;
        case ActionStatus.fail:
            break;
    }
}
function* deleteSignature() {
    yield call(callDELETEendpoint);
    yield* clearAction();
}
export { GETdoctorSignatureSaga };
