class StoreProxy {
    constructor(store) {
        this._store = store;
    }
    get store() {
        return this._store;
    }
    dispatch(action) {
        this._store.dispatch(action);
    }
    subscribe(listener) {
        return this._store.subscribe(listener);
    }
    get getState() {
        return this._store.getState();
    }
}
export { StoreProxy };
