import { DictionaryReducer } from '../../common/dictionary';
import { StoreProxy } from '../../factory/StoreProxy';
import { storeFactory } from "../../factory/storeFactory";
import { ordonnanceFormatSaga } from "../../../saga/ordonnanceFormat";
class OrdonnanceFormatReducer extends DictionaryReducer {
    keyToString({ hprimId }) {
        return hprimId;
    }
}
const reducerInstance = new OrdonnanceFormatReducer(), store = storeFactory(reducerInstance.dictionaryReducer.bind(reducerInstance), ordonnanceFormatSaga), ordonnanceFormatStoreProxy = new StoreProxy(store);
export { ordonnanceFormatStoreProxy };
