export interface AllyPropsObject {
  id: string
  'aria-controls': string
}

export type AllyPropsFunction = (index: number) => AllyPropsObject

function allyPropsHorizontal(index: number): AllyPropsObject {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

function allyPropsVertical(index: number): AllyPropsObject {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  }
}

export {
  allyPropsHorizontal,
  allyPropsVertical
}
