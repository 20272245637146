import Grid from '@mui/material/Grid'
import '../../../style/baseComponents/applicationTemplate.scss'
import {PropsWithChildren} from 'react'
import {TopMenuJsx} from "../menu/topMenu";
export interface TemplateProps
  extends PropsWithChildren {}

function ApplicationTemplate(props: TemplateProps): JSX.Element {

  return <Grid className='applicationGridContainer' container columns={12}>
      <Grid className='menuGridContainer' item xs={2}>
          <TopMenuJsx/>
      </Grid>
      <Grid
          className='itemGridContainer'
          item xs={10}
          justifyContent="center"
          sx={{display : 'flex', flexDirection: 'column'}}
      >
        {props.children}
      </Grid>
  </Grid>
}

export {ApplicationTemplate}
