import {
  TableFooter,
  TableRow
} from '@mui/material'
import React from 'react'
import {Pagination} from './Pagination'


function Footer(): JSX.Element {
  return <TableFooter>
    <TableRow>
      <Pagination/>
    </TableRow>
  </TableFooter>
}

export {Footer}
