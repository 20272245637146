import React, {
  useEffect,
  useState
} from 'react'
import {TextField} from '@mui/material'
import {
  DPRaction,
  DPRactionType,
  DPRpropertyName
} from '@deepa-web/browser-shared/src/redux/doctorPractice/DPRaction'
import {doctorPracticeStoreProxy} from '@deepa-web/browser-shared/src/redux/doctorPractice'
import {DoctorPracticeState} from '@deepa-web/browser-shared/src/redux/doctorPractice/DoctorPracticeState'
import {doctorPracticeProviderWrapper} from '../../../../redux/store/doctorPracticeProviderWrapper'


export interface DPRtextFieldProps {
  id: string
  label: string
  fieldName: Exclude<DPRpropertyName, DPRpropertyName.phones>
}

function DPRtextField(props: DPRtextFieldProps): JSX.Element {
  const initValue = doctorPracticeProviderWrapper.useSelector(
      (state: DoctorPracticeState) => state[props.fieldName]),

    [value, setValue] = useState(initValue || '')

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newValue = e.target.value
    if (newValue !== value) {
      setValue(newValue)
    }
  }

  useEffect(() => {
    const action: DPRaction = {
      type: DPRactionType.storeField,
      data: {
        tag: props.fieldName,
        fieldValue: value
      }
    }
    doctorPracticeStoreProxy.dispatch(action)
  }, [value, props.fieldName])

  return (
    <TextField
      id={props.id}
      name={props.id}
      label={props.label}
      value={value}
      onChange={onChange}
      margin='normal'
      required
      fullWidth
    />
  )
}

export {DPRtextField}
