import { storeFactory } from '../factory/storeFactory';
import { DictionaryReducer } from '../common/dictionary';
import { StoreProxy } from '../factory/StoreProxy';
import { ruleEngine1Saga } from '../../saga/ruleEngine';
class RuleEngineReducer extends DictionaryReducer {
    keyToString({ hprimId }) {
        return hprimId;
    }
}
const reducerInstance = new RuleEngineReducer(), store = storeFactory(reducerInstance.dictionaryReducer.bind(reducerInstance), ruleEngine1Saga), ruleEngine1StoreProxy = new StoreProxy(store);
export { ruleEngine1StoreProxy };
