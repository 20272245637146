class UserGroupFunctor {
    constructor(options, userGroupFactory) {
        this._options = options;
        this._userGroupFactory = userGroupFactory;
    }
    result() {
        const userGroup1 = this._userGroupFactory();
        return this._options.get(userGroup1 || null);
    }
}
export { UserGroupFunctor };
