import {DeepTabsResponsiveProps} from './DeepTabsResponsiveProps'
import {DeepTabsProps} from './DeepTabsProps'
import {DeepTabs} from './DeepTabs'

function DeepTabsResponsive(props: DeepTabsResponsiveProps) {
  const factory = props.responsiveFactory,
    deepTabsProps: DeepTabsProps = {
    allyProps: factory.allyProps,
    childFactory: props.childFactory,
    orientation: factory.orientation,
    tabLabels: props.tabLabels,
    tabStyle: factory.tabStyle
  }

  return <DeepTabs {...deepTabsProps} />
}

export {DeepTabsResponsive}
