import { storeFactory } from '../factory/storeFactory';
import { DictionaryReducer } from '../common/dictionary';
import { StoreProxy } from '../factory/StoreProxy';
import { feedback1Saga } from '../../saga/feedback';
class FeedbackReducer extends DictionaryReducer {
    keyToString({ hprimId }) {
        return hprimId;
    }
}
const reducerInstance = new FeedbackReducer(), store = storeFactory(reducerInstance.dictionaryReducer.bind(reducerInstance), feedback1Saga), feedbackStoreProxy = new StoreProxy(store);
export { feedbackStoreProxy };
