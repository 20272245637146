import React from 'react'
import '../../../../../../../../style/doctorLaboCommon.scss'
import {HprimSummaryType} from '@deepa-web/browser-shared/src/redux/hprimSummaryList/HprimSummaryType'
import {formatDate} from '@deepa-web/shared/src/type/date/ExplicitDate'
import {ComponentUIDLabel} from '../../../../../../../base/ComponentUIDLabel'
import {CellBody} from '../../../../../../../base/tableCells/CellBody'
import {DetailRowFactory} from './detailRow'
import {useNavigate} from 'react-router-dom'
import {FaCircle} from 'react-icons/fa'
import {urgentColours} from '../../../../../../../base/urgent/urgentColours'


function SingleHPRIMRow(notification: HprimSummaryType) {
  const {
      hprimId,
      patientNom,
      patientPrenom,
      dateExamen,
      isRead,
      urgent_int,
      dateNaissance
    } = notification,
    displayDateExamen = formatDate(dateExamen),
    displayDateNaissance = formatDate(dateNaissance),
    navigate = useNavigate(),
    cellClass = isRead ?
      'bilanCellRead' :
      'bilanCellUnread'

  return <ComponentUIDLabel uid='CID-SingleHPRIMRow' key={hprimId}>
    <DetailRowFactory navigate={navigate} notification={notification}>
      <CellBody classNameTableCell={cellClass}>
        {notification.hprimId}
      </CellBody>
      <CellBody classNameTableCell={cellClass}>
        {patientNom}
      </CellBody>
      <CellBody classNameTableCell={cellClass}>
        {patientPrenom}
      </CellBody>
      <CellBody classNameTableCell={cellClass}>
        {displayDateNaissance}
      </CellBody>
      <CellBody classNameTableCell={cellClass}>
        {displayDateExamen}
      </CellBody>
      {showUrgentSummary(urgent_int, isRead)}
    </DetailRowFactory>
  </ComponentUIDLabel>
}

function showUrgentSummary(urgent_int: number, isRead: boolean) {

  const cellClass = isRead ?
    'bilanCellRead' :
    'bilanCellUnread'

  const color: string | undefined = urgentColours[urgent_int]
  return color ?
    <CellBody classNameTableCell={cellClass}>
      <FaCircle color={color}/>
    </CellBody> : null
}

export {SingleHPRIMRow}
