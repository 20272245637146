import {
  Box,
  Stack
} from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import {UserLabeledInput} from './UserLabeledInput'
import {doctorPracticeStoreProxy} from '@deepa-web/browser-shared/src/redux/doctorPractice'
import {
  DoctorPracticeState,
  newPhoneEntry
} from '@deepa-web/browser-shared/src/redux/doctorPractice/DoctorPracticeState'
import {
  DPRactionField,
  DPRactionFieldPhoneLabel,
  DPRactionType,
  DPRpropertyName
} from '@deepa-web/browser-shared/src/redux/doctorPractice/DPRaction'
import {doctorPracticeProviderWrapper} from '../../../redux/store/doctorPracticeProviderWrapper'


function UserLabeledInputs() {
  return doctorPracticeProviderWrapper.ProviderWrapper(<UserLabeledInputsHooked/>)
}

function UserLabeledInputsHooked() {
  const storedList = doctorPracticeProviderWrapper.useSelector((state: DoctorPracticeState) => state.phones)

  return (
    <div className='customInputDiv'>
      <Box id='customBox'>
        <div className='buttonsCustom'>
          <Stack>
            {storedList.map(v => <UserLabeledInput {...v} key={v.uid}/>)}
          </Stack>
        </div>
        <AddCircleOutlineIcon onClick={addInput}/>
      </Box>
    </div>
  )
}


function addInput() {
  const data: DPRactionFieldPhoneLabel = {
      tag: DPRpropertyName.phones,
      fieldValue: newPhoneEntry()
    },
    action: DPRactionField = {
      type: DPRactionType.storeField,
      data
    }

  doctorPracticeStoreProxy.dispatch(action)
}

export {UserLabeledInputs}
