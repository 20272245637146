"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServerAppRoutes = void 0;
var ServerAppRoutes;
(function (ServerAppRoutes) {
    ServerAppRoutes["api"] = "/api";
    ServerAppRoutes["anomalies1"] = "/api/anomalies/1/:hprimId";
    ServerAppRoutes["bilanSanguin1"] = "/api/bilan-sanguin/1/:hprimId";
    ServerAppRoutes["feedback1"] = "/api/reaction/1/:hprimId";
    ServerAppRoutes["feedbackDownload"] = "/api/reaction/telecharger";
    ServerAppRoutes["feedbackRecent"] = "/api/reaction/recente/:nr";
    ServerAppRoutes["hprim1"] = "/api/hprim/1/:hprimId";
    ServerAppRoutes["hprimPieceJointeList"] = "/api/hprim/pieceJointe/liste/:hprimId";
    ServerAppRoutes["hprimPieceJointeDownload"] = "/api/hprim/pieceJointe/telecharger/:hprimId/:uid";
    ServerAppRoutes["hprim1lu"] = "/api/hprim/lu/:hprimId";
    ServerAppRoutes["hprimSummary"] = "/api/hprim/summary";
    ServerAppRoutes["hprimPatient"] = "/api/hprim/patient/:uid";
    ServerAppRoutes["hprimUploadSauvegarder"] = "/api/hprim/upload";
    ServerAppRoutes["hprimUploadLeger"] = "/api/hprim/leger/upload";
    ServerAppRoutes["doctorDetail"] = "/api/medecin/detail";
    ServerAppRoutes["doctorPatientList"] = "/api/medecin/patient";
    ServerAppRoutes["doctorPracticeInfo"] = "/api/medecin/cabinet";
    ServerAppRoutes["doctorSignatureImageFile"] = "/api/medecin/signature";
    ServerAppRoutes["ordonnance1"] = "/api/ordonnance/1/:hprimId";
    ServerAppRoutes["ordonnanceFormatted"] = "/api/ordonnance/formate/:hprimId";
    ServerAppRoutes["ordonnancePdfGenerate"] = "/api/ordonnance/pdf/generer/:hprimId";
    ServerAppRoutes["ordonnancePdfList"] = "/api/ordonnance/pdf/liste/:hprimId";
    ServerAppRoutes["ordonnancePdfDownload"] = "/api/ordonnance/pdf/telecharger/:hprimId/:uid";
    ServerAppRoutes["ruleEngine1"] = "/api/moteur-de-regles/1/:hprimId";
    ServerAppRoutes["wsNotify"] = "/api/ws/notify";
})(ServerAppRoutes = exports.ServerAppRoutes || (exports.ServerAppRoutes = {}));
