import {FaMinus} from 'react-icons/fa'


export interface AnomalyDocumentationProps {
    reco: string
}

function AnomalyDocumentation(props: AnomalyDocumentationProps) {

    const {reco} = props

    return <div className='documentationDiv'>
           <span className='documentationSpan'>
               <FaMinus color={'#091954'} size={'0.7em'}/>
               Documentation
           </span>
        <div className='documentationP'>
            {reco.split('\n').map((line, i) => (
                <p key={i}> {line} </p>
            ))}
        </div>
    </div>
}

export {AnomalyDocumentation}
