import {CategoryValueMap} from './CategoryValueMap'
import {
  TabOrientationCSS,
  TabPanel
} from './TabPanel'

abstract class TabFactory<Value> {
  private readonly _categoryValueMap: CategoryValueMap<Value>
  protected readonly _tabLabels: string[]

  constructor(categoryValueMap: CategoryValueMap<Value>) {
    this._categoryValueMap = categoryValueMap
    this._tabLabels = Object.keys(categoryValueMap)
  }

  protected sortTabLabels(): void {}

  get tabLabels(): string[] {
    return this._tabLabels
  }

  protected abstract displayValue(tabLabel: string, value: Value): JSX.Element

  elementFactories(): ((selectedTab: number) => JSX.Element)[] {
    this.sortTabLabels()
    return this._tabLabels.map(this.elementFactory.bind(this))
  }

  private elementFactory(tabLabel: string, listIndex: number): (selectedTab: number) => JSX.Element {
    return (selectedTab: number) => {
      const singleValue: Value = this._categoryValueMap[tabLabel]

      return <TabPanel
        classNameTabPanel='tabContent'
        value={selectedTab}
        index={listIndex}
        key={tabLabel}
        orientation={TabOrientationCSS.vertical}>

        {this.displayValue(tabLabel, singleValue)}
      </TabPanel>
    }
  }
}

export {TabFactory}
