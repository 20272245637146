import {ComponentUIDLabel} from '../../base/ComponentUIDLabel'
import {ApplicationTemplate} from '../../base/template/ApplicationTemplate'
import '../../../style/contact.scss'
import AuthorisationProxy from '../../base/authorise/AuthorisationProxy'
import {UserGroup} from '@deepa-web/shared/src/type/CognitoUser'
import {PageHeaderSimple} from "../../base/pageHeader/PageHeaderSimple"
import {Grid} from '@mui/material'

function Contact() {
  return <AuthorisationProxy
    allowedGroups={[UserGroup.doctors, UserGroup.labos]}>
    <ContactLabeled/>
  </AuthorisationProxy>
}

function ContactLabeled() {
  return <ComponentUIDLabel uid='CID-Contact'>
      <ApplicationTemplate>
          <Grid sx={{width: '91%'}} container spacing={2}>
              <Grid item xs={12} sm={12} lg={12}>
                  <PageHeaderSimple pageTitle={'Contact'}/>
              </Grid>
              <Grid item xs={7} sm={7} lg={7} sx={{display: 'flex', justifyContent: 'center', margin: 'auto'}}>
                  <div className='information'>
                      <p> Pour tout renseignement vous pouvez nous contacter au </p>
                      <p> + 33 1 84 60 75 90 </p>
                      <p> ou à l'adresse </p>
                      <div>
                          <a href='mailto:support@deepia.fr'> support@deepia.fr </a>
                      </div>
                  </div>
              </Grid>
          </Grid>
      </ApplicationTemplate>
  </ComponentUIDLabel>
}

export {Contact}
