import { newUid } from '../../saga/uid';
const defaultLabel = 'Ajoutez votre label';
function initDoctorPracticeState() {
    return { phones: [newPhoneEntry()] };
}
function newPhoneEntry() {
    return {
        uid: newUid(),
        label: defaultLabel,
        value: ''
    };
}
function phonesExist(state) {
    return state.phones.filter(userEnteredValue).length > 0;
}
function userEnteredValue(phone) {
    return phone.value !== undefined && phone.value.length > 0;
}
export { defaultLabel, initDoctorPracticeState, newPhoneEntry, phonesExist };
