import { reverse, sortBy } from 'lodash';
import { formatDateIso } from '@deepa-web/shared/src/type/date/ExplicitDate';
import { SortOrder } from '@deepa-web/shared/src/type/SortOrder';
function sortNotificationsByDate(list, sort) {
    const asc1 = sortBy(list, n => formatDateIso(n.dateExamen));
    return sort === SortOrder.asc
        ? asc1
        : reverse(asc1);
}
export { sortNotificationsByDate };
