import {OrdonnanceContent} from './OrdonnanceContent'
import {OrdonnanceFooter} from '../footer'
import {Header} from '../header'
import {doctorDataProviderWrapper} from '../../../../../../redux/store/doctorDataProviderWrapper'
import {doctorPracticeProviderWrapper} from '../../../../../../redux/store/doctorPracticeProviderWrapper'
import {ordonnance1ProviderWrapper} from "../../../../../../redux/store/ordonnance1ProviderWrapper"
import {ordonnanceFormatProviderWrapper} from "../../../../../../redux/store/ordonnanceFormatProviderWrapper";

function OrdonnancePrintableEmbedded() {
  return ordonnance1ProviderWrapper.ProviderWrapper(
   ordonnanceFormatProviderWrapper.ProviderWrapper(
    doctorDataProviderWrapper.ProviderWrapper(
      doctorPracticeProviderWrapper.ProviderWrapper(
        <OrdonnancePrintableHooked/>
      ))))
}

function OrdonnancePrintableHooked() {
  return (
    <div className='templateContainer'>
      <Header/>
      <OrdonnanceContent/>
      <OrdonnanceFooter/>
    </div>
  )
}

export {OrdonnancePrintableEmbedded}
