import {Button} from '@mui/material'
import {PieceJointeDecrite} from '@deepa-web/shared/src/type/PieceJointeDecrite'
import {FiDownloadCloud} from 'react-icons/fi'
import {downloadAttachment} from './downloadAttachment'

function DownloadBilanAttachment(props: PieceJointeDecrite) {
  return <Button className='attachmentDownloadButton'
                 onClick={() => downloadAttachment(props)}>
    <FiDownloadCloud color={'#091954'} size={'1.5em'}/>
  </Button>
}

export {DownloadBilanAttachment}
