import { call, delay, put, takeEvery } from 'redux-saga/effects';
import { UserActionType } from '../../redux/user/UserAction';
import { WsMessage } from '@deepa-web/shared/src/routes/wsMessage';
import { resetAction } from '../../redux/user/dispatchToUserStore';
import { requestSummaryListFromServer } from '../../redux/hprimSummaryList';
import { addNotificationListener, removeNotificationListener } from './notificationListener';
import { clearStores } from '../../redux/common/clearStores';
import { logoutServiceinstance } from './LogoutService';
import { stringify } from '@deepa-web/shared/src/json/stringify';
import { requestFeedbackSummaryFromServer } from '../../redux/feedbackSummary';
import { requestDoctorPersonalDataFromServer } from '../../redux/doctorData';
import { isUserDoctor } from '@deepa-web/shared/src/type/CognitoUser';
import { requestDoctorPracticeFromServer } from '../../redux/doctorPractice';
import { wsServiceInstance } from '../../apiClient/webSocket/WsServiceInstance';
function* userSaga() {
    yield takeEvery([UserActionType.login, UserActionType.logout], peekAction);
}
function* peekAction(action) {
    const wsService = wsServiceInstance.wsService;
    switch (action.type) {
        case UserActionType.login:
            if (action.user) {
                const userJson = stringify(action.user);
                yield call(wsService.sendMessage.bind(wsService), WsMessage.user, userJson);
                yield call(addNotificationListener);
                yield call(requestSummaryListFromServer);
                yield call(requestFeedbackSummaryFromServer);
                if (isUserDoctor(action.user)) {
                    yield call(requestDoctorPersonalDataFromServer);
                    yield call(requestDoctorPracticeFromServer);
                }
            }
            break;
        case UserActionType.logout:
            yield call(logoutServiceinstance.runCallBack.bind(logoutServiceinstance));
            yield call(wsService.disconnect.bind(wsService));
            yield call(removeNotificationListener);
            yield call(clearStores);
            yield delay(1000);
            yield put(resetAction());
            break;
    }
}
export { userSaga };
