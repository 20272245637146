import { initAlertState } from './AlertState';
import { AlertActionType } from './AlertAction';
function alertReducer(prevState = initAlertState(), action) {
    let messages;
    switch (action.type) {
        case AlertActionType.newAlert:
            messages = [
                ...prevState.messages,
                Object.assign({}, action.alertMessage)
            ];
            return { messages };
        case AlertActionType.deleteAlert:
            messages = prevState.messages.filter((m) => m.uid !== action.uid);
            return { messages };
        default:
            return prevState;
    }
}
export { alertReducer };
