import { ConfigBuilderFacadeFactoryBrowser } from './AxiosRequestConfigBuilderBrowser';
import { ContentErrorFunctorFactoryBrowser } from '../../error/ContentErrorFunctorFactoryBrowser';
import { hostPort } from './hostPort';
class RequestFactoryModuleArgBrowser {
    configBuilderFactory() {
        return new ConfigBuilderFacadeFactoryBrowser();
    }
    contentErrorFactory() {
        return new ContentErrorFunctorFactoryBrowser();
    }
    get hostPort() {
        return hostPort;
    }
}
export { RequestFactoryModuleArgBrowser };
