import {TableHead, TableRow } from '@mui/material'
import {CellHeader} from "../../../base/tableCells/CellHeader";


function Header(): JSX.Element {
  return <TableHead>
    <TableRow>
      <CellHeader> Utilisateur </CellHeader>
      <CellHeader> Hprim ID </CellHeader>
      <CellHeader> Date </CellHeader>
      <CellHeader> Note </CellHeader>
    </TableRow>
  </TableHead>
}

export {Header}
