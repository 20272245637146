import {AnomalySources} from './AnomalySources'
import {AnomalyExams} from './exam'
import {AnomalySelectable} from '@deepa-web/shared/src/type/awsLambda/ruleEngine/response/anomaly/Anomaly'


function AnomalyExplorations(props: AnomalySelectable) {

  const {explorations, sources} = props

  return <div className='anomalyExplorationsDiv'>
    <div className='anomalyExplorationsExam'>
      <AnomalyExams explorations={explorations}/>
      <AnomalySources sources={sources}/>
    </div>
  </div>

}


export {AnomalyExplorations}
