import {BooleanFunctorReactComponent} from './BooleanFunctorReactComponent'
import {NoDataMessage} from '../NoDataMessage'

export interface ListElementFactoryProps<T> {
  list: T[]
  noDataMessage: string | null
}

abstract class ListElementFactory<T, TProps extends ListElementFactoryProps<T>>
  extends BooleanFunctorReactComponent<TProps> {

  protected get isMatch(): boolean {
    return this.props.list.length > 0
  }

  protected get noMatch(): JSX.Element | null {
      const message: string | null = this.props.noDataMessage
      return message === null ? null :
    <NoDataMessage> {message} </NoDataMessage>
  }
}

export {ListElementFactory}
