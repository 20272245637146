import { call, select } from 'redux-saga/effects';
import { callPOSTendpoint } from './callEndpoints';
import { bilanSanguin1StoreProxy } from '../../redux/bilanSanguin';
import { ordonnance1StoreProxy } from '../../redux/ordonnance';
import { parseDateTime } from '@deepa-web/shared/src/type/date/ExplicitDateTime';
export function* postAction(action) {
    const hprimId = action.key;
    if (!(hprimId === null || hprimId === void 0 ? void 0 : hprimId.hprimId)) {
        return;
    }
    const feedbackRating = yield select((s) => s.items[hprimId.hprimId]);
    if (feedbackRating !== undefined) {
        const feedbackData = composeFeedbackDataSnapshot(hprimId, feedbackRating);
        yield call(callPOSTendpoint, action.key, feedbackData);
    }
}
function composeFeedbackDataSnapshot(hprimId, rating) {
    const hprimId1 = hprimId.hprimId;
    return Object.assign(Object.assign({}, hprimId), { bilanSanguin: bilanSanguin1StoreProxy.getState.items[hprimId1], ordonnance: ordonnance1StoreProxy.getState.items[hprimId1], rating, timestamp: parseDateTime(new Date()) });
}
