abstract class BooleanFunctor<TResult> {
  private readonly _match: boolean

  constructor(isMatch: boolean){
    this._match = isMatch
  }

  protected abstract get match(): TResult
  protected abstract get noMatch(): TResult

  get value() {
    return this._match ? this.match : this.noMatch
  }
}

export {BooleanFunctor}
