export var DoctorDataActionType;
(function (DoctorDataActionType) {
    DoctorDataActionType["requestData"] = "requestData";
    DoctorDataActionType["storeData"] = "storeData";
    DoctorDataActionType["storeField"] = "storeField";
    DoctorDataActionType["postDataToAPI"] = "postDataToAPI";
    DoctorDataActionType["clearData"] = "clearData";
})(DoctorDataActionType || (DoctorDataActionType = {}));
export var DoctorDataPropertyName;
(function (DoctorDataPropertyName) {
    DoctorDataPropertyName["RPPS"] = "RPPS";
    DoctorDataPropertyName["lastName"] = "lastName";
    DoctorDataPropertyName["firstName"] = "firstName";
    DoctorDataPropertyName["email"] = "email";
    DoctorDataPropertyName["phone"] = "phone";
})(DoctorDataPropertyName || (DoctorDataPropertyName = {}));
