import React from 'react'
import '../../../style/myAccount.scss'
import {DeepTabs} from '../../base/tab/DeepTabs'
import {Tab1} from './Tab1'
import {Tab2} from "./Tab2"
import {Tab3} from "./Tab3"
import AuthorisationProxy from '../../base/authorise/AuthorisationProxy'
import {UserGroup} from '@deepa-web/shared/src/type/CognitoUser'
import {ComponentUIDLabel} from '../../base/ComponentUIDLabel'
import {ApplicationTemplate} from '../../base/template/ApplicationTemplate'
import {allyPropsHorizontal} from '../../base/tab/style/allyProps'
import {tabsStyleHorizontal} from '../../base/tab/style/tabStyle'
import {TabOrientationTag} from '../../base/tab/enum/TabOrientationTag'
import {PageHeaderSimple} from "../../base/pageHeader/PageHeaderSimple"
import {Grid} from '@mui/material'


const tabLabels = [
    'Personnel',
    'Professionnel',
    'Signature'
  ],

  tabFactory = [Tab1, Tab2, Tab3]

function MyAccount() {
  return <AuthorisationProxy allowedGroups={[UserGroup.doctors]}>
    <ComponentUIDLabel uid='CID-MyAccount'>
        <MyAccountAuthorised/>
    </ComponentUIDLabel>
  </AuthorisationProxy>
}

function MyAccountAuthorised() {
  return <ApplicationTemplate>
      <Grid sx={{width: '91%'}} container spacing={2}>
          <Grid item xs={12} sm={12} lg={12}>
              <PageHeaderSimple pageTitle={'Mon Compte'} />
          </Grid>
          <Grid item xs={7} sm={7} lg={7} sx={{display: 'flex', justifyContent: 'center', margin: 'auto'}}>
              <div className='form'>
                  <DeepTabs
                      tabLabels={tabLabels}
                      orientation={TabOrientationTag.horizontal}
                      childFactory={tabFactory}
                      allyProps={allyPropsHorizontal}
                      tabStyle={tabsStyleHorizontal}
                  />
              </div>
          </Grid>
      </Grid>
  </ApplicationTemplate>
}

export {MyAccount}
