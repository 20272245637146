import {FiAlertTriangle} from 'react-icons/fi'
import {PropsWithChildren} from 'react'

function OrdonnanceDataWarning(props: PropsWithChildren): JSX.Element {
  return <p className='noDoctorInfo'>
    <FiAlertTriangle size={'1.3em'}/>
    {props.children}
  </p>
}

export {OrdonnanceDataWarning}
