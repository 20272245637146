"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RedirectTo = void 0;
class RedirectTo {
    constructor(path) {
        this._path = path;
    }
    get path() {
        return this._path;
    }
}
exports.RedirectTo = RedirectTo;
