import { UserActionType } from './UserAction';
import { userStoreProxy } from './index';
import { mapToRelevant } from '@deepa-web/shared/src/type/CognitoUser';
function dispatchToUserStore(action) {
    if (action) {
        userStoreProxy.dispatch(action);
    }
}
function loginAction(arg) {
    if (arg.user) {
        return {
            type: UserActionType.login,
            user: mapToRelevant(arg.user)
        };
    }
    return null;
}
function logoutAction(callback) {
    if (typeof callback === 'function') {
        return {
            type: UserActionType.logout
        };
    }
    return null;
}
function resetAction() {
    return {
        type: UserActionType.reset
    };
}
export { dispatchToUserStore, loginAction, logoutAction, resetAction };
