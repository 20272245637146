import {Exam} from '@deepa-web/shared/src/type/awsLambda/ruleEngine/response/anomaly/Exam'
import {groupBy} from 'lodash'
import {
  Exploration1,
  GroupedValues
} from '../AnomalyExamProps'

function explorationToShow(exploration: Exploration1) {
  const {exams} = exploration,
    examsGrouped: GroupedValues<Exam> = groupBy(exams, 'recap_line')

  return Object.keys(examsGrouped).map((recap_line) => {
    const exams: Exam[] = examsGrouped[recap_line]

    return <span className='itemListAnomaly' key={recap_line}>
      {exams.map(exam => exam.label).join(', ')}
    </span>
  })
}

export {explorationToShow}
