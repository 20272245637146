import { call, select, takeEvery } from 'redux-saga/effects';
import { SearchQueryActionType } from '../../redux/hprimSummaryList/query/SearchQueryAction';
import { hprimSummaryListStoreProxy } from '../../redux/hprimSummaryList';
import { putHPRIMlistToPaginationStore } from '../../redux/hprimSummaryList/pagination';
import { sortNotificationsByDate } from '../../redux/hprimSummaryList/sort/sortNotificationByDate';
import { filterNotificationByDateRange } from '../../redux/hprimSummaryList/filter/filterNotificationByDateRange';
import { filterNotificationByUnread } from '../../redux/hprimSummaryList/filter/filterNotificationByUnread';
import { filterNotificationByAnormaux } from '../../redux/hprimSummaryList/filter/filterNotificationByAnormaux';
import { filterNotificationByUnreadAndAnormaux } from '../../redux/hprimSummaryList/filter/filterNotificationByUnreadAndAnormaux';
import { filterNotificationByUrgentInt } from '../../redux/hprimSummaryList/filter/filterNotificationByUrgence';
import { filterSequence } from '../../redux/hprimSummaryList/filter/filterSequence';
import { filterNotificationByQuery } from '../../redux/hprimSummaryList/filter/filterNotificationByQuery';
function* searchQuerySaga() {
    yield takeEvery([
        SearchQueryActionType.applyFilter,
        SearchQueryActionType.examDateEnd,
        SearchQueryActionType.examDateStart,
        SearchQueryActionType.reverseSortDateExamen,
        SearchQueryActionType.setQuery,
        SearchQueryActionType.showBilanUnread,
        SearchQueryActionType.showBilanAnormaux,
        SearchQueryActionType.showOnlyAnormaux,
        SearchQueryActionType.showOnlyUnread,
        SearchQueryActionType.showUnreadAndAnormaux,
        SearchQueryActionType.urgent_int
    ], peekAction);
}
function* peekAction(_action) {
    const { query, examDateSort, examDateStart, examDateEnd, showBilanUnread, showBilanAnormaux, showOnlyUnread, showOnlyAnormaux, showUnreadAndAnormaux, urgent_int } = yield select(), safeQuery = query === null || query === undefined ? '' : query, entireList = hprimSummaryListStoreProxy.getState;
    function* filters() {
        yield (list) => filterNotificationByQuery(safeQuery, list);
        yield (list) => filterNotificationByDateRange(list, (n) => n.dateExamen, examDateStart, examDateEnd);
        yield (list) => filterNotificationByUnread(list, showBilanUnread || showOnlyUnread);
        yield (list) => filterNotificationByAnormaux(list, showBilanAnormaux || showOnlyAnormaux);
        yield (list) => filterNotificationByUnreadAndAnormaux(list, showUnreadAndAnormaux);
        yield (list) => filterNotificationByUrgentInt(list, urgent_int);
    }
    const filteredList = filterSequence(filters(), entireList.items), sortedList = sortNotificationsByDate(filteredList, examDateSort);
    yield call(putHPRIMlistToPaginationStore, sortedList);
}
export { searchQuerySaga };
