"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RequestFactoryBody = void 0;
const RequestFactory_1 = require("./RequestFactory");
class RequestFactoryBody extends RequestFactory_1.RequestFactory {
    constructor(routeParameter, contentType, responseType, moduleArg, body) {
        super(routeParameter, contentType, responseType, moduleArg);
        this._body = body;
    }
}
exports.RequestFactoryBody = RequestFactoryBody;
