import { call, put, select, takeEvery } from 'redux-saga/effects';
import { DoctorDataActionType } from '../../redux/doctorData/DoctorDataAction';
import { DoctorDataCallEndpoints } from './DoctorDataCallEndpoints';
let requested = false;
function* doctorPersonalDetailSaga() {
    yield takeEvery([
        DoctorDataActionType.requestData,
        DoctorDataActionType.postDataToAPI
    ], peekAction);
}
function* peekAction(action) {
    switch (action.type) {
        case DoctorDataActionType.requestData:
            yield requestAction();
            break;
        case DoctorDataActionType.postDataToAPI:
            yield postAction();
            break;
    }
}
function* postAction() {
    const doctorPersonalDetail = yield select((s) => s);
    if (doctorPersonalDetail) {
        const caller = new DoctorDataCallEndpoints();
        yield call(caller.callPOSTendpoint.bind(caller), doctorPersonalDetail);
    }
}
function* requestAction() {
    if (requested) {
        return;
    }
    requested = true;
    const caller = new DoctorDataCallEndpoints(), doctorPersonalDetail = (yield call(caller.callGETendpoint.bind(caller)));
    if (!doctorPersonalDetail) {
        requested = false;
        return;
    }
    const newAction = {
        type: DoctorDataActionType.storeData,
        data: doctorPersonalDetail
    };
    yield put(newAction);
    requested = false;
}
export { doctorPersonalDetailSaga };
