import { initUserState, LoginStage } from './UserState';
import { UserActionType } from './UserAction';
function userReducer(prevState = initUserState(), action) {
    switch (action.type) {
        case UserActionType.login:
            return {
                user: action.user,
                loginStage: LoginStage.loggedIn
            };
        case UserActionType.logout:
            return {
                loginStage: LoginStage.loggedOut
            };
        case UserActionType.reset:
            return initUserState();
        default:
            return prevState;
    }
}
export { userReducer };
