import {groupBy} from 'lodash'
import {
  BilanSanguin,
  BilanSanguinValue
} from '@deepa-web/shared/src/type/awsLambda/ruleEngine/response/BilanSanguin'
import {BilanSanguinTabFactory} from './BilanSanguinTabFactory'


function tabbedDataFactory(bilanSanguin: BilanSanguin)
  : BilanSanguinTabFactory {

  const result = groupBy(bilanSanguin, keyExtract)
  result.Toutes = [...bilanSanguin]
  return new BilanSanguinTabFactory(result)
}

function keyExtract(bilanSanguinVal: BilanSanguinValue): string {
  return bilanSanguinVal.category || 'Aucune catégorie spécifiée'
}

export {tabbedDataFactory}
