import useMediaQuery from '@mui/material/useMediaQuery'
import {DeepTabsResponsive} from '../../../../../../../base/tab/DeepTabsResponsive'
import {MediaQueryResponsiveFactory} from '../../../../../../../base/tab/mediaQuery'
import {DeepSelect} from '../../../../../../../base/select/DeepSelect'

export interface BilanSanguinResponsiveProps {
  labels: string[]
  childFactory: ((selectedItem: number) => JSX.Element)[]
}

function BilanSanguinResponsive(props: BilanSanguinResponsiveProps) {

  const {labels, childFactory} = props,
    screenIsWide = useMediaQuery('(min-width:1500px)')

  return <>
    {screenIsWide ?
      <DeepTabsResponsive tabLabels={labels}
                          childFactory={childFactory}
                          responsiveFactory={new MediaQueryResponsiveFactory(screenIsWide)}
      /> :
      <DeepSelect menuItems={labels} childFactory={childFactory}/>
    }
  </>
}

export {BilanSanguinResponsive}
