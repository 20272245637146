import {BilanSanguinValue} from '@deepa-web/shared/src/type/awsLambda/ruleEngine/response/BilanSanguin'

class FormatBilanSanguinValue {
  private readonly _bilanSanguinValue: BilanSanguinValue

  constructor(bilanSanguinValue: BilanSanguinValue) {
    this._bilanSanguinValue = bilanSanguinValue
  }

  get bilanSanguinValue(): BilanSanguinValue {
    return this._bilanSanguinValue
  }

  get unit(): string {
    const unit = this._bilanSanguinValue.unit
    switch (unit) {
      case 'none':
      case null:
      case undefined:
        return ''
      default:
        return unit
    }
  }

  get bin(): string {
    return nullable(this._bilanSanguinValue.bin)
  }

  get bsn(): string {
    return nullable(this._bilanSanguinValue.bsn)
  }

  get valeur(): string {
    const value = this._bilanSanguinValue.value,
      unit = this.unit

    return `${value} ${unit}`
  }

  get valeurNormales(): string {
    const bin = this.bin,
      bsn = this.bsn

    return `${bin} - ${bsn}`
  }
}

function nullable(nullableNumber?: number | null): string {
  switch (nullableNumber) {
    case undefined:
    case null:
      return ''
    default:
      return nullableNumber.toString()
  }
}

export {FormatBilanSanguinValue}
