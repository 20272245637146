import { SortOrder } from '@deepa-web/shared/src/type/SortOrder';
function initQueryState() {
    return {
        query: null,
        examDateSort: SortOrder.desc,
        examDateStart: null,
        examDateEnd: null,
        showBilanUnread: false,
        showBilanAnormaux: false,
        showOnlyUnread: false,
        showOnlyAnormaux: false,
        showUnreadAndAnormaux: false,
        urgent_int: null
    };
}
export { initQueryState };
