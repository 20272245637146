import {BooleanFunctor} from '../../functor/BooleanFunctor'
import {
  tabsStyleHorizontal,
  TabStyle,
  tabStyleVertical
} from '../style/tabStyle'

class TabStyleFunctor extends BooleanFunctor<TabStyle> {
  protected get match(): TabStyle {
    return tabStyleVertical
  }

  protected get noMatch(): TabStyle {
    return tabsStyleHorizontal
  }
}

export {TabStyleFunctor}
