import { call, put, select, takeEvery } from 'redux-saga/effects';
import { DictionaryActionType } from '../../redux/common/dictionary/DictionaryAction';
import { callDELETEEndpoint, callGETEndpoint } from './callEndpoints';
import { requestSummaryListFromServer } from '../../redux/hprimSummaryList';
import { dispatchDeleteNotification } from './dispatchDeleteNotification';
const requested = new Set();
function* bilanSanguin1Saga() {
    yield takeEvery([
        DictionaryActionType.requestData,
        DictionaryActionType.deleteData
    ], wrapCancelAction);
}
function* wrapCancelAction(action) {
    yield call(peekAction, action);
    yield put({ type: DictionaryActionType.cancelRequest });
}
function* peekAction(action) {
    switch (action.type) {
        case DictionaryActionType.requestData:
            const key = action.key.hprimId, existing = yield select((s) => s.items[key]), isRequested = requested.has(key);
            if (existing || isRequested) {
                break;
            }
            requested.add(key);
            const bilanSanguin1 = (yield call(callGETEndpoint, action.key));
            if (!bilanSanguin1) {
                requested.delete(key);
                break;
            }
            const key2 = action.key, newAction = {
                type: DictionaryActionType.storeData,
                key: key2,
                item: bilanSanguin1
            };
            yield put(newAction);
            requested.delete(key);
            break;
        case DictionaryActionType.deleteData:
            const deleteResponse = (yield call(callDELETEEndpoint, action.key));
            console.log(deleteResponse);
            requested.clear();
            yield call(requestSummaryListFromServer);
            yield call(dispatchDeleteNotification, action.key);
            break;
    }
}
export { bilanSanguin1Saga };
