import { DictionaryActionType } from '../../redux/common/dictionary/DictionaryAction';
import { call, put } from 'redux-saga/effects';
import { callGETendpoint } from './callEndpoints';
const requested = new Set();
function* requestAction(action) {
    const { hprimId } = action.key;
    if (requested.has(hprimId)) {
        return;
    }
    requested.add(hprimId);
    const feedbackData = (yield call(callGETendpoint, action.key));
    if (!feedbackData) {
        requested.delete(hprimId);
        return;
    }
    const newAction = {
        type: DictionaryActionType.storeData,
        key: action.key,
        item: feedbackData.rating
    };
    yield put(newAction);
    requested.delete(hprimId);
}
export { requestAction };
