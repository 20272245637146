import {
  BilanSanguinDiagnostics,
  RuleEngineProps
} from './BilanSanguinDiagnostics'
import {HPRIMId} from '@deepa-web/shared/src/type/hprim/HPRIMId'
import {DictionaryState} from '@deepa-web/browser-shared/src/redux/common/dictionary/DictionaryState'
import {ComponentUIDLabel} from '../../../../../../base/ComponentUIDLabel'
import {RuleEngineData} from '@deepa-web/shared/src/type/awsLambda/ruleEngine/response'
import {renderDataOrProgress} from '../../../../../../base/progress/renderDataOrProgress'
import {ruleEngine1ProviderWrapper} from '../../../../../../../redux/store/ruleEngine1ProviderWrapper'
import {Diagnostic} from '@deepa-web/shared/src/type/awsLambda/ruleEngine/response/Diagnostic'


function RuleEngine(hprimId: HPRIMId) {
  const elementFactory = (state: DictionaryState<RuleEngineData>) => {
    const factory = new RuleEngineFactory(hprimId, state)
    return <ComponentUIDLabel uid='CID-RuleEngine'>
      {factory.render()}
    </ComponentUIDLabel>
  }

  return renderDataOrProgress(elementFactory, ruleEngine1ProviderWrapper)
}

class RuleEngineFactory {
  private readonly _hprimId: string
  private readonly _state: DictionaryState<RuleEngineData>
  private _diagnostics: Diagnostic[] = []

  constructor({hprimId}: HPRIMId, state: DictionaryState<RuleEngineData>) {
    this._hprimId = hprimId
    this._state = state
    this.setDiagnostics()
  }

  private setDiagnostics() {
    const response: RuleEngineData | undefined = this._hprimId ?
      this._state.items[this._hprimId] : undefined

    this._diagnostics = response?.diagnostics || []
  }

  private get ruleEngineProps(): RuleEngineProps {
    return {
      hprimId: this._hprimId
    }
  }

  render() {
    const props: RuleEngineProps = this.ruleEngineProps

    return <div className='tabContainer'>
        <BilanSanguinDiagnostics {...props} />
    </div>

  }
}

export {RuleEngine}
