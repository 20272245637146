import React, {PropsWithChildren} from 'react'
import {ComponentUIDState} from '@deepa-web/browser-shared/src/redux/componentUID/ComponentUIDState'
import {componentUIDProviderWrapper} from '../../redux/store/componentUIDProviderWrapper'

export interface ComponentUIDLabelProps extends PropsWithChildren {
  uid: string
}

function ComponentUIDLabel(props: ComponentUIDLabelProps): JSX.Element {
  const display: boolean = componentUIDProviderWrapper.useSelector((s: ComponentUIDState) => s.displayUID)
  return display ? displayLabel(props) : <>{props.children}</>
}

function displayLabel(props: ComponentUIDLabelProps): JSX.Element {
  return <div className='componentUIDLabel'>
    <div className='UIDlabel'> {props.uid} </div>
    {props.children}
  </div>
}

export {ComponentUIDLabel}
