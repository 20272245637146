import { NotificationActionType } from '../../notification/NotificationAction';
import { notificationStoreProxy } from '../../notification';
import { doctorDataStoreProxy } from '../../doctorData';
import { DoctorDataActionType } from '../../doctorData/DoctorDataAction';
import { DPRactionType } from '../../doctorPractice/DPRaction';
import { doctorPracticeStoreProxy } from '../../doctorPractice';
function clearOtherStores() {
    clearNotificationStore();
    clearDoctorDataStore();
    clearDoctorPracticeStore();
}
function clearNotificationStore() {
    const action = { type: NotificationActionType.clearAllNotifications };
    notificationStoreProxy.dispatch(action);
}
function clearDoctorDataStore() {
    const action = { type: DoctorDataActionType.clearData };
    doctorDataStoreProxy.dispatch(action);
}
function clearDoctorPracticeStore() {
    const action = { type: DPRactionType.clearData };
    doctorPracticeStoreProxy.dispatch(action);
}
export { clearOtherStores };
