import {useNavigate} from 'react-router-dom'
import {NavigationButton} from '../navigation/NavigationButton'
import {BrowserRoutePaths} from '@deepa-web/shared/src/routes/BrowserRoutePaths'
import HeaderLogo from '../../../../images/Logo2024.png'
import SignOutButton from '../../button/SignOutButton'
import {isUserAdmin} from '../../../../redux/user/userGroup/isUserAdmin'
import {MenuUserInfo} from './MenuUserInfo'
import {doctorDataProviderWrapper} from '../../../../redux/store/doctorDataProviderWrapper'
import {DoctorDataState} from '@deepa-web/browser-shared/src/redux/doctorData/DoctorDataState'
import '../../../../style/baseComponents/topMenu.scss'
import {CustomTooltip} from '../../tooltip'
import {NotificationDropdown} from '../../../composite/doctorLabo/common/notificationComponent/dropdown'


function TopMenuJsx() {
    return doctorDataProviderWrapper.ProviderWrapper(
        <TopMenuJsxHooked/>
    )
}

function TopMenuJsxHooked() {

    const includeAdmin = isUserAdmin(),
        navigate = useNavigate(),
        doctorData = doctorDataProviderWrapper.useSelector(
            (state: DoctorDataState) => state)

    return <div className='topMenuDiv'>
        <img className='headerLogo' src={HeaderLogo} alt={'Logo'}/>
        <div className='topMenuButtons'>
            <NavigationButton
                onClick={() => navigate(BrowserRoutePaths.appRoot)}>
                Accueil
            </NavigationButton>
            <CustomTooltip title={'Cette page sera bientôt accessible.'}
                           placement={'bottom'}>
                {disabledMenuButton('Mon Compte')}
            </CustomTooltip>
            <NavigationButton
                onClick={() => navigate(BrowserRoutePaths.contact)}>
                Contact
            </NavigationButton>
            {includeAdmin ?
                <CustomTooltip title={'Cette page sera bientôt accessible.'}
                               placement={'bottom'}>
                    {disabledMenuButton('Admin')}
                </CustomTooltip>
                :
                null
            }
        </div>
        <div className='logoutInfoDiv'>
            <MenuUserInfo {...doctorData} />
            <NotificationDropdown/>
            <SignOutButton/>
        </div>
    </div>
}

function disabledMenuButton(label: string) {
    return <span>
        <NavigationButton>
            {label}
        </NavigationButton>
    </span>
}


export {TopMenuJsx}
