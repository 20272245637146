import {HPRIMId} from '@deepa-web/shared/src/type/hprim/HPRIMId'
import {DictionaryState} from '@deepa-web/browser-shared/src/redux/common/dictionary/DictionaryState'
import {ordonnanceFormatProviderWrapper} from '../../../../../../redux/store/ordonnanceFormatProviderWrapper'

function OrdonnanceView(hprimId: HPRIMId): JSX.Element {
  const formattedOrdonnance: string[] = ordonnanceFormatProviderWrapper.useSelector(
    (s: DictionaryState<string[]>) => s.items[hprimId.hprimId])

  return <div>
    <ul>
      {formattedOrdonnance.map(OneValue)}
    </ul>
  </div>
}

function OneValue(value: string, index: number) {
  return <li className='previewItems' key={index}> {value} </li>
}

export {OrdonnanceView}
