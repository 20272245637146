import {I18n} from 'aws-amplify'

const authScreenLabels = {
    fr: {
        'Sign In': 'S\'inscrire',
        'Username': 'E-mail',
        'Password': 'Mot de passe',
        'Sign in': 'S\'identifier',
        'Forgot your password?': 'Mot de passe oublié?',
        'Change Password': 'Modifier le mot de passe',
        'Confirm Password': 'Confirmez votre mot de passe',
        'Back to Sign In': 'Retour à la connexion',
        'Reset your password': 'Réinitialisez votre mot de passe',
        'Enter your username': 'Saisissez votre nom d\'utilisateur.',
        'Send code': 'Envoyer le code',
        'Code': 'le code',
        'Submit': 'Envoyer',
        'Resend Code': 'Renvoyer le code',
        'New Password': 'Nouveau mot de passe',
        'Incorrect username or password.': 'E-mail ou mot de passe saisi incorrect.'
    }
}

I18n.setLanguage('fr')
I18n.putVocabularies(authScreenLabels)
