import {PieceJointeDecrite} from '@deepa-web/shared/src/type/PieceJointeDecrite'
import {ServerRouteParameter} from '@deepa-web/shared/src/routes/ServerRouteParameter'
import {ServerAppRoutes} from '@deepa-web/shared/src/routes/ServerAppRoutes'
import {OnClickDownloadFile} from "../../../../../../../../base/downloadBlob/OnClickDownloadFile";

class DownloadAttachment extends OnClickDownloadFile {
  private readonly _props: PieceJointeDecrite

  constructor(props: PieceJointeDecrite) {
    super()
    this._props = props
  }

  protected get fileName(): string {
    const {piece_jointe_id, type_fichier} = this._props
    return `Fichier_${piece_jointe_id}.${type_fichier}`
  }

  protected routeParameter(): ServerRouteParameter {
    const {piece_jointe_id} = this._props,
      routeParameter = new ServerRouteParameter(ServerAppRoutes.hprimPieceJointeDownload)

    routeParameter.hprimId(this._props)
    routeParameter.uid(piece_jointe_id)
    return routeParameter
  }
}

function downloadAttachment(props: PieceJointeDecrite) {
  const instance = new DownloadAttachment(props)
  instance.onClick.call(instance)
}

export {downloadAttachment}
