import { initEtapeState } from './EtapeState';
import { EtapeActionEnum } from './EtapeAction';
function etapeReducer(prevState = initEtapeState(), action) {
    const prevEtape = prevState.etape;
    switch (action.type) {
        case EtapeActionEnum.resetEtape:
            return initEtapeState();
        case EtapeActionEnum.incrementEtape:
            return {
                etape: prevEtape + 1
            };
        case EtapeActionEnum.decrementEtape:
            return {
                etape: prevEtape - 1
            };
        default:
            return prevState;
    }
}
export { etapeReducer };
