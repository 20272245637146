import {DoctorPracticeState} from '@deepa-web/browser-shared/src/redux/doctorPractice/DoctorPracticeState'
import {HPRIMId} from '@deepa-web/shared/src/type/hprim/HPRIMId'
import {
  Params,
  useParams
} from 'react-router-dom'
import {OrdonnanceView} from './OrdonnanceView'
import {doctorPracticeProviderWrapper} from '../../../../../../redux/store/doctorPracticeProviderWrapper'


function OrdonnanceContent(): JSX.Element {
  const params: Readonly<Params> = useParams(),
    hprimId: HPRIMId = {hprimId: params.hprimId!}

  const location: string | undefined = doctorPracticeProviderWrapper.useSelector(
      (state: DoctorPracticeState) => state.location),

    currentDate = new Date().toLocaleDateString('fr-FR')

  return <>
    <div className='documentTitle'> Ordonnance</div>
    <div className='documentDate'> Fait à {location},
      le {currentDate}</div>
    <div className='prescriptionElementDiv'>
      <OrdonnanceView {...hprimId}/>
    </div>
  </>
}

export {OrdonnanceContent}
