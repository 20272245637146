import {RPPS} from './RPPS'
import {FINESS} from './FINESS'

function BarCodes(): JSX.Element {
  return <div className='doctorBarcode'>
    <RPPS/>
    <FINESS/>
  </div>
}

export {BarCodes}
