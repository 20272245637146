export var SearchQueryActionType;
(function (SearchQueryActionType) {
    SearchQueryActionType["setQuery"] = "setQuery";
    SearchQueryActionType["reset"] = "reset";
    SearchQueryActionType["applyFilter"] = "applyFilter";
    SearchQueryActionType["reverseSortDateExamen"] = "reverseSortDateExamen";
    SearchQueryActionType["examDateStart"] = "examDateStart";
    SearchQueryActionType["examDateEnd"] = "examDateEnd";
    SearchQueryActionType["showBilanUnread"] = "showBilanUnread";
    SearchQueryActionType["showBilanAnormaux"] = "showBilanAnormaux";
    SearchQueryActionType["showOnlyUnread"] = "showOnlyUnread";
    SearchQueryActionType["showOnlyAnormaux"] = "showOnlyAnormaux";
    SearchQueryActionType["showUnreadAndAnormaux"] = "showUnreadAndAnormaux";
    SearchQueryActionType["urgent_int"] = "urgent_int";
})(SearchQueryActionType || (SearchQueryActionType = {}));
