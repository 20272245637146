const urgentColours: {[urgent_int: number]: string} =
  {
    0: '#68ec81',
    0.5: '#f8d045',
    1: '#f8d045',
    1.5: '#f8d045',
    2: '#fa803c',
    2.5: '#fa803c',
    3: '#f62222',
    3.5: '#f62222',
    4: '#000000'
  }

export {urgentColours}
