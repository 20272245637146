var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { GETrequestFactory } from '@deepa-web/axios/src/factory/GETrequestFactory';
import { ServerAppRoutes } from '@deepa-web/shared/src/routes/ServerAppRoutes';
import { ListActionType } from '../../redux/common/list/ListAction';
import { putHPRIMlistToPaginationStore } from '../../redux/hprimSummaryList/pagination';
import { ServerRouteParameter } from '@deepa-web/shared/src/routes/ServerRouteParameter';
import { applySearchQuery } from '../../redux/hprimSummaryList/query';
import { RequestFactoryModuleArgBrowser } from '../../apiClient/https/RequestFactoryModuleArgBrowser';
import { isEqual } from 'lodash';
let isRequested = false;
function* hprimSummaryListSaga() {
    yield takeEvery([
        ListActionType.requestData,
        ListActionType.storeData
    ], peekAction);
}
function* peekAction(action) {
    switch (action.type) {
        case ListActionType.requestData:
            if (isRequested) {
                break;
            }
            isRequested = true;
            const previousList = yield select((s) => s.items), summaryList = (yield call(callEndpoint)), safeList = summaryList || [];
            if (isEqual(previousList, safeList)) {
                isRequested = false;
                return;
            }
            const newAction = {
                type: ListActionType.storeData,
                items: safeList
            };
            yield put(newAction);
            isRequested = false;
            yield call(putHPRIMlistToPaginationStore, safeList);
            break;
        case ListActionType.storeData:
            yield call(applySearchQuery);
            break;
    }
}
function callEndpoint() {
    return __awaiter(this, void 0, void 0, function* () {
        const routeParameter = new ServerRouteParameter(ServerAppRoutes.hprimSummary), factory = new GETrequestFactory(routeParameter, new RequestFactoryModuleArgBrowser()), requestBuilder = factory.Result();
        return yield requestBuilder.resultContentOrDefault();
    });
}
export { hprimSummaryListSaga };
