var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { GETrequestFactory } from '@deepa-web/axios/src/factory/GETrequestFactory';
import { POSTrequestFactory } from '@deepa-web/axios/src/factory/POSTrequestFactory';
import { RequestFactoryModuleArgBrowser } from '../../apiClient/https/RequestFactoryModuleArgBrowser';
export class CallEndpointGeneric {
    callGETendpoint() {
        return __awaiter(this, void 0, void 0, function* () {
            const { serverRoute, responseType } = this.getSpecs, factory = new GETrequestFactory(serverRoute, new RequestFactoryModuleArgBrowser(), responseType), requestBuilder = factory.Result();
            return yield requestBuilder.resultContentOrDefault();
        });
    }
    callPOSTendpoint(details) {
        return __awaiter(this, void 0, void 0, function* () {
            const { contentType, serverRoute, responseType } = this.postSpecs, factory = new POSTrequestFactory(serverRoute, contentType, responseType, new RequestFactoryModuleArgBrowser(), details), requestBuilder = factory.Result();
            yield requestBuilder.resultContentOrDefault();
        });
    }
}
