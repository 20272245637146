import {Dayjs} from 'dayjs'
import {
  SearchQueryAction,
  SearchQueryActionType
} from '@deepa-web/browser-shared/src/redux/hprimSummaryList/query/SearchQueryAction'
import {toExplicitDateNull} from '@deepa-web/browser-shared/src/type/DayjsExplicitDateAdapter'
import {ExplicitDate} from '@deepa-web/shared/src/type/date/ExplicitDate'
import {dispatchQuery} from './dispatchQuery'

function handleEndDate(newDate: Dayjs | null) {
  const explicit: ExplicitDate | null = toExplicitDateNull(newDate),
    action: SearchQueryAction = {
      type: SearchQueryActionType.examDateEnd,
      examDateEnd: explicit || undefined
    }
  dispatchQuery(action)
}

export {handleEndDate}
