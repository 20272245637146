import { MarkHprimReadActionType } from './MarkHprimReadAction';
import { markHprimReadReducer } from './markHprimReadReducer';
import { storeFactory } from '../factory/storeFactory';
import { markHprimReadSaga } from '../../saga/markHprimRead';
const markHprimReadStore = storeFactory(markHprimReadReducer, markHprimReadSaga);
function markHPRIMread(notification) {
    markHprimReadStore.dispatch({
        type: MarkHprimReadActionType.markRead,
        notification
    });
}
export { markHPRIMread };
