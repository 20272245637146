const accordionStyle = {boxShadow: 'none', border: 'none'},
  summaryStyle = {
    backgroundColor: '#ffffff',
    fontWeight: 'bold',
    color: '#091954',
    margin: '0'
  },
  detailStyle = {padding: '1em'}

export {
  accordionStyle,
  summaryStyle,
  detailStyle
}
