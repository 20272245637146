import { io } from 'socket.io-client';
import { wsManagerOptions } from './wsManagerOptions';
import { ActionStatus } from '@deepa-web/shared/src/type/ContentError';
class WsService {
    constructor(apiPath) {
        this.registeredListeners = new Set();
        this._apiPath = apiPath;
    }
    get socket() {
        if (!this.socket_) {
            const path = this._apiPath;
            this.socket_ = io(path, wsManagerOptions());
        }
        return this.socket_;
    }
    disconnect() {
        function disconnectAction(socket) {
            socket.removeAllListeners();
            this.registeredListeners.clear();
            socket.io.reconnection(false);
            socket.disconnect();
            delete this.socket_;
        }
        this.socketAction(disconnectAction.bind(this));
    }
    sendMessage(messageType, message) {
        function action(socket) {
            socket.emit(messageType, message);
        }
        return this.socketAction(action);
    }
    addListener(messageType, listener) {
        if (!this.registeredListeners.has(messageType)) {
            this.registeredListeners.add(messageType);
            return this.socketAction(s => s.on(messageType, listener));
        }
        return ActionStatus.fail;
    }
    removeListeners(messageType) {
        if (this.registeredListeners.has(messageType)) {
            this.registeredListeners.delete(messageType);
            return this.socketAction(s => s.off(messageType));
        }
        return ActionStatus.fail;
    }
    socketAction(action) {
        if (this.socket) {
            action(this.socket);
            return ActionStatus.success;
        }
        else {
            return ActionStatus.fail;
        }
    }
}
export { WsService };
