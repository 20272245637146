import {
  TableCell,
  TableRow
} from '@mui/material'
import {PaginationState} from '@deepa-web/browser-shared/src/redux/hprimSummaryList/pagination/PaginationState'
import {paginationProviderWrapper} from '../../../../../../../redux/store/paginationProviderWrapper'

// Avoid a layout jump when reaching the last page with empty rows.
function EmptyRowsFix() {
  const {
      rowsPerPage,
      list,
      page
    } = paginationProviderWrapper.useSelector((s: PaginationState) => s),
    listCopy = [...list],
    emptyRows = rowsPerPage - Math.min(rowsPerPage, listCopy.length - page * rowsPerPage)

  return emptyRows > 0 ?
    <TableRow style={{height: 37.31 * emptyRows}}>
      <TableCell sx={{padding : '6px'}} colSpan={6}/>
    </TableRow>
    : null
}

export {EmptyRowsFix}
