import {HPRIMId} from '@deepa-web/shared/src/type/hprim/HPRIMId'
import {PromptDeleteProps} from '../../../../../../base/button/PromptDelete'
import {deleteBilanSanguin} from '../ruleEngine/bilanSanguin/deleteBilanSanguin'
import {gotoPage} from '../../../../../../base/gotoDetailPage'
import {NavigateFunction} from 'react-router-dom'
import {BrowserRoutePaths} from '@deepa-web/shared/src/routes/BrowserRoutePaths'
import {OnClickProps} from '@deepa-web/browser-shared/src/type/OnClickProps'

function promptDeleteProps(hprimId: HPRIMId,
                           navigate: NavigateFunction,
                           open: boolean,
                           setOpen: (value: (((prevState: boolean) => boolean) | boolean)) => void): PromptDeleteProps {
  const navigateHome: OnClickProps = gotoPage(BrowserRoutePaths.appRoot, navigate),
    onClickDelete1Item = () => {
      deleteBilanSanguin(hprimId.hprimId)
      navigateHome.onClick?.call(navigateHome.onClick)
    }

  return {
    open,
    setOpen,
    onClickDelete1Item
  }
}

export {promptDeleteProps}
