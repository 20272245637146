import { initState } from './PaginationState';
import { PaginationActionType } from './PaginationAction';
import { calculateMaxPage } from './calculateMaxPage';
function paginationReducer(prevState = initState(), action) {
    const newState = Object.assign(Object.assign({}, prevState), { list: [...prevState.list] });
    switch (action.type) {
        case PaginationActionType.setList:
            newState.list = [...action.data];
            adjustPageIfNeed(newState);
            break;
        case PaginationActionType.setPage:
            newState.page = action.data;
            break;
        case PaginationActionType.setRowsPerPage:
            newState.rowsPerPage = action.data;
            newState.page = 0;
            break;
    }
    return newState;
}
function adjustPageIfNeed(newState) {
    const maxPage = calculateMaxPage(newState);
    if (maxPage >= 0 && newState.page > maxPage) {
        newState.page = maxPage;
    }
}
export { paginationReducer };
