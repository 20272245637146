import {searchQueryStoreProxy} from '@deepa-web/browser-shared/src/redux/hprimSummaryList/query'
import {
  SearchQueryAction,
  SearchQueryActionTypeOnlyEnum
} from '@deepa-web/browser-shared/src/redux/hprimSummaryList/query/SearchQueryAction'

function dispatchQuery(action: SearchQueryAction) {
  searchQueryStoreProxy.dispatch.call(searchQueryStoreProxy, action)
}

function dispatchQueryType(type: SearchQueryActionTypeOnlyEnum) {
  dispatchQuery({type})
}

export {
  dispatchQuery,
  dispatchQueryType
}
