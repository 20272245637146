import React from 'react'
import {DialogModal, DialogModalProps} from '../DialogModal'

export interface PromptDeleteProps {
  open: boolean,
  setOpen: (value: (((prevState: boolean) => boolean) | boolean)) => void,
  onClickDelete1Item: () => void
}

function PromptDelete({
                        open,
                        setOpen,
                        onClickDelete1Item
                      }: PromptDeleteProps) {

  const dialogProps: DialogModalProps = {
    title: 'Confirmer la suppression',
    open,
    buttons: [
      <button
        className='dialogButtons'
        onClick={() => setOpen(false)}
        key='cancelButton'> Annuler</button>,

      <button
        className='dialogButtons'
        onClick={onClickDelete1Item}
        key='confirmButton'
      > Confirmer</button>
    ]
  }

  return <DialogModal {...dialogProps}>
    Êtes-vous sûr de supprimer l'élément ?
  </DialogModal>
}

export {PromptDelete}
