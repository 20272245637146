"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reverseSortOrder = exports.SortOrder = void 0;
var SortOrder;
(function (SortOrder) {
    SortOrder["asc"] = "asc";
    SortOrder["desc"] = "desc";
})(SortOrder = exports.SortOrder || (exports.SortOrder = {}));
const other = {
    [SortOrder.desc]: SortOrder.asc,
    [SortOrder.asc]: SortOrder.desc
};
function reverseSortOrder(o) {
    return other[o];
}
exports.reverseSortOrder = reverseSortOrder;
