import {PieceJointeDecrite} from '@deepa-web/shared/src/type/PieceJointeDecrite'
import {TableRow} from '@mui/material'
import {DownloadBilanAttachment} from "./downloadBilanAttachment";
import {CellBody} from "../../../../../../../../base/tableCells/CellBody";

function SingleItemBilanAttachment(props : PieceJointeDecrite) {

    const {piece_jointe_id, description_telecharge, type_fichier} = props

    return <TableRow>
        <CellBody> {piece_jointe_id} </CellBody>
        <CellBody> {description_telecharge} </CellBody>
        <CellBody> {type_fichier} </CellBody>
        <CellBody> <DownloadBilanAttachment {...props}/> </CellBody>
    </TableRow>
}

export {SingleItemBilanAttachment}
