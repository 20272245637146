import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material'
import '../../style/baseComponents/dialogModal.scss'
import {PropsWithChildren} from 'react'

export interface DialogModalProps extends PropsWithChildren {
  title: string
  open: boolean
  buttons: JSX.Element[]
}

function DialogModal(props: DialogModalProps): JSX.Element {
  const {open, title, children, buttons} = props

  return (
    <Dialog
      open={open}
      className='dialogBox'
      PaperProps={{
        sx: {
          backgroundColor: 'white',
          border : '2px solid #C6D9F7',
          padding: '1.2em',
          color: '#091954'
        }
      }}
    >
      <DialogTitle id='dialogTitle'> {title} </DialogTitle>
      <DialogContent className='dialogContent'>
        {children}
      </DialogContent>
      <DialogActions>
        {buttons}
      </DialogActions>
    </Dialog>
  )
}

export {DialogModal}
