import React from 'react'
import {
  FiChevronsDown,
  FiChevronsUp
} from 'react-icons/fi'
import {TableRow} from '@mui/material'
import {CellBody} from '../../../../../../../../base/tableCells/CellBody'
import {bilanSanguinValueSafe} from './values'
import {FormatBilanSanguinValue} from './FormatBilanSanguinValue'
import {BilanSanguinValue} from '@deepa-web/shared/src/type/awsLambda/ruleEngine/response/BilanSanguin'

function BilanSanguinValueDisplay(
  bilanSanguinValue: BilanSanguinValue,
  index: number):
  JSX.Element {
  const normal: boolean = bilanSanguinValue.normal === false ? false : true,
    format = new FormatBilanSanguinValue(bilanSanguinValue),
    CellBodyN = CellBodyMap(normal),
    hl_flag = bilanSanguinValue.hl_flag ?
      showIndicateur(bilanSanguinValue) :
      <CellBody/>


  return <TableRow key={index}>
    {CellBodyN(bilanSanguinValueSafe(format), 1)}
    <CellBody/>
    {CellBodyN(format.valeur, 2)}
    <CellBody/>
    {CellBodyN(format.bin, 3)}
    {CellBodyN(format.bsn, 4)}
    {hl_flag}
  </TableRow>
}


function showIndicateur(bilan: BilanSanguinValue) {

  const {hl_flag} = bilan

  switch (hl_flag) {
    case 'L' :
      return <CellBody>
        <FiChevronsDown color={'#6980fd'} size={'1.7em'}/>
      </CellBody>
    case 'H' :
      return <CellBody>
        <FiChevronsUp color={'#ef3d3d'} size={'1.7em'}/>
      </CellBody>
    default :
      return <CellBody/>
  }
}

function CellBodyMap(isNormal: boolean) {
  return (value: string, idx: number) =>
    <CellBody isNormal={isNormal} key={idx}> {value} </CellBody>
}


export {BilanSanguinValueDisplay}
