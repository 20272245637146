import {ListState} from '@deepa-web/browser-shared/src/redux/common/list/ListState'
import {
  TableBody,
  TableRow
} from '@mui/material'
import {FeedbackDataUser} from '@deepa-web/shared/src/type/FeedbackData'
import {formatDate} from '@deepa-web/shared/src/type/date/ExplicitDate'
import {CellBody} from "../../../base/tableCells/CellBody";
import {feedbackSummaryProviderWrapper} from '../../../../redux/store/feedbackSummaryProviderWrapper'


function Body(): JSX.Element {
  const feedbacks: FeedbackDataUser[] = feedbackSummaryProviderWrapper.useSelector((s: ListState<FeedbackDataUser>) => s.items)

  return <TableBody className='feedbackBodyTable'>
    {feedbacks.map((dataItem: FeedbackDataUser, idx: number) =>
      <TableRow key={idx}>
        <CellBody> {dataItem.userEmail} </CellBody>
        <CellBody> {dataItem.feedbackData.hprimId} </CellBody>
        <CellBody> {formatDate(dataItem.feedbackData.timestamp)} </CellBody>
        <CellBody> {dataItem.feedbackData.rating}/5 </CellBody>
      </TableRow>
    )}
  </TableBody>
}


export {Body}
