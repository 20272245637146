import { WsMessage } from '@deepa-web/shared/src/routes/wsMessage';
import { NotificationActionType } from '../../redux/notification/NotificationAction';
import { notificationStoreProxy } from '../../redux/notification';
import { requestSummaryListFromServer } from '../../redux/hprimSummaryList';
import { wsServiceInstance } from '../../apiClient/webSocket/WsServiceInstance';
function removeNotificationListener() {
    var _a;
    (_a = wsServiceInstance.wsService) === null || _a === void 0 ? void 0 : _a.removeListeners(WsMessage.hprim);
}
function addNotificationListener() {
    var _a;
    function listener(notificationString) {
        const notification = JSON.parse(notificationString), action = {
            type: NotificationActionType.newNotification,
            notification
        };
        notificationStoreProxy.dispatch(action);
        requestSummaryListFromServer();
    }
    (_a = wsServiceInstance.wsService) === null || _a === void 0 ? void 0 : _a.addListener(WsMessage.hprim, listener);
}
export { removeNotificationListener, addNotificationListener };
