import {Paper} from '@mui/material'
import Logo from '../../../images/Logo2023.svg'
import '../../../style/baseComponents/error.scss'
import {PropsWithChildren} from 'react'

function ErrorPageTemplate(props: PropsWithChildren): JSX.Element {
  return <Paper className='errorPaper'>
    <div className='logoDiv'>
      <img className='errorLogo' src={Logo} alt='logo'/>
    </div>
    <div className='errorContainer'>
      <div className='contentErrorDiv'>
        {props.children}
      </div>
    </div>
  </Paper>
}

export {ErrorPageTemplate}
