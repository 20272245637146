import React from 'react'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import {CellHeader} from '../../../../../../../../base/tableCells/CellHeader'
import {BilanSanguinComparator} from '../BilanSanguinComparator'
import {BilanSanguinValueDisplay} from './BilanSanguinValueDisplay'
import {
  BilanSanguin,
  BilanSanguinValue
} from '@deepa-web/shared/src/type/awsLambda/ruleEngine/response/BilanSanguin'

interface BilanSanguin1Props {
  bilanSanguin: BilanSanguin
}

function BilanSanguin1(props: BilanSanguin1Props) {
  const {bilanSanguin} = props

  if (!(bilanSanguin && bilanSanguin.length > 0)) {
    return <p className='noDataMessageBS'>Aucune donnée disponible</p>
  }

  return <div className='propsTable'>
    <TableContainer>
      <Table>
        <TableHead1/>
        <TableBody1 {...props} />
      </Table>
    </TableContainer>
  </div>
}

function TableHead1(): JSX.Element {
  return <TableHead>
    <TableRow>
      <CellHeader> Examen de biologie médicale </CellHeader>
      <CellHeader width={'0.5em'} > </CellHeader>
      <CellHeader > Valeur </CellHeader>
      <CellHeader width={'0.5em'} > </CellHeader>
      <CellHeader width={'3em'} > VdR. Min </CellHeader>
      <CellHeader width={'3em'} > VdR. Max </CellHeader>
      <CellHeader width={'5em'} ></CellHeader>
    </TableRow>
  </TableHead>
}

function TableBody1(props: BilanSanguin1Props): JSX.Element {
  const propsSorted: BilanSanguinValue[] = props.bilanSanguin.sort(BilanSanguinComparator)

  return <TableBody>
    {propsSorted.map(BilanSanguinValueDisplay)}
  </TableBody>

}

export {BilanSanguin1}
