import {
  createSelectorHook,
  Provider,
  ReactReduxContextValue
} from 'react-redux'
import React from 'react'
import {EqualityFn} from 'react-redux'
import {
  Action,
  AnyAction,
  Store
} from '@reduxjs/toolkit'
import {StoreProxy} from '@deepa-web/browser-shared/src/redux/factory/StoreProxy'

class ProviderWrapper<TState, TAction extends Action> {
  private readonly _context: React.Context<ReactReduxContextValue> = React.createContext<ReactReduxContextValue>(null as any)
  private readonly _storeProxy: StoreProxy<TState, TAction>
  private readonly _useSelector = createSelectorHook(this._context)

  constructor(storeProxy: StoreProxy<TState, TAction>) {
    this._storeProxy = storeProxy
  }

  get useSelector(): <TState, Selected = unknown>(selector: (state: TState) => Selected, equalityFn?: EqualityFn<Selected>) => Selected {
    return this._useSelector
  }

  ProviderWrapper(element: JSX.Element): JSX.Element {
    return <Provider
      store={this._storeProxy.store as unknown as Store<any, AnyAction>}
      context={this._context}>
      {element}
    </Provider>
  }
}

export {ProviderWrapper}
