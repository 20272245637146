import { notificationStoreProxy } from '../../redux/notification';
import { NotificationActionType } from '../../redux/notification/NotificationAction';
function dispatchDeleteNotification(hprimId) {
    const notification = notificationStoreProxy.getState.notifications.find(n => n.hprimId === hprimId.hprimId);
    if (!notification) {
        return;
    }
    notificationStoreProxy.dispatch({ type: NotificationActionType.deleteNotification, notification });
}
export { dispatchDeleteNotification };
