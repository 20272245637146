import {useState} from 'react'
import {DictionaryState} from '@deepa-web/browser-shared/src/redux/common/dictionary/DictionaryState'
import {HPRIMId} from '@deepa-web/shared/src/type/hprim/HPRIMId'
import {BilanSanguinTabFactory} from './BilanSanguinTabFactory'
import {bilanSanguin1ProviderWrapper} from '../../../../../../../../redux/store/bilanSanguin1ProviderWrapper'
import {BilanSanguinResponsive} from './BilanSanguinResponsive'
import {ListElementFactoryWithChildren} from '../../../../../../../base/functor/ListElementFactoryWithChildren'
import {
    AnormaleSwitch,
    ShowAnormale
} from './AnormaleSwitch'
import {BilanSanguin} from '@deepa-web/shared/src/type/awsLambda/ruleEngine/response/BilanSanguin'
import {filterAnormale} from './filterAnormale'
import {tabbedDataFactory} from './tabbedDataFactory'
import {BilanSanguinAttachmentsAccordion} from "../attachments/BilanAttachmentsAccordion";


function BilanSanguinTabs({hprimId}: HPRIMId) {
    const [showAnormale, setShowAnormale] = useState(ShowAnormale.tous),
        predicate = filterAnormale(showAnormale),
        bilanSanguin: BilanSanguin | undefined =
            bilanSanguin1ProviderWrapper.useSelector(
                (s: DictionaryState<BilanSanguin>) =>
                    hprimId ?
                        s.items[hprimId]?.filter(predicate) :
                        undefined)


    function Switch() {
        return <AnormaleSwitch
            initChoice={showAnormale}
            setChoice={setShowAnormale}
        />
    }

    if (bilanSanguin === undefined
        || bilanSanguin.length === 0) {

        return <>
            <BilanSanguinAttachmentsAccordion />
            <Switch/>
            <span className='noDataSpan'> Aucune donnée disponible </span>
        </>
    }

    const childFactoryInstance: BilanSanguinTabFactory = tabbedDataFactory(bilanSanguin),
        tabLabels: string[] = childFactoryInstance.tabLabels

    return <ListElementFactoryWithChildren list={tabLabels}
                                           noDataMessage='Aucune donnée disponible'>
        <BilanSanguinAttachmentsAccordion  />
        <Switch/>
        <BilanSanguinResponsive labels={tabLabels}
                                childFactory={childFactoryInstance.elementFactories()}/>
    </ListElementFactoryWithChildren>
}

export {BilanSanguinTabs}
