"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RequestBuilder = void 0;
const HostPortCert_1 = require("../type/HostPortCert");
const path_1 = require("./path");
const ContentError_1 = require("@deepa-web/shared/src/type/ContentError");
const RedirectTo_1 = require("../type/RedirectTo");
const AxiosError_1 = require("../type/AxiosError");
class RequestBuilder {
    constructor(routeParameter, hostPort, axiosConfig, contentErrorFactory) {
        this._routeParameter = routeParameter;
        this._hostPort = hostPort;
        this._axiosConfig = axiosConfig;
        this._contentErrorFactory = contentErrorFactory;
        this._relativeUrl = (0, path_1.dropLeadSlash)(routeParameter.Result);
    }
    async resultContentOrDefault() {
        const response = await this.callApi(), ce = this._contentErrorFactory.makeContentError(this._routeParameter);
        return ce.mapContentError(response);
    }
    axiosResponseToT(response) {
        if (response.status === 200) {
            const dataStatusCode = response.data.statusCode;
            if (dataStatusCode && (dataStatusCode !== 200)) {
                const dataBody = response.data.body;
                return {
                    status: ContentError_1.ActionStatus.fail,
                    error: dataBody
                };
            }
            return {
                status: ContentError_1.ActionStatus.success,
                content: response.data
            };
        }
        else if (response.status === 210) {
            const newPath = response.data;
            window.location.pathname = newPath;
            return {
                status: ContentError_1.ActionStatus.fail,
                error: new RedirectTo_1.RedirectTo(newPath)
            };
        }
        else {
            return {
                status: ContentError_1.ActionStatus.fail,
                error: response.data
            };
        }
    }
    caughtError(error) {
        return {
            status: ContentError_1.ActionStatus.fail,
            error: (0, AxiosError_1.formatAxiosError)(error)
        };
    }
    buildUrl() {
        const { host, port, protocol } = this._hostPort;
        let protocolString;
        switch (protocol) {
            case HostPortCert_1.Protocol.HTTP:
                protocolString = 'http';
                break;
            case HostPortCert_1.Protocol.HTTPS:
            default:
                protocolString = 'https';
                break;
        }
        return `${protocolString}://${host}:${port}/${this._relativeUrl}`;
    }
}
exports.RequestBuilder = RequestBuilder;
