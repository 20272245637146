import {
  BilanSanguin,
  BilanSanguinValue
} from '@deepa-web/shared/src/type/awsLambda/ruleEngine/response/BilanSanguin'
import {Bin} from './Bin'
import {Bsn} from './Bsn'

function toolTipContent(bilanSanguin: BilanSanguin): string[] {
  return bilanSanguin
    .filter(bs => bs.normal === false)
    .map(oneLine)
}

function oneLine(bsValue: BilanSanguinValue): string {
  const {displayed_name, value, unit} = bsValue,
    binInstance = new Bin(bsValue),
    bsnInstance = new Bsn(bsValue)

  return `${displayed_name} ${value} ${unit} ${binInstance.display}${bsnInstance.display}`
}

export {toolTipContent}

