import { initMarkHprimReadState } from './MarkHprimReadState';
import { MarkHprimReadActionType } from './MarkHprimReadAction';
function markHprimReadReducer(prevState = initMarkHprimReadState(), action) {
    switch (action.type) {
        case MarkHprimReadActionType.markRead:
            return initMarkHprimReadState();
        default:
            return prevState;
    }
}
export { markHprimReadReducer };
