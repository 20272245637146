import {UserGroup} from '@deepa-web/shared/src/type/CognitoUser'
import {UserGroupFunctorAdmin} from '@deepa-web/browser-shared/src/redux/user/userGroup/UserGroupFunctorAdmin'

function isUserAdmin(): boolean {
  const valueMap = new Map<UserGroup, boolean>()
  valueMap.set(UserGroup.admin, true)
  const functor = new UserGroupFunctorAdmin(valueMap)

  return functor.result() || false
}

export {isUserAdmin}
