import { call, put, select, takeEvery } from 'redux-saga/effects';
import { DPRactionType } from '../../redux/doctorPractice/DPRaction';
import { DoctorPracticeInfoCallEndpoints } from './DoctorPracticeInfoCallEndpoints';
let requested = false;
function* doctorPracticeSaga() {
    yield takeEvery([
        DPRactionType.requestData,
        DPRactionType.postDataToAPI
    ], peekAction);
}
function* peekAction(action) {
    switch (action.type) {
        case DPRactionType.requestData:
            yield requestAction();
            break;
        case DPRactionType.postDataToAPI:
            yield postAction();
            break;
    }
}
function* postAction() {
    const doctorPracticeState = yield select((s) => s);
    if (doctorPracticeState) {
        const caller = new DoctorPracticeInfoCallEndpoints();
        yield call(caller.callPOSTendpoint.bind(caller), doctorPracticeState);
    }
}
function* requestAction() {
    if (requested) {
        return;
    }
    requested = true;
    const caller = new DoctorPracticeInfoCallEndpoints(), doctorPractice = (yield call(caller.callGETendpoint.bind(caller)));
    if (!doctorPractice) {
        requested = false;
        return;
    }
    const newAction = {
        type: DPRactionType.storeData,
        data: doctorPractice
    };
    yield put(newAction);
    requested = false;
}
export { doctorPracticeSaga };
