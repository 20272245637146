import { put, takeEvery } from 'redux-saga/effects';
import { DictionaryActionType } from '../../redux/common/dictionary/DictionaryAction';
import { dictionaryTypeActionFactory } from '../../redux/common/dictionary/dictionaryTypeActionFactory';
import { postAction } from './postAction';
import { requestAction } from './requestAction';
function* feedback1Saga() {
    yield takeEvery([
        DictionaryActionType.requestData,
        DictionaryActionType.postDataToAPI
    ], wrapCancelAction);
}
function* wrapCancelAction(action) {
    const cancelAction = dictionaryTypeActionFactory(DictionaryActionType.cancelRequest);
    yield peekAction(action);
    yield put(cancelAction);
}
function* peekAction(action) {
    switch (action.type) {
        case DictionaryActionType.requestData:
            yield requestAction(action);
            break;
        case DictionaryActionType.postDataToAPI:
            yield postAction(action);
            break;
    }
}
export { feedback1Saga };
