import {
    TableHead,
    TableRow
} from '@mui/material'
import {CellHeader} from "../../../../base/tableCells/CellHeader"

function HeaderPatientHprimList() {
    return <TableHead>
        <TableRow>
            <CellHeader> Compte-rendu n° </CellHeader>
            <CellHeader> Date de prélévement </CellHeader>
            <CellHeader> Urgence de l'anomalie </CellHeader>
        </TableRow>
    </TableHead>
}

export {HeaderPatientHprimList}
