import { StoreProxy } from '../factory/StoreProxy';
import { configureStore } from '@reduxjs/toolkit';
import { AlertActionType } from './AlertAction';
import { alertReducer } from './alertReducer';
import { newAlertMessage } from './AlertMessage';
const alertStore = configureStore({ reducer: alertReducer }), alertStoreProxy = new StoreProxy(alertStore);
function dispatchNewAlertMessage(message) {
    const alertMessage = newAlertMessage(message);
    alertStore.dispatch({
        type: AlertActionType.newAlert,
        alertMessage
    });
}
function dispatchDeleteAlert(uid) {
    alertStore.dispatch({
        type: AlertActionType.deleteAlert,
        uid
    });
}
export { alertStoreProxy, dispatchNewAlertMessage, dispatchDeleteAlert };
