import { DictionaryReducer } from '../../common/dictionary';
import { StoreProxy } from '../../factory/StoreProxy';
import { configureStore } from '@reduxjs/toolkit';
import { DictionaryActionType } from '../../common/dictionary/DictionaryAction';
class OrdonnanceTitleReducer extends DictionaryReducer {
    keyToString({ hprimId }) {
        return hprimId;
    }
}
const reducerInstance = new OrdonnanceTitleReducer(), store = configureStore({
    reducer: reducerInstance.dictionaryReducer.bind(reducerInstance)
}), ordoTitleStoreProxy = new StoreProxy(store);
function selectOrdoTitle(hprimId, ordo_title) {
    const action = {
        type: DictionaryActionType.storeData,
        key: hprimId,
        item: ordo_title
    };
    store.dispatch(action);
}
function readCurrentOrdoTitle(hprimId) {
    if (!hprimId)
        return undefined;
    return store.getState().items[hprimId.hprimId];
}
export { ordoTitleStoreProxy, selectOrdoTitle, readCurrentOrdoTitle };
