import { storeFactory } from '../factory/storeFactory';
import { StoreProxy } from '../factory/StoreProxy';
import { DictionaryReducer } from "../common/dictionary";
import { ordonnanceSummaryListSaga } from "../../saga/ordonnanceSummaryList";
class OrdonnanceListReducer extends DictionaryReducer {
    keyToString({ hprimId }) {
        return hprimId;
    }
}
const reducerInstance = new OrdonnanceListReducer(), ordonnanceSummaryListStore = storeFactory(reducerInstance.dictionaryReducer.bind(reducerInstance), ordonnanceSummaryListSaga), ordonnanceSummaryListStoreProxy = new StoreProxy(ordonnanceSummaryListStore);
export { ordonnanceSummaryListStoreProxy };
