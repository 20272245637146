import { userSaga } from '../../saga/user';
import { storeFactory } from '../factory/storeFactory';
import { userReducer } from './userReducer';
import { StoreProxy } from '../factory/StoreProxy';
import { doctorLaboGroup, isAdmin, UserGroup } from '@deepa-web/shared/src/type/CognitoUser';
const userStore = storeFactory(userReducer, userSaga), userStoreProxy = new StoreProxy(userStore);
function loginStage() {
    return userStore.getState().loginStage;
}
function groupDoctorLaboUser() {
    const groups1 = groups();
    return doctorLaboGroup(groups1);
}
function groupAdminUser() {
    const groups1 = groups(), isAdmin1 = isAdmin(groups1);
    return isAdmin1 ? UserGroup.admin : undefined;
}
function groups() {
    var _a;
    return ((_a = userStore.getState().user) === null || _a === void 0 ? void 0 : _a.group) || [];
}
export { userStoreProxy, loginStage, groupDoctorLaboUser, groupAdminUser };
