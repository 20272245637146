import React from 'react'
import{Grid} from '@mui/material'
import {
  CognitoUserRelevant,
  doctorLaboGroup,
  UserGroup
} from '@deepa-web/shared/src/type/CognitoUser'
import '../../../../style/doctorLaboCommon.scss'
import {routeLoggedInUser} from '../../../base/authorise/routeLoggedInUser'
import {UserState} from '@deepa-web/browser-shared/src/redux/user/UserState'
import {ApplicationTemplate} from '../../../base/template/ApplicationTemplate'
import {HprimList} from './hprimPages/summary/HprimList'
import {AuthArg} from '@deepa-web/browser-shared/src/type/AuthArg'
import {userProviderWrapper} from '../../../../redux/store/userProviderWrapper'
import {doctorDataProviderWrapper} from '../../../../redux/store/doctorDataProviderWrapper'
import {HprimListFiltersToggle} from "./hprimPages/summary/filter/HprimListFiltersToggle";
import {PageHeaderComplex} from "../../../base/pageHeader/PageHeaderComplex";
import {DashboardInfo} from "./hprimPages/summary/dashboardInfo";


export interface DoctorLaboCommonProps extends AuthArg {
  expectUserGroup: UserGroup
}

function DoctorLaboCommon(props: DoctorLaboCommonProps) {
  return userProviderWrapper.ProviderWrapper(
    doctorDataProviderWrapper.ProviderWrapper(
    <ApplicationTemplate>
      <DoctorLaboCommonHooked {...props}/>
    </ApplicationTemplate>
  ))
}

function DoctorLaboCommonHooked(props: DoctorLaboCommonProps) {
  const userRelevant: CognitoUserRelevant | undefined = userProviderWrapper.useSelector((s: UserState) => s.user),
    actualGroups: UserGroup[] = userRelevant?.group || [],
    doctorLabo1: UserGroup.doctors | UserGroup.labos | undefined = doctorLaboGroup(actualGroups)

  if (doctorLabo1 !== props.expectUserGroup) {
    return routeLoggedInUser(userRelevant)
  }

  return <div className='homePageContent'>
      <Grid sx={{width: '91%'}} className='homePageGrid' container spacing={2}>
          <Grid item xs={12} sm={12} lg={12}>
              <PageHeaderComplex pageTitle={'Tableau de Bord'}
                                 action={<DashboardInfo/>} />
              <HprimListFiltersToggle />
          </Grid>
          <Grid item xs={12} sm={12} lg={12}>
              <div className='widgets'>
              <HprimList/>
              </div>
          </Grid>
      </Grid>
  </div>

}

export {DoctorLaboCommon}
