import {ComponentUIDLabel} from '../../../../../base/ComponentUIDLabel'
import {
  Table,
  TableContainer
} from '@mui/material'
import {Footer} from './footer/Footer'
import {Header} from './header/Header'
import {Body} from './body/Body'
import {hprimSummaryListProviderWrapper} from '../../../../../../redux/store/hprimSummaryListProviderWrapper'
import {paginationProviderWrapper} from '../../../../../../redux/store/paginationProviderWrapper'
import {searchQueryProviderWrapper} from '../../../../../../redux/store/searchQueryProviderWrapper'
import 'dayjs/locale/en-gb'
function HprimList() {
  return hprimSummaryListProviderWrapper.ProviderWrapper(
    paginationProviderWrapper.ProviderWrapper(
      searchQueryProviderWrapper.ProviderWrapper(
        <HprimListHooked/>
      )
    )
  )
}

function HprimListHooked() {
  return (
    <ComponentUIDLabel uid='CID-HprimList'>
      <div className='hprimList'>
        <div className='table'>
          <TableContainer className='hprimTable'>
            <Table>
              <Header/>
              <Body/>
              <Footer/>
            </Table>
          </TableContainer>
        </div>
      </div>
    </ComponentUIDLabel>
  )
}

export {HprimList}
