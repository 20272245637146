import {UserGroup} from '@deepa-web/shared/src/type/CognitoUser'
import {OnClickProps} from '@deepa-web/browser-shared/src/type/OnClickProps'
import {UserGroupFunctorDoctorLabo} from '@deepa-web/browser-shared/src/redux/user/userGroup/UserGroupFunctorDoctorLabo'
import {FiEye} from 'react-icons/fi'
import React from 'react'

export interface NavigateToHPRIMbuttonProps {
  doctor: OnClickProps
  labo: OnClickProps
}

function UserGroupAwareNavigateButton(props: NavigateToHPRIMbuttonProps): JSX.Element {
  const {doctor, labo} = props,
    map = new Map<UserGroup, OnClickProps>()

  map.set(UserGroup.doctors, doctor)
  map.set(UserGroup.labos, labo)

  const functor = new UserGroupFunctorDoctorLabo(map)
  return <button className='notificationButton'
                 onClick={functor.result()?.onClick}>
    <FiEye color='#205072'/>
  </button>
}

export {UserGroupAwareNavigateButton}
