var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { POSTrequestFactory } from '@deepa-web/axios/src/factory/POSTrequestFactory';
import { ContentTypeShort } from '@deepa-web/shared/src/type/ContentTypeShort';
import { RequestFactoryModuleArgBrowser } from '../../apiClient/https/RequestFactoryModuleArgBrowser';
class UploadFile {
    postFormData(file) {
        return __awaiter(this, void 0, void 0, function* () {
            const formData = new FormData();
            formData.append(this.formDataProperty, file);
            const factory = new POSTrequestFactory(this.apiRoute, ContentTypeShort.file, 'text', new RequestFactoryModuleArgBrowser(), formData), builder = factory.Result(), response = yield builder.resultContentOrDefault();
            if (response !== null) {
                setTimeout(alert, 0, response);
            }
        });
    }
}
export { UploadFile };
