import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {Exam} from '@deepa-web/shared/src/type/awsLambda/ruleEngine/response/anomaly/Exam'
import {Exploration} from '@deepa-web/shared/src/type/awsLambda/ruleEngine/response/anomaly/Exploration'
import {AccordionDetails} from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import {
  accordionStyle,
  detailStyle,
  summaryStyle
} from './accordionStyle'
import {
  Exploration1,
  GroupedValues
} from '../AnomalyExamProps'
import {showDelay} from './showDelay'
import {SingleOrdoTitleDetail} from './SingleOrdoTitleDetail'


function SingleOrdoTitle(explorationsGrouped: GroupedValues<Exploration<Exam>>, ordo_title: string): JSX.Element {
  const explorations: Exploration1[] = explorationsGrouped[ordo_title],
    firstExploration: Exploration1 = explorations[0]

  return <div className='examContainer' key={ordo_title}>
    <Accordion sx={accordionStyle}>
      <AccordionSummary expandIcon={<ExpandMoreIcon/>} sx={summaryStyle}>
        {firstExploration.ordo_title} {showDelay(firstExploration)}
      </AccordionSummary>
      <AccordionDetails sx={detailStyle}>
        {SingleOrdoTitleDetail(explorations)}
      </AccordionDetails>
    </Accordion>
  </div>
}

export {SingleOrdoTitle}
