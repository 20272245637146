import React from 'react'
import {
  dispatchToUserStore,
  logoutAction
} from '@deepa-web/browser-shared/src/redux/user/dispatchToUserStore'
import {LogoutUserAction} from '@deepa-web/browser-shared/src/redux/user/UserAction'
import {logoutServiceinstance} from '@deepa-web/browser-shared/src/saga/user/LogoutService'
import {withAuthenticator} from '@aws-amplify/ui-react'
import {authenticatorOptions} from '../../../configuration/authenticatorOptions'
import '../../../style/baseComponents/logOutButton.scss'
import {AuthArg} from '@deepa-web/browser-shared/src/type/AuthArg'
import {FiPower} from 'react-icons/fi'
import {useNavigate} from 'react-router-dom'
import {BrowserRoutePaths} from '@deepa-web/shared/src/routes/BrowserRoutePaths'
import {CustomTooltip} from '../tooltip'

function SignOutButton(arg?: AuthArg): JSX.Element | null {
  const navigate = useNavigate(),
    redirectHome = () => navigate(BrowserRoutePaths.appRoot)

  const onClick = () => {
    logoutServiceinstance.saveCallback(arg?.signOut)
    const action: LogoutUserAction | null = logoutAction(arg?.signOut)
    dispatchToUserStore(action)
    redirectHome()
  }

  return arg?.signOut ?
    <CustomTooltip placement='bottom-end' title='Se déconnecter'>
      <button className='logOutButton' onClick={onClick}>
        <FiPower size={'1.5em'} color={'#091954'}/>
      </button>
    </CustomTooltip>
    : null
}


export default withAuthenticator(SignOutButton, authenticatorOptions)
