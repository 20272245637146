import {orderBy} from 'lodash'
import {Container} from '@mui/material'
import {FaCircle} from 'react-icons/fa'
import {HPRIMId} from '@deepa-web/shared/src/type/hprim/HPRIMId'
import {DictionaryState} from '@deepa-web/browser-shared/src/redux/common/dictionary/DictionaryState'
import {anomaly1ProviderWrapper} from '../../../../../redux/store/anomaly1ProviderWrapper'
import {
  AnomaliesSelectable,
  AnomalySelectable
} from '@deepa-web/shared/src/type/awsLambda/ruleEngine/response/anomaly/Anomaly'
import {AnomalyExplorations} from './anomaly1/AnomalyExplorations'
import {AnomalyAccordionFactory} from './anomalyAccordion/AnomalyAccordionFactory'
import '../../../../../style/hprimDetailPage/anomaly.scss'
import {urgentColours} from '../../../../base/urgent/urgentColours'
import {ListElementFactoryWithChildren} from "../../../../base/functor/ListElementFactoryWithChildren"
import {
    CopyClipboardParam,
    CopyToClipboard
} from '../../../../base/button/clipboard/CopyToClipboard'
import {FiClipboard} from "react-icons/fi"
import {FiCheck} from "react-icons/fi"


function AnomalyDoctor(hprimId: HPRIMId) {
  return anomaly1ProviderWrapper.ProviderWrapper(
    <AnomalyDoctorHooked {...hprimId} />
  )
}


function AnomalyDoctorHooked(hprimId: HPRIMId) {

  const anomalies: AnomalySelectable[] = anomaly1ProviderWrapper.useSelector(
      (s: DictionaryState<AnomaliesSelectable>) => s.items[hprimId.hprimId]) || [],
    anomaliesSortedDesc = orderBy(anomalies, ['urgent'], ['desc'])

  return <div className='anomalyContent'>
    <div className='anomaliesTitle'>
      <h3> Anomalies biologiques identifiées </h3>
    </div>
        <ListElementFactoryWithChildren list={anomalies} noDataMessage={'Pas d\'anomalies présentes'}>
      <Container className='ordonnanceContainer'>
        <div className='anomalyContainer'>
          {anomaliesSortedDesc?.map((anomaly: AnomalySelectable) => {
            return <div key={anomaly.label} className='anomalyDiv'>
                                <CopyToClipboard
                                    icon={clipboardIcon()}
                                    tooltipTitle={'Copier le nom de l\'anomalie'}
                                    useDefaults={false}
                                    className='copyToClipboardButton'
                                >
                                    {anomaly.label}
                                </CopyToClipboard>
              <AnomalyAccordionFactory
                label={
                  <div className='accordionSummaryContent'>
                    {showUrgentSummary(anomaly.urgent)} {anomaly.label}
                  </div>
                }>
                <AnomalyExplorations {...anomaly}/>
              </AnomalyAccordionFactory>
            </div>
          })}
        </div>
      </Container>
    </ListElementFactoryWithChildren>
  </div>

}

function showUrgentSummary(urgent: number) {
  const color: string | undefined = urgentColours[urgent]
  return color ? <FaCircle color={color}/> : null
}

function clipboardIcon(): CopyClipboardParam<JSX.Element> {
    return {
        param: <FiClipboard color={'#091954'} size={'1.3em'}/>,
        copied: <FiCheck color={'#091954'} size={'1.3em'}/>
    }
}

export {AnomalyDoctor}
