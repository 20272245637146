"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseTime = void 0;
function parseTime(date) {
    return {
        heure: date.getHours(),
        minute: date.getMinutes(),
        seconde: date.getSeconds()
    };
}
exports.parseTime = parseTime;
