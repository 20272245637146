import {ApplicationTemplate} from "../../base/template/ApplicationTemplate";
import {PageHeaderSimple} from "../../base/pageHeader/PageHeaderSimple";
import {Grid} from '@mui/material'
import {PatientDetails} from "./PatientDetails";
import {PatientHprimList} from "./PatientHprimList";
import '../../../style/patientDetail.scss'

function PatientDetailPage() {
    return <ApplicationTemplate>
        <Grid sx={{width: '91%'}} container spacing={2}>
            <Grid item xs={12} sm={12} lg={12}>
                <PageHeaderSimple pageTitle='Détail Patient' />
            </Grid>
            <Grid className='detailGridContainer' item xs={12} sm={12} lg={12}>
                <PatientDetails />
                <PatientHprimList />
            </Grid>
        </Grid>
    </ApplicationTemplate>
}

export {PatientDetailPage}

