import {BilanSanguinValue} from '@deepa-web/shared/src/type/awsLambda/ruleEngine/response/BilanSanguin'
import {ShowAnormale} from './AnormaleSwitch'

function filterAnormale(showAnormale: ShowAnormale): (bs: BilanSanguinValue) => boolean {
  return (bs: BilanSanguinValue) => {
    if (bs.displayed !== true) {
      return false
    }

    switch (showAnormale) {
      case ShowAnormale.tous:
        return true
      case ShowAnormale.anormale:
        return bs.normal === false
      default:
        return true
    }
  }
}

export {filterAnormale}
