import {EmptyRowsFix} from './EmptyRowsFix'
import {SingleHPRIMRow} from './singleHPRIM'
import {TableBody} from '@mui/material'
import {useHPRIMlistOnePage} from './useHPRIMlistOnePage'
import {HprimSummaryType} from '@deepa-web/browser-shared/src/redux/hprimSummaryList/HprimSummaryType'

function Body(): JSX.Element {
  const onePage = useHPRIMlistOnePage()

  return <TableBody>
    {onePage.map(renderHprim)}
    <EmptyRowsFix/>
  </TableBody>
}

function renderHprim(notification: HprimSummaryType): JSX.Element {
  return <SingleHPRIMRow {...notification} key={notification.hprimId}/>
}

export {Body}
