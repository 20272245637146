"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GETrequestFactory = void 0;
const GETrequestBuilder_1 = require("../builder/GETrequestBuilder");
const RequestFactory_1 = require("./RequestFactory");
const ContentTypeShort_1 = require("@deepa-web/shared/src/type/ContentTypeShort");
class GETrequestFactory extends RequestFactory_1.RequestFactory {
    constructor(routeParameter, moduleArg, responseType) {
        super(routeParameter, ContentTypeShort_1.ContentTypeShort.none, responseType || 'json', moduleArg);
    }
    makeBuilder(axiosConfig) {
        const routeParameter = this._routeParameter;
        return new GETrequestBuilder_1.GETrequestBuilder(routeParameter, this._hostPort, axiosConfig, this._contentErrorFactory);
    }
}
exports.GETrequestFactory = GETrequestFactory;
