import { call, takeEvery } from 'redux-saga/effects';
import { MarkHprimReadActionType } from '../../redux/markHprimRead/MarkHprimReadAction';
import { callPATCHendpoint } from './callPATCHendpoint';
function* markHprimReadSaga() {
    yield takeEvery([
        MarkHprimReadActionType.markRead
    ], peekAction);
}
function* peekAction(action) {
    switch (action.type) {
        case MarkHprimReadActionType.markRead:
            if (action.notification.isRead === false) {
                yield call(callPATCHendpoint, action.notification);
            }
            break;
    }
}
export { markHprimReadSaga };
