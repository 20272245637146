import { StoreProxy } from '../factory/StoreProxy';
import { storeFactory } from '../factory/storeFactory';
import { DPRactionType } from './DPRaction';
import { doctorPracticeSaga } from '../../saga/doctorPracticeInfo';
import { doctorPracticeReducer } from './reducer/DoctorPracticeReducer';
const doctorPracticeStore = storeFactory(doctorPracticeReducer, doctorPracticeSaga), doctorPracticeStoreProxy = new StoreProxy(doctorPracticeStore);
function requestDoctorPracticeFromServer() {
    doctorPracticeStore.dispatch({ type: DPRactionType.requestData });
}
export { doctorPracticeStoreProxy, requestDoctorPracticeFromServer };
