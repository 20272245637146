import {BrowserNotification} from '@deepa-web/shared/src/type/BrowserNotification'
import {
  TableRow
} from '@mui/material'
import {toolTipContent} from '../tooltip/toolTipContent'
import React from 'react'
import {OnClickProps} from '@deepa-web/browser-shared/src/type/OnClickProps'
import {CustomTooltip} from '../../../../../../../../base/tooltip'

class AnomalyTooltipTrFactory {
  private readonly _child: React.ReactNode
  private readonly _browserNotification: BrowserNotification

  constructor(child: React.ReactNode,
              browserNotification: BrowserNotification) {
    this._child = child
    this._browserNotification = browserNotification
  }

  factoryMethod(props?: OnClickProps): JSX.Element {
    const toolTipLines: string[] = toolTipContent(this._browserNotification.bilanSanguin)

    return toolTipLines.length > 0 ?
      this.withAnomalies(toolTipLines, props) :
      this.allNormal(props)
  }

  private allNormal(props?: OnClickProps) {
    return this.clickableChild(props)
  }

  private withAnomalies(toolTipLines: string[], props?: OnClickProps) {
    return <CustomTooltip title={toolTipLines.map(oneLineToolTip)}
                          placement={'bottom-start'}>
      {this.clickableChild(props)}
    </CustomTooltip>
  }

  private get tableRowClass(): string {
    const isRead = this._browserNotification.isRead
    return isRead ? 'bilanRead' : 'bilanUnread'
  }

  private clickableChild(props?: OnClickProps) {
    const tableRowClass = this.tableRowClass
    return <TableRow className={tableRowClass} onClick={props?.onClick}>
      {this._child}
    </TableRow>
  }
}

function oneLineToolTip(line: string, idx: number): JSX.Element {
  return <p key={idx}>{line}</p>
}

export {AnomalyTooltipTrFactory}
