import {paginationStoreProxy} from '@deepa-web/browser-shared/src/redux/hprimSummaryList/pagination'
import {
  PaginationState,
  rowsPerPageOptions
} from '@deepa-web/browser-shared/src/redux/hprimSummaryList/pagination/PaginationState'
import {PaginationActionType} from '@deepa-web/browser-shared/src/redux/hprimSummaryList/pagination/PaginationAction'
import React, {
  ChangeEvent,
  MouseEvent
} from 'react'
import {TablePagination} from '@mui/material'
import {paginationProviderWrapper} from '../../../../../../../redux/store/paginationProviderWrapper'

const buttonProps: any = {color: 'primary'}

function Pagination() {
  const reduxState = paginationProviderWrapper.useSelector((s: PaginationState) => s),
    {list, page, rowsPerPage} = reduxState,
    listCopy = [...list]

  return <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      count={listCopy.length}
      rowsPerPage={rowsPerPage}
      page={page}
      labelRowsPerPage={<span className='paginationSpan' > Comptes-rendus par page </span>}
      backIconButtonProps={buttonProps}
      nextIconButtonProps={buttonProps}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      sx={{
        ".MuiTablePagination-selectLabel, .MuiTablePagination-input, .MuiTablePagination-displayedRows, .MuiTablePagination-select" : {
          fontFamily:'graphie'
        },
        backgroundColor: 'white'
      }}
    />
}

function handleChangePage(event: MouseEvent<HTMLButtonElement> | null, newPage: number) {
  paginationStoreProxy.dispatch({
    type: PaginationActionType.setPage,
    data: newPage
  })
}

function handleChangeRowsPerPage(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
  const rowsPerPageNew = parseInt(event.target.value, 10)
  paginationStoreProxy.dispatch({
    type: PaginationActionType.setRowsPerPage,
    data: rowsPerPageNew
  })
}

export {Pagination}
