import {BinBsn} from './BinBsn'

class Bin extends BinBsn {
  protected analyseDecimals(value: number, binBsn: number, unit: string): string {
        return value < binBsn ? `< ${binBsn}${unit} (BIN)` : ''
  }

  protected get threshold(): number | undefined | null {
    return this._bilanSanguinValue.bin
  }
}

export {Bin}
