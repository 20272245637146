/** https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/ */

const env = process.env,
  clientId = env.REACT_APP_CLIENT_ID,
  userPoolId = env.REACT_APP_USER_POOL_ID,
  region = env.REACT_APP_AWS_REGION,
  origin = global.location.origin

const awsExports = {
  Auth: {
    // (required)- Amazon Cognito Region
    region,

    // (optional) - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: 'XX-XXXX-X',

    // (optional) - Amazon Cognito User Pool ID
    userPoolId,

    // (optional) - Amazon Cognito Web Client ID (26-char alphanumeric string, App client secret needs to be disabled)
    userPoolWebClientId: clientId,

    // (optional) - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,

    // (optional) - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_SRP_AUTH',

    // (optional) - Hosted UI configuration
    oauth: {
      domain: 'localhost',
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin'
      ],
      redirectSignIn: origin,
      redirectSignOut: origin,
      clientId,
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  }
}

export {awsExports}
