class WsServiceInstance {
    set wsServiceSet(value) {
        this._wsService = value;
    }
    get wsService() {
        return this._wsService;
    }
}
const wsServiceInstance = new WsServiceInstance();
export { wsServiceInstance };
