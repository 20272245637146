import { hprimSummaryListSaga } from '../../saga/hprimSummaryList';
import { storeFactory } from '../factory/storeFactory';
import { ListActionType } from '../common/list/ListAction';
import { getListReducer } from '../common/list';
import { StoreProxy } from '../factory/StoreProxy';
const hprimSummaryListStore = storeFactory(getListReducer, hprimSummaryListSaga), hprimSummaryListStoreProxy = new StoreProxy(hprimSummaryListStore);
function requestSummaryListFromServer() {
    hprimSummaryListStore.dispatch({ type: ListActionType.requestData });
}
export { hprimSummaryListStoreProxy, requestSummaryListFromServer };
