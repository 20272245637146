import {BooleanFunctor} from '../../functor/BooleanFunctor'
import {TabOrientationTag} from '../enum/TabOrientationTag'

class OrientationQuery extends BooleanFunctor<TabOrientationTag> {
  protected get match(): TabOrientationTag {
    return TabOrientationTag.vertical
  }

  protected get noMatch(): TabOrientationTag {
    return TabOrientationTag.horizontal
  }
}

export {OrientationQuery}
