import {useEffect} from 'react'
import JsBarcode from 'jsbarcode'

export interface BarCodeProps {
  id: string
  data: string
}

const BarCodeOptions = {
  width: 1.3,
  height: 30,
  fontSize: 10
}

function BarCode(props: BarCodeProps) {
  const {id, data} = props

  useEffect(() => {
    JsBarcode(`#${id}`, data, BarCodeOptions)
  }, [data, id])

  return <svg id={id}></svg>
}

export {BarCode}
