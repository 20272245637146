import {loginStage} from '@deepa-web/browser-shared/src/redux/user'
import {LoginStage} from '@deepa-web/browser-shared/src/redux/user/UserState'
import {
  dispatchToUserStore,
  loginAction
} from '@deepa-web/browser-shared/src/redux/user/dispatchToUserStore'
import {AuthArg} from '@deepa-web/browser-shared/src/type/AuthArg'

function loginEffect(authArg?: AuthArg, onLogin?: () => void): () => void {
  return (() => {
    const stage = loginStage()
    if (authArg && stage !== LoginStage.loggedOut) {
      dispatchToUserStore(loginAction(authArg))
      if (typeof onLogin === 'function') {
        onLogin()
      }
    }
  })
}

export {loginEffect}
