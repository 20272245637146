import {
  PropsWithChildren,
  useState
} from 'react'
import {copyToClipboardSafeProps} from './copyToClipboardSafeProps'
import {CustomTooltip} from '../../tooltip'

export interface CopyClipboardParam<T> {
  param: T
  copied: T
}

export interface CopyToClipboardProps extends PropsWithChildren {
  useDefaults: boolean
  tooltipTitle?: string
  label?: CopyClipboardParam<string>
  icon?: CopyClipboardParam<JSX.Element>
  className? : string
}

function CopyToClipboard(props: CopyToClipboardProps) {
  const {label, icon, tooltipTitle, className} = props.useDefaults ?
      copyToClipboardSafeProps(props) :
      props,

    [isCopied, setIsCopied] = useState(false),
    copyButtonText = copyClipboardParam(isCopied, label),
    copyButtonIcon = copyClipboardParam(isCopied, icon),

    handleClickClipboard = () => {
      navigator.clipboard.writeText(props.children as string)
        .then(() => {
            setTimeout(setIsCopied, 0, true)
            setTimeout(setIsCopied, 3000, false)
          }
        )
    }

  return <CustomTooltip placement='left' title={tooltipTitle}>
    <button className={className}
            onClick={handleClickClipboard}>
      {copyButtonIcon}
      {copyButtonText}
    </button>
  </CustomTooltip>
}

function copyClipboardParam<T>(isCopied: boolean, pair?: CopyClipboardParam<T>)
  : T | null {
  if (!pair) {
    return null
  }

  const {param, copied} = pair
  return isCopied ? copied : param
}

export {CopyToClipboard}
