"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServerRouteParameter = void 0;
class ServerRouteParameter {
    constructor(path) {
        this._result = path;
    }
    get Result() {
        const checked = this._result;
        if (/:/.test(checked)) {
            throw Error(this.errorMessage.call(this));
        }
        return checked;
    }
    errorMessage() {
        return `not all parameters are substituted in ${this._result}`;
    }
    hprimId(hprimId) {
        this._result = this._result.replace(':hprimId', hprimId.hprimId);
    }
    nr(nr) {
        this._result = this._result.replace(':nr', nr.toString());
    }
    uid(uid) {
        this._result = this._result.replace(':uid', uid.toString());
    }
}
exports.ServerRouteParameter = ServerRouteParameter;
