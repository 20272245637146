"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapSslCert = exports.Protocol = void 0;
var Protocol;
(function (Protocol) {
    Protocol["HTTP"] = "HTTP";
    Protocol["HTTPS"] = "HTTPS";
})(Protocol = exports.Protocol || (exports.Protocol = {}));
function mapSslCert(mapper, { pemKey, pemCert }) {
    return {
        pemKey: mapper(pemKey),
        pemCert: mapper(pemCert)
    };
}
exports.mapSslCert = mapSslCert;
