import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {PropsWithChildren, ReactNode} from 'react'


const accordionStyle = {boxShadow: 'none', border: '1px solid #f3f6ff'},
  summaryStyle = {backgroundColor: '#f3f6ff', color: '#091954', fontWeight: 'bolder'},
  detailStyle = {padding: '1em'}


export interface AnomalyAccordionFactoryProps
  extends PropsWithChildren {

  label: ReactNode
}


function AnomalyAccordionFactory(props: AnomalyAccordionFactoryProps) {
  const {label, children} = props

  return <Accordion sx={accordionStyle}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon/>}
      sx={summaryStyle}>
      {label}
    </AccordionSummary>
    <AccordionDetails sx={detailStyle}>
      {children}
    </AccordionDetails>
  </Accordion>
}

export {AnomalyAccordionFactory}
