import Button from '@mui/material/Button'
import {ChangeEvent} from 'react'
import {signatureUploadStoreProxy} from '@deepa-web/browser-shared/src/redux/doctorSignature/upload'
import {
  SignatureUploadAction,
  SignatureUploadActionType
} from '@deepa-web/browser-shared/src/redux/doctorSignature/upload/SignatureUploadAction'
import {uploadSignatureServiceInstance} from '@deepa-web/browser-shared/src/saga/doctorSignature/upload/UploadFileServiceSignature'
import {HiOutlineUpload} from 'react-icons/hi'
import {signatureUploadProviderWrapper} from '../../../../redux/store/signatureUploadProviderWrapper'
import {renderDataOrProgress} from '../../../base/progress/renderDataOrProgress'

function UploadSignatureButton() {
  return signatureUploadProviderWrapper.ProviderWrapper(
    <UploadSignatureButtonFactory/>)
}

function UploadSignatureButtonFactory() {
  const elementFactory = (_state: {}) => <UploadSignatureButtonHooked/>
  return renderDataOrProgress(elementFactory, signatureUploadProviderWrapper)
}

function UploadSignatureButtonHooked(): JSX.Element {
  return (
    <Button className='validateSignatureButton' component='label'>
      <span> Chargez votre signature </span>
      <HiOutlineUpload fontSize='2.5em' color={'black'}/>
      <input type='file' accept='image/*' hidden onChange={onUploadSignature}/>
    </Button>
  )
}

function onUploadSignature(changeEvent: ChangeEvent<HTMLInputElement>): void {
  const files = changeEvent.target.files
  if (files && files[0]) {
    storeSignatureInService(files)
    notifySignatureStore()

    changeEvent.target.value = ''   // reset input
  }
}

function storeSignatureInService(files: FileList) {
  for (const f of files) {
    uploadSignatureServiceInstance.pushItem(f)
  }
}

function notifySignatureStore() {
  const action: SignatureUploadAction = {
    type: SignatureUploadActionType.storeFile
  }
  signatureUploadStoreProxy.dispatch(action)
}

export {UploadSignatureButton}
