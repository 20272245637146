import {BrowserNotification} from '@deepa-web/shared/src/type/BrowserNotification'
import '../../../../../../style/baseComponents/notificationDisplay.scss'
import {NotificationState} from '@deepa-web/browser-shared/src/redux/notification/NotificationState'
import React, {useState} from 'react'
import {FiBell} from 'react-icons/fi'
import {ListLengthDisplay} from '../notificationContent/ListLengthDisplay'
import {NotificationContent} from '../notificationContent/NotificationContent'
import {notificationProviderWrapper} from '../../../../../../redux/store/notificationProviderWrapper'
import {CustomTooltip} from '../../../../../base/tooltip'

function NotificationDropdown(): JSX.Element {
  return notificationProviderWrapper.ProviderWrapper(
    <NotificationDropdownHooked/>
  )
}

function NotificationDropdownHooked(): JSX.Element {
  const [toggle, setToggle] = useState(false),
    toggleClass = 'dropdownNotification' + (toggle ? ' active ' : ' inactive'),
    dataList: BrowserNotification[] = notificationProviderWrapper.useSelector((s: NotificationState) => s.notifications)

  return <div className='notificationDiv'>
    <CustomTooltip placement='bottom-end' title='Notifications'>
      <button className='dropdownButton' onClick={() => setToggle(!toggle)}>
        <FiBell size={'1.5em'} color={'#091954'}/>
      </button>
    </CustomTooltip>
    <ListLengthDisplay list={dataList} noDataMessage={null}/>

    <div
      className={toggleClass}>
      <h3>
        Notifications
        <span className='underLine'/>
      </h3>

      <NotificationContent list={dataList} noDataMessage='Aucune notification'/>
    </div>
  </div>
}

export {NotificationDropdown}




