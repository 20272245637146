import { call, put, takeEvery } from 'redux-saga/effects';
import { callGetEndpoint } from './callEndpoints';
import { DictionaryActionType } from '../../redux/common/dictionary/DictionaryAction';
const requested = new Set();
function* anomaly1Saga() {
    yield takeEvery([
        DictionaryActionType.requestData,
        DictionaryActionType.storeData
    ], wrapCancelAction);
}
function* wrapCancelAction(action) {
    yield call(peekAction, action);
    yield put({ type: DictionaryActionType.cancelRequest });
}
function* peekAction(action) {
    switch (action.type) {
        case DictionaryActionType.requestData:
            const key = action.key.hprimId, isRequested = requested.has(key);
            if (isRequested) {
                break;
            }
            requested.add(key);
            const anomaly1 = (yield call(callGetEndpoint, action.key));
            if (!anomaly1) {
                requested.delete(key);
                break;
            }
            const key2 = action.key, newAction = {
                type: DictionaryActionType.storeData,
                key: key2,
                item: anomaly1
            };
            yield put(newAction);
            requested.delete(key);
            break;
    }
}
export { anomaly1Saga };
