import React from 'react'
import '../../../../style/baseComponents/backButton.scss'
import {OnClickProps} from '@deepa-web/browser-shared/src/type/OnClickProps'
import {ReactComponent as BackButtonIcon} from '../../../../images/icons/back.svg'

export function BackButton(props?: OnClickProps) {
  return <button className='backButton' onClick={props?.onClick}>
    <BackButtonIcon/>
  </button>
}
