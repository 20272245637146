import {Grid} from '@mui/material'
import {BrowserNotification} from '@deepa-web/shared/src/type/BrowserNotification'
import {ButtonBackHome} from '../../../../../../base/button/back/ButtonBackHome'
import {formatDate} from '@deepa-web/shared/src/type/date/ExplicitDate'
import {DeleteButton} from "../deleteButton";
import { FiCircle } from "react-icons/fi";
import '../../../../../../../style/hprimDetailPage/bilanPatientDetail.scss'

function BilanDetails(notification: BrowserNotification) {

  const {
      dateExamen,
      patientNom,
      hprimId,
      patientPrenom,
      patientAge
    } = notification,

    displayDate = formatDate(dateExamen)

  return <Grid className='detailGrid' item xs={7} sm={7} md={7}>
    <div className='bilanDetailDiv'>
      <ButtonBackHome/>
      <p>
          Compte-rendu de biologie médicale de <span>{patientNom} {patientPrenom}</span> du <span> {displayDate} </span>
      </p>
      <DeleteButton {...notification} />
    </div>
    <div className='bilanInfo'>
      {hprimId}
      <FiCircle size={'0.5em'} color={'#091954'} />
      {patientAge} ans
    </div>
  </Grid>
}

export {BilanDetails}
