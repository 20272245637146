import {Button} from '@mui/material'
import {ChangeEvent} from 'react'
import {
  UploadAction,
  UploadActionType
} from '@deepa-web/browser-shared/src/redux/hprimUpload/UploadAction'
import {hprimUploadStoreProxy} from '@deepa-web/browser-shared/src/redux/hprimUpload'
import {uploadFileServiceInstance} from '@deepa-web/browser-shared/src/saga/hprimUpload/UploadFileService'
import '../../../../../style/baseComponents/uploadButton.scss'
import {hprimUploadProviderWrapper} from '../../../../../redux/store/hprimUploadProviderWrapper'
import {renderDataOrProgress} from '../../../../base/progress/renderDataOrProgress'
import {CustomTooltip} from '../../../../base/tooltip'
import {ReactComponent as UploadIcon} from '../../../../../images/icons/upload.svg'


const buttonStyle = {
  fontFamily: 'mulish'
}

function UploadButton() {
  return hprimUploadProviderWrapper.ProviderWrapper(<UploadButtonFactory/>)
}

function UploadButtonFactory() {
  const elementFactory = (_state: {}) => <UploadButtonHooked/>
  return renderDataOrProgress(elementFactory, hprimUploadProviderWrapper)
}

function UploadButtonHooked(): JSX.Element {
  return (
    <div className='upload'>
      <CustomTooltip
        title={'Seulement les fichiers HPRIM sont acceptés. Le traitement des fichiers PDF sera bientôt disponible.'}
        placement={'bottom'}>
          <span>
            <Button id='uploadButton' className='homeButtons'
                    component='label'
                    sx={buttonStyle}>
                <div className='uploadButtonDiv'>
                  <UploadIcon/>
                  <span
                    className='uploadbuttonLabel'> Chargez un fichier</span>
                  <input type='file' hidden onChange={onUpload}/>
                </div>
            </Button>
           </span>
      </CustomTooltip>
    </div>
  )
}

function onUpload(changeEvent: ChangeEvent<HTMLInputElement>): void {
  const files = changeEvent.target.files
  if (files && files[0]) {
    storeFilesInService(files)
    notifyStore()

    changeEvent.target.value = ''   // reset input
  }
}

function storeFilesInService(files: FileList) {
  for (const f of files) {
    uploadFileServiceInstance.pushItem(f)
  }
}

function notifyStore() {
  const action: UploadAction = {
    type: UploadActionType.storeFile
  }
  hprimUploadStoreProxy.dispatch(action)
}

export {UploadButton}
