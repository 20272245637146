"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentErrorFunctor = void 0;
const ContentError_1 = require("./ContentError");
class ContentErrorFunctor {
    mapContentError(contentError) {
        if (contentError.status === ContentError_1.ActionStatus.success) {
            return contentError.content;
        }
        else {
            this.handleError(contentError.error);
            return this.ifError();
        }
    }
}
exports.ContentErrorFunctor = ContentErrorFunctor;
