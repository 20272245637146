var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ServerAppRoutes } from '@deepa-web/shared/src/routes/ServerAppRoutes';
import { ServerRouteParameter } from '@deepa-web/shared/src/routes/ServerRouteParameter';
import { ActionStatus } from '@deepa-web/shared/src/type/ContentError';
import { GETrequestFactory } from '@deepa-web/axios/src/factory/GETrequestFactory';
import { GETdoctorSignatureService } from './GETdoctorSignatureService';
import { RequestFactoryModuleArgBrowser } from '../../../apiClient/https/RequestFactoryModuleArgBrowser';
function callGETendpoint() {
    return __awaiter(this, void 0, void 0, function* () {
        const routeParameter = new ServerRouteParameter(ServerAppRoutes.doctorSignatureImageFile), factory = new GETrequestFactory(routeParameter, new RequestFactoryModuleArgBrowser(), 'blob'), builder = factory.Result(), contentError = yield builder.callApi();
        if (contentError.status === ActionStatus.fail) {
            return { status: ActionStatus.fail };
        }
        GETdoctorSignatureService.signatureFile = contentError.content;
        return { status: ActionStatus.success };
    });
}
export { callGETendpoint };
