import React from 'react'
import ReactDOM from 'react-dom/client'
import './style/baseComponents/index.scss'
import {App} from './component/composite/App'
import reportWebVitals from './reportWebVitals'
import {
  BrowserRouter,
  Route,
  Routes
} from 'react-router-dom'
import {NotFound} from './component/base/error/NotFound'
import '@aws-amplify/ui-react/styles.css'
import './configuration/localise'
import './configuration/amplify'
import {Doctor} from './component/composite/doctorLabo/doctor'
import {Labo} from './component/composite/doctorLabo/labo'
import {MyAccount} from './component/composite/myAccount'
import {HprimDetailPage} from './component/composite/doctorLabo/common/hprimPages/detail'
import {Contact} from './component/composite/contact'
import {BrowserRoutePaths} from '@deepa-web/shared/src/routes/BrowserRoutePaths'
import {Alerts} from './component/base/Alerts'
import {AdminPage} from './component/composite/admin/AdminPage'
import {OrdonnancePdfPreview} from './component/composite/doctorLabo/doctor/ordonnancePreview'
import {wsServiceInstance} from '@deepa-web/browser-shared/src/apiClient/webSocket/WsServiceInstance'
import {WsService} from '@deepa-web/browser-shared/src/apiClient/webSocket/WsService'
import {apiPathChecked} from './configuration/apiPath'
import {componentUIDProviderWrapper} from './redux/store/componentUIDProviderWrapper'
import {PatientDetailPage} from "./component/composite/patient";

wsServiceInstance.wsServiceSet = new WsService(apiPathChecked())

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <React.StrictMode>
    <RoutedComponents/>
    <Alerts/>
  </React.StrictMode>
)

function RoutedComponents() {
  return componentUIDProviderWrapper.ProviderWrapper(<RoutedComponentsHooked/>)
}

function RoutedComponentsHooked() {
  return <BrowserRouter>
    <Routes>
      <Route path={BrowserRoutePaths.ordonnancePreview}
             element={<OrdonnancePdfPreview/>}/>
      <Route path={BrowserRoutePaths.medecinHprimDetail}
             element={<HprimDetailPage/>}/>
      <Route path={BrowserRoutePaths.medecin} element={<Doctor/>}/>
      <Route path={BrowserRoutePaths.labo} element={<Labo/>}/>
      <Route path={BrowserRoutePaths.laboHprimDetail}
             element={<HprimDetailPage/>}/>
      <Route path={BrowserRoutePaths.account} element={<MyAccount/>}/>
      <Route path={BrowserRoutePaths.contact} element={<Contact/>}/>
      <Route path={BrowserRoutePaths.admin} element={<AdminPage/>}/>
      <Route path={BrowserRoutePaths.patientDetail} element={<PatientDetailPage/>}/>
      <Route path={BrowserRoutePaths.appRoot} element={<App/>}/>
      <Route path='*' element={<NotFound/>}/>
    </Routes>
  </BrowserRouter>
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
