import {DPRtextField} from './DPRtextField'
import React from 'react'
import {
  Box,
  Button
} from '@mui/material'
import {doctorPracticeStoreProxy} from '@deepa-web/browser-shared/src/redux/doctorPractice'
import {
  DPRactionType,
  DPRpropertyName
} from '@deepa-web/browser-shared/src/redux/doctorPractice/DPRaction'
import {UserLabeledInputs} from '../../../base/customLabelInput/UserLabeledInputs'
import {doctorPracticeProviderWrapper} from '../../../../redux/store/doctorPracticeProviderWrapper'

function DPRform() {
  return doctorPracticeProviderWrapper.ProviderWrapper(<DPRformHooked/>)
}

function DPRformHooked() {
  return (
    <Box id='box' component='form'>
      <DPRtextField id={'FINESSinput'} label={'FINESS'}
                    fieldName={DPRpropertyName.FINESS}/>
      <DPRtextField id={'serviceInput'} label={'Service'}
                    fieldName={DPRpropertyName.service}/>
      <DPRtextField id={'addressInput'} label={'Adresse'}
                    fieldName={DPRpropertyName.address}/>
      <DPRtextField id={'locationInput'}
                    label={'Lieu d\'écriture de l’ordonnance'}
                    fieldName={DPRpropertyName.location}/>

      <div className='userInputsDiv'>
        <h4>Numéros de téléphone du cabinet médical</h4>
        <UserLabeledInputs/>
      </div>

      <Button
        id='submitButton'
        type='button'
        variant='contained'
        onClick={submit}
      >
        Enregistrer
      </Button>
    </Box>
  )
}

function submit() {
  doctorPracticeStoreProxy.dispatch({type: DPRactionType.postDataToAPI})
}

export {DPRform}
