import {FormatBilanSanguinValue} from './FormatBilanSanguinValue'

function bilanSanguinValueSafe(format: FormatBilanSanguinValue): string {
  const val = format.bilanSanguinValue
  return firstNonEmpty(val.displayed_name, val.human_readable)
}

function firstNonEmpty(...vals: string[]): string{
  return vals.find(v => (v ?? '').length > 0) ?? ''
}

export {bilanSanguinValueSafe}
