import {
  Box,
  NativeSelect
} from '@mui/material'
import React, {
  useState,
  useEffect
} from 'react'

export interface DeepSelectProps {
  menuItems: string[]
  childFactory: ((selectedItem: number) => JSX.Element)[]
}

function DeepSelect(props: DeepSelectProps) {
  const [tabIndex, setIndex] = useState<number>(0),
    handleChange = (event: any) => {
      setIndex(parseInt(event.target.value))
    },
    {menuItems, childFactory} = props

  useEffect(() => setIndex(0), [menuItems])

  return <Box sx={{width: '100%'}}>
    <Box className='bilanSanguinSelectMobile' p={0} pt={1}
         sx={{display: 'flex', flexDirection: 'column'}}>
      <NativeSelect
        value={tabIndex.toString()}
        onChange={handleChange}
        sx={{margin: '10px', color: '#205072'}}
      >
        {menuItems?.map((label: string, index: number) => {

          const upperLabel = label.charAt(0).toUpperCase() + label.slice(1)

          return <option
            key={index}
            value={index}
            id={`tab-${index}-${label.toLowerCase().replaceAll(' ', '-')}`}
          >
            {upperLabel}
          </option>
        })}
      </NativeSelect>
      {childFactory.map(f => f(tabIndex))}
    </Box>
  </Box>

}

export {DeepSelect}
