import { DPRpropertyName } from '../DPRaction';
function storeField(action, newState) {
    const data = action.data;
    switch (data.tag) {
        case DPRpropertyName.FINESS:
            newState.FINESS = data.fieldValue;
            break;
        case DPRpropertyName.service:
            newState.service = data.fieldValue;
            break;
        case DPRpropertyName.address:
            newState.address = data.fieldValue;
            break;
        case DPRpropertyName.location:
            newState.location = data.fieldValue;
            break;
        case DPRpropertyName.phones:
            const newPhone = data.fieldValue, uid = newPhone.uid, idx = newState.phones.findIndex(v => v.uid == uid);
            if (idx === -1) {
                newState.phones.push(newPhone);
            }
            else {
                newState.phones[idx] = newPhone;
            }
    }
}
export { storeField };
