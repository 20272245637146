import { call, put, select, takeEvery } from 'redux-saga/effects';
import { DictionaryActionType } from '../../redux/common/dictionary/DictionaryAction';
import { callGETendpoint, callPOSTendpoint } from './callEndpoints';
import { dictionaryTypeActionFactory } from '../../redux/common/dictionary/dictionaryTypeActionFactory';
import { ordonnanceFormatStoreProxy } from '../../redux/ordonnance/format';
import { selectOrdoTitle } from '../../redux/ordonnance/ordoTitle';
const requested = new Set();
function* ordonnance1Saga() {
    yield takeEvery([
        DictionaryActionType.requestData,
        DictionaryActionType.postDataToAPI
    ], wrapCancelAction);
}
function* wrapCancelAction(action) {
    const cancelAction = dictionaryTypeActionFactory(DictionaryActionType.cancelRequest);
    yield peekAction(action);
    yield put(cancelAction);
}
function* peekAction(action) {
    switch (action.type) {
        case DictionaryActionType.requestData:
            yield requestAction(action);
            break;
        case DictionaryActionType.postDataToAPI:
            yield postAction(action);
            break;
    }
}
function* postAction(action) {
    const key = action.key.hprimId, ordonnance = yield select((s) => s.items[key]);
    if (ordonnance) {
        const formattedOrdonnance = yield call(callPOSTendpoint, action.key, ordonnance);
        ordonnanceFormatStoreProxy.dispatch({
            type: DictionaryActionType.storeData,
            key: action.key,
            item: formattedOrdonnance
        });
    }
}
function* requestAction(action) {
    const key = action.key.hprimId;
    if (requested.has(key)) {
        return;
    }
    requested.add(key);
    const ordonnance1 = (yield call(callGETendpoint, action.key));
    if (!ordonnance1) {
        requested.delete(key);
        return;
    }
    const newAction = {
        type: DictionaryActionType.storeData,
        key: action.key,
        item: ordonnance1
    };
    yield put(newAction);
    requested.delete(key);
    const map = ordonnance1.ordonnanceMap, titles = Object.keys(map);
    if (titles.length > 0) {
        yield call(selectOrdoTitle, action.key, titles[0]);
    }
}
export { ordonnance1Saga };
