import {
  SxProps,
  Theme
} from '@mui/material'

export type TabStyle = SxProps<Theme>


const tabsStyleHorizontal: TabStyle = {
    fontFamily: 'mulish',
    color: '#205072',
    textTransform: 'none',
    fontSize: '1.3em'
  },

  tabStyleVertical: TabStyle = {
    fontFamily: 'mulish',
    color: '#205072',
    textTransform: 'capitalize',
    fontSize: '1.6vh'
  }

export {
  tabsStyleHorizontal,
  tabStyleVertical
}
