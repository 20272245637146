import {Box} from '@mui/material'
import React, {PropsWithChildren} from 'react'

export enum TabOrientationCSS {
  horizontal = 'simple',
  vertical = 'vertical'
}

export interface TabPanelProps extends PropsWithChildren {
  index: number
  value: number
  orientation: TabOrientationCSS
  classNameTabPanel?: string
}

function TabPanel(props: TabPanelProps) {
  const {
    children,
    value,
    index,
    orientation,
    classNameTabPanel
  } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`${orientation}-tabpanel-${index}`}
      aria-labelledby={`${orientation}-tab-${index}`}
      className={classNameTabPanel}
    >
      {value === index && (
        <Box sx={{p: 3}}>
          {children}
        </Box>
      )}
    </div>
  )
}

export {TabPanel}
