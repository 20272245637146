import {OnClickPropsChildren} from '@deepa-web/browser-shared/src/type/OnClickProps'
import {Button} from '@mui/material'
import React from 'react'
import '../../../style/baseComponents/goToButton.scss'

function GoToButton(props: OnClickPropsChildren) {
  return <Button className='goToButton'
                 onClick={props.onClick}>
    {props.children}
  </Button>
}

export {GoToButton}
