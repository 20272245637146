import {HPRIMId} from '@deepa-web/shared/src/type/hprim/HPRIMId'
import {ListState} from '@deepa-web/browser-shared/src/redux/common/list/ListState'
import {useEffect} from 'react'
import {markHPRIMread} from '@deepa-web/browser-shared/src/redux/markHprimRead'
import {hprimSummaryListProviderWrapper} from '../../../../../../redux/store/hprimSummaryListProviderWrapper'
import {ApplicationTemplate} from '../../../../../base/template/ApplicationTemplate'
import {RuleEngine} from './ruleEngine'
import {Grid} from '@mui/material'
import {PatientDetail} from './header/PatientDetail'
import {BilanDetails} from './header/BilanDetails'
import {AnomalyDoctor} from '../../../doctor/anomaly/AnomalyDoctor'
import {HprimSummaryType} from '@deepa-web/browser-shared/src/redux/hprimSummaryList/HprimSummaryType'

function HprimDetailPagePure(hprimId: HPRIMId) {

  const entireList: HprimSummaryType[] = hprimSummaryListProviderWrapper.useSelector((s: ListState<HprimSummaryType>) => s.items),
    hprimNotification: HprimSummaryType | undefined = entireList.find(n => n.hprimId === hprimId.hprimId)

  useEffect(() => {
    if (hprimNotification !== undefined) {
      markHPRIMread(hprimNotification)
    }
  }, [hprimNotification])

  return <ApplicationTemplate>
    <Grid sx={{width: '93%'}} container spacing={2}>
      {
        hprimNotification ?
          <Grid item xs={12} sm={12} lg={12}
                sx={{display: 'flex', flexDirection: 'row', gap: '1em'}}>
            <BilanDetails {...hprimNotification}   />
            <PatientDetail {...hprimNotification} />
          </Grid> :
          null
      }
      <Grid item xs={12} sm={12} lg={12}
            sx={{display: 'flex', flexDirection: 'column', gap: '1.5em'}}>
        <RuleEngine {...hprimId} />

        <AnomalyDoctor {...hprimId}/>

      </Grid>
    </Grid>
  </ApplicationTemplate>
}

export {HprimDetailPagePure}
