import {
    SwitchButton,
    SwitchChoicesProps,
    SwitchProps,
    ValueLabel
} from '../../../../../../../base/switch'

export enum ShowAnormale {
    tous = 'tous',          // normal & abnormal
    anormale = 'anormale'   // abnormal only
}

const choices: ValueLabel<ShowAnormale> =
    {
        value: ShowAnormale.anormale,
        label: 'Anormales'
    }



function AnormaleSwitch(props: SwitchProps<ShowAnormale>){
    const propsChoices: SwitchChoicesProps<ShowAnormale> = {
        ...props,
        choices
    }

    return <SwitchButton {...propsChoices} />
}

export {AnormaleSwitch}
