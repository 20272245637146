"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.POSTrequestFactory = void 0;
const POSTrequestBuilder_1 = require("../builder/POSTrequestBuilder");
const RequestFactoryBody_1 = require("./RequestFactoryBody");
class POSTrequestFactory extends RequestFactoryBody_1.RequestFactoryBody {
    makeBuilder(axiosConfig) {
        const routeParameter = this._routeParameter, body = this._body;
        return new POSTrequestBuilder_1.POSTrequestBuilder(routeParameter, this._hostPort, axiosConfig, body, this._contentErrorFactory);
    }
}
exports.POSTrequestFactory = POSTrequestFactory;
