import {DoctorDataState} from '@deepa-web/browser-shared/src/redux/doctorData/DoctorDataState'
import {
  DoctorPracticeState,
  phonesExist
} from '@deepa-web/browser-shared/src/redux/doctorPractice/DoctorPracticeState'
import {UserLabeledPhone} from './UserLabeledPhone'
import {ValidatedOrdonnanceElement} from '../dataCheck/ValidatedOrdonnanceElement'
import {stringIsNotEmpty} from '../dataCheck/validator/stringIsNotEmpty'
import {stringOfMinimumLengthValidatorFactory} from '../dataCheck/validator/StringOfMinimumLength'
import {phoneMinimumLength} from '../dataCheck/validator/expectedDoctorPropertyLength'
import {doctorDataProviderWrapper} from '../../../../../../redux/store/doctorDataProviderWrapper'
import {doctorPracticeProviderWrapper} from '../../../../../../redux/store/doctorPracticeProviderWrapper'


function DoctorInfo(): JSX.Element {
  const {
      firstName,
      lastName,
      phone,
      email
    } = doctorDataProviderWrapper.useSelector(
      (state: DoctorDataState) => state),

    doctorPracticeState = doctorPracticeProviderWrapper.useSelector(
      (state: DoctorPracticeState) => state),

    {
      address,
      service,
      phones
    } = doctorPracticeState

  return <div className='doctorInfo'>
    {Name(lastName, firstName)}

    <div className='contact'>
      <NonEmptyStringP text={service} warning='Service'/>
      <NonEmptyStringP text={phone} warning='Téléphone'
                       minimalLength={phoneMinimumLength}/>

      {phonesExist(doctorPracticeState) ?
        phones.map(UserLabeledPhone) :
        <span/>
      }

      <p> {email} </p>
      <p> {address} </p>
    </div>
  </div>
}

function Name(
  lastName?: string,
  firstName?: string
): JSX.Element {

  return <div className='name'>
    <p> Docteur </p>
    <ValidatedOrdonnanceElement
      data={lastName}
      dataIsValid={stringIsNotEmpty}
      warning='Nom'>
      <p className='lastName'> {lastName} </p>
    </ValidatedOrdonnanceElement>

    <NonEmptyStringP text={firstName} warning='Prénom'/>
  </div>
}

interface NonEmptyStringPProps {
  text?: string
  warning: string
  minimalLength?: number
}

function NonEmptyStringP(props: NonEmptyStringPProps) {
  const {text, warning, minimalLength} = props,
    validator = minimalLength !== undefined ?
      stringOfMinimumLengthValidatorFactory(minimalLength) :
      stringIsNotEmpty

  return <ValidatedOrdonnanceElement
    data={text}
    dataIsValid={validator}
    warning={warning}>
    <p> {text} </p>
  </ValidatedOrdonnanceElement>
}

export {DoctorInfo}
