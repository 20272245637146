import { DictionaryActionType } from '../dictionary/DictionaryAction';
import { bilanSanguin1StoreProxy } from '../../bilanSanguin';
import { ruleEngine1StoreProxy } from '../../ruleEngine';
import { ordonnance1StoreProxy } from '../../ordonnance';
import { ordoTitleStoreProxy } from '../../ordonnance/ordoTitle';
function clearDictionaryStores() {
    const proxies = [
        bilanSanguin1StoreProxy,
        ruleEngine1StoreProxy,
        ordonnance1StoreProxy,
        ordoTitleStoreProxy
    ], action = clearDictionaryAction();
    proxies.forEach(p => p.dispatch(action));
}
function clearDictionaryAction() {
    return { type: DictionaryActionType.clearData };
}
export { clearDictionaryStores };
