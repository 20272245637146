import { ListActionType } from '../list/ListAction';
import { feedbackSummaryStoreProxy } from '../../feedbackSummary';
import { hprimSummaryListStoreProxy } from '../../hprimSummaryList';
function clearListStores() {
    const proxies = [
        hprimSummaryListStoreProxy,
        feedbackSummaryStoreProxy
    ], action = clearAction();
    proxies.forEach(p => p.dispatch(action));
}
function clearAction() {
    return { type: ListActionType.clearData };
}
export { clearListStores };
