import { StoreProxy } from '../factory/StoreProxy';
import { DoctorDataActionType } from './DoctorDataAction';
import { doctorDataReducer } from './DoctorDataReducer';
import { storeFactory } from '../factory/storeFactory';
import { doctorPersonalDetailSaga } from '../../saga/doctorData';
const doctorDataStore = storeFactory(doctorDataReducer, doctorPersonalDetailSaga), doctorDataStoreProxy = new StoreProxy(doctorDataStore);
function requestDoctorPersonalDataFromServer() {
    doctorDataStore.dispatch({ type: DoctorDataActionType.requestData });
}
export { doctorDataStoreProxy, requestDoctorPersonalDataFromServer };
