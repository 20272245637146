import LogoFooter from '../../../../../../images/Logo2023.svg'
import {Signature} from '../../../../../base/signature'
import {ImageFromService} from '../../../../../base/signature/ImageFromService'

function OrdonnanceFooter(): JSX.Element {
  return <div className='footerDiv'>
    <Logo/>
    <div className='signaturePreviewDiv'>
      <span className='signatureP'> Signature : </span>
      <Signature>
        <ImageFromService/>
      </Signature>
    </div>
  </div>
}

function Logo(): JSX.Element {
  return <div className='deepaDiv'>
    <img src={LogoFooter} alt='DeepA' id='deepaLogo'/>
  </div>
}

export {OrdonnanceFooter}
