import {BrowserNotification} from '@deepa-web/shared/src/type/BrowserNotification'
import {NavigateFunction} from 'react-router-dom'
import {gotoDetailPage} from '../../../../../../../../base/gotoDetailPage'
import {BrowserRoutePaths} from '@deepa-web/shared/src/routes/BrowserRoutePaths'
import {UserGroupMap} from '@deepa-web/browser-shared/src/redux/user/userGroup/UserGroupFunctor'
import {UserGroupFunctorClick} from '@deepa-web/browser-shared/src/redux/user/userGroup/UserGroupFunctorClick'
import {UserGroup} from '@deepa-web/shared/src/type/CognitoUser'
import {PropsWithChildren} from 'react'
import {OnClickProps} from '@deepa-web/browser-shared/src/type/OnClickProps'
import {UserGroupFunctorDoctorLabo} from '@deepa-web/browser-shared/src/redux/user/userGroup/UserGroupFunctorDoctorLabo'
import {AnomalyTooltipTrFactory} from './AnomalyTooltipTrFactory'

export interface DetailRowFactoryProps extends PropsWithChildren {
  notification: BrowserNotification
  navigate: NavigateFunction
}

function DetailRowFactory(props: DetailRowFactoryProps) {

  const {notification, navigate, children} = props,
    userGroupFunctor = makeUserGroupFunctor(notification, navigate),
    elementFactory = new AnomalyTooltipTrFactory(children, notification),
    factoryMethod = elementFactory.factoryMethod.bind(elementFactory),
    functorClick = new UserGroupFunctorClick(factoryMethod, userGroupFunctor)

  return functorClick.result()
}

function makeUserGroupFunctor(notification: BrowserNotification, navigate: NavigateFunction): UserGroupFunctorDoctorLabo<OnClickProps> {
  const gotoDetailDoctor = gotoDetailPage(BrowserRoutePaths.medecinHprimDetail, notification, navigate),
    gotoDetailLabo = gotoDetailPage(BrowserRoutePaths.laboHprimDetail, notification, navigate),
    callbackMap: UserGroupMap<OnClickProps> = new Map()

  callbackMap.set(UserGroup.doctors, gotoDetailDoctor)
  callbackMap.set(UserGroup.labos, gotoDetailLabo)

  return new UserGroupFunctorDoctorLabo(callbackMap)
}


export {DetailRowFactory}
