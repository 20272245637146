import { initNotificationState } from './NotificationState';
import { NotificationActionType } from './NotificationAction';
import { pull } from 'lodash';
function notificationReducer(prevState = initNotificationState(), action) {
    const { notifications } = prevState;
    switch (action.type) {
        case NotificationActionType.newNotification:
            const notifications1 = [...notifications, action.notification];
            return { notifications: notifications1 };
        case NotificationActionType.deleteNotification:
            const notifications2 = pull([...notifications], action.notification);
            return { notifications: notifications2 };
        case NotificationActionType.clearAllNotifications:
            return initNotificationState();
        default:
            return prevState;
    }
}
export { notificationReducer };
