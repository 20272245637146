import {GoToButton} from '../../../../../base/button/GoToButton'
import {requestDownload} from '../../../../../base/downloadBlob/requestDownload'
import {HPRIMId} from '@deepa-web/shared/src/type/hprim/HPRIMId'
import {ServerAppRoutes} from '@deepa-web/shared/src/routes/ServerAppRoutes'
import {ContentTypeShort} from '@deepa-web/shared/src/type/ContentTypeShort'
import {ordonnancePdfFileName} from './ordonnancePdfFileName'
import {ServerRouteParameter} from '@deepa-web/shared/src/routes/ServerRouteParameter'
import {RequestBuilder} from '@deepa-web/axios/src/builder/RequestBuilder'
import {updateOrdonnancePdfList} from '@deepa-web/browser-shared/src/redux/ordonnanceSummaryList/updateOrdonnancePdfList'
import {POSTrequestFactory} from '@deepa-web/axios/src/factory/POSTrequestFactory'
import {RequestFactoryModuleArgBrowser} from '@deepa-web/browser-shared/src/apiClient/https/RequestFactoryModuleArgBrowser'

function EnabledButtonToPdf(hprimId: HPRIMId): JSX.Element {
  return <GoToButton
    onClick={onClickDownloadButton(hprimId)}>
    Télécharger
  </GoToButton>
}

function onClickDownloadButton(hprimId1: HPRIMId): () => void {
  const routeParameter = new ServerRouteParameter(ServerAppRoutes.ordonnancePdfGenerate)

  routeParameter.hprimId(hprimId1)

  const moduleArg = new RequestFactoryModuleArgBrowser<Blob>(),
    factory = new POSTrequestFactory<{}, Blob>(
      routeParameter,
      ContentTypeShort.none,
      'blob',
      moduleArg,
      {}),
    requestBuilder: RequestBuilder<Blob, null> = factory.Result(),
    fileName = ordonnancePdfFileName(hprimId1),
    successCallback = () => updateOrdonnancePdfList(hprimId1)

  return () =>
    requestDownload(
      requestBuilder,
      fileName,
      successCallback
    )
}

export {EnabledButtonToPdf}
