import {ReactComponent as MaleIcon} from '../../../../../../../images/icons/man.svg'
import {ReactComponent as FemaleIcon} from '../../../../../../../images/icons/woman.svg'
import {CustomTooltip} from '../../../../../../base/tooltip'

export interface PatientGenderProps {
    gender : number
}

function PatientGender(props : PatientGenderProps) {
  switch (props.gender) {
    case 0 :
      return <CustomTooltip title='Genre' placement='top'>
        <div className='femaleIconDiv'>
          <FemaleIcon/> Femme
        </div>
      </CustomTooltip>
    case 1 :
      return <CustomTooltip title='Genre' placement='top'>
        <div className='maleIconDiv'>
          <MaleIcon/> Homme
        </div>
      </CustomTooltip>
    default :
      return null
  }
}

export {PatientGender}
